
<template>
<v-stepper v-model="position_stepper"
					 elevation=1
					 vertical
					 class="mt-3">

	<v-stepper-step :complete="position_stepper > 1"
									step="1">
		{{$t("assist3")}}
		<div v-if="position_stepper>1">

			<br />
			<b class="p-5  success--text"
				 v-html=" (field_euro)+ '€ = '+ quantity.toFixed(2) + ' Berlcoin'" />
		</div>
	</v-stepper-step>
	<v-stepper-content step="1">
		<v-card color="blue-grey lighten-5"
						elevation=0
						class="mb-12 pa-4">
			<v-card-text>
				<v-btn elevation=0
							 class="ma-1"
							 color="white">
					<v-icon color="orange darken-2">
						mdi-currency-eur
					</v-icon><b class="success--text"
						 v-html="monetary_aspect(daily_rate)" />
				</v-btn>
				<v-btn elevation=0
							 class="ma-1"
							 color="white">
					<v-icon color="orange darken-2">
						mdi-arrow-up-bold-box-outline
					</v-icon><b class="success--text"
						 v-html="monetary_aspect(vdp_index)+' %'" />
				</v-btn>

				<br />
				<br />
				<v-text-field v-model="field_euro"
											outlined
											@blur="convert_berlcoin"
											:label="$t('invest15')"></v-text-field>
			</v-card-text>
			<v-card-actions>
				{{$t('invest16')}} <b class="mx-1"
					 v-html="monetary_aspect(quantity)" /> Berlcoin

				<v-spacer>
				</v-spacer>

				<b class="success--text"
					 v-html="monetary_aspect(cours_XLM_inverse*daily_rate *quantity )+' XLM'" />

			</v-card-actions>
		</v-card>
		<v-btn class="success"
					 @click="position_stepper = 2">
			{{$t('next')}}
		</v-btn> &nbsp;


	</v-stepper-content>





	<v-stepper-step :complete="position_stepper > 2"
									step="2">
		{{$t("Paiement")}}
		<div v-if="position_stepper>2">

			<br />
			<b class="p-5  success--text"
				 v-show="!virement">{{$t('paypal')}}
			</b>
			<b class="p-5  success--text"
				 v-show="virement">{{$t('moneytransfert')}} </b>
		</div>
	</v-stepper-step>

	<v-stepper-content step="2">
		<v-card color="blue-grey lighten-5"
						elevation=0
						class="mb-12 pa-4">

			<v-tabs v-model="tab_paymodel"
							class="mb-2"
							grow
							active-class="light-green lighten-4"
							slider-color="warning">
				<v-tab href="#paypaypal">
					Carte
				</v-tab>
				<v-tab href="#paybank">
					Virement
				</v-tab>
				<v-tab href="#paycrypto">
					Crypto
				</v-tab>


			</v-tabs>
			<v-tabs-items v-model="tab_paymodel"
										color="blue-grey lighten-5">
				<v-tab-item value="paypaypal"
										class="blue-grey lighten-5">

					<v-card elevation=0
									color="blue-grey lighten-5"
									v-show="!virement"
									class="mb-12 p-5">

						<div ref="paypal"></div>
					</v-card>

				</v-tab-item>
				<v-tab-item value="paybank"
										class="blue-grey lighten-5">

					<v-card elevation=0
									color="blue-grey lighten-5"
									class="mb-12 p-5">

						<b class="caption">{{$t('ibanlabel')}} </b>
						<br /><b style="font-size:95%"
							 class="red--text">FR76 1679 8000 0100 0085 4071 638</b>
						<br /> {{$t('codebic')}}: TRZOFR21XXX
						<br /> {{$t('titulaire')}}: <b> Fraction Real Estate </b>
						<br /><br /> <b class="caption">{{$t('bank')}} </b> <br />
						Shine 5 rue Charlot, 75003 Paris
						<br />
						<br /> {{$t("reftranfert")}} : <b style=" color:red">{{uid}}</b>
						<br /> <br /> <i style="font-size:80%">{{$t('assist4')}}</i>
						<div class="mb-4"></div>
						<validbutton :etat_transaction="etat_transaction"
												 v-if="etat_transaction != 'success'"
												 :error_why="error_why"
												 :progress="progress"
												 @valid="send_transaction" />
					</v-card>
				</v-tab-item>
				<v-tab-item value="paycrypto"
										class="blue-grey lighten-5">

					<stellarform operation="manageBuyOffer_XLM"
											 css="pa-3"
											 :receive_asset="master_asset"
											 :send_asset="cryptocurrency"
											 :amount="quantity"
											 :price="cours_XLM_inverse*daily_rate"
											 :order="order"
											 @result="stellarform_result" />
				</v-tab-item>
			</v-tabs-items>
			<div style="max-width:750px;display:none;"
					 class=" m-0  p-5 bg-gray">
			</div>

			<v-btn class="bg-primary"
						 style="display:none;"
						 text
						 @click="virement = !virement">
				<span v-show="!virement"> {{$t('autremoyen')}} </span> <span v-show="virement">{{$t('autremoyen')}}</span>
			</v-btn>
			&nbsp;



		</v-card>
		<v-btn class="success"
					 v-show="virement"
					 @click="position_stepper = 3">
			{{$t('next')}}
		</v-btn> &nbsp;
		<v-btn @click="position_stepper = position_stepper-1"
					 elevation=0
					 class="mx-2"
					 fab
					 dark
					 small
					 color="primary">
			<v-icon dark>
				mdi-arrow-left
			</v-icon>
		</v-btn>
	</v-stepper-content>

	<v-stepper-step step="3">
		{{$t("confirmation")}}
	</v-stepper-step>
	<v-stepper-content step="3">
		<v-card color="blue-grey lighten-5"
						elevation=0
						class="mb-12 pa-5">
			<div v-show="virement">
				<p v-if="etat_transaction == 'success'">
					<br /> {{$t('ask_thanks') }}
					<br /><span v-if="kyc_wallet"> {{$t('kyc_email') }}</span>
					<br /><span v-if="!kyc_wallet"> {{$t('kyc_emailno') }}</span>
					<br /> <br />{{$t("reservation")}}
					<br />
					<br /> {{$t("reftranfert")}} : <b style=" color:red">{{uid}}</b>
					<br /> <br />
					<b class="caption">{{$t('ibanlabel')}} </b>
					<br /><b style="font-size:92%"
						 class="red--text">FR76 1679 8000 0100 0085 4071 638</b>
					<br /> {{$t('codebic')}}: TRZOFR21XXX
					<br /> {{$t('titulaire')}}: <b> Fraction Real Estate </b>
					<br /><br /> <b class="caption">{{$t('bank')}} </b> <br />
					Shine 5 rue Charlot, 75003 Paris


					<br /> <br /> <i style="font-size:80%">{{$t('assist4')}}</i>
				</p>

			</div>

			<div v-show="!virement">
				<p v-show="transactionstate">
					{{$t('assist6')}}
					<br />
					{{$t('assist7')}}
					<br />
					<span v-show="!savestate"> {{$t('assist8')}} </span>

				</p>
				<p class="red--text"
					 v-show="!transactionstate"> {{$t('assist9')}}
					&nbsp;
					<v-btn @click="position_stepper = position_stepper-1"
								 elevation=0
								 class="mx-2"
								 fab
								 dark
								 small
								 color="primary">
						<v-icon dark>
							mdi-arrow-left
						</v-icon>
					</v-btn>
				</p>

			</div>


		</v-card>


	</v-stepper-content>
</v-stepper>
</template>



<script>
var StellarSdk = require('stellar-sdk');
import validbutton from '@/components/valid_button.vue'
import stellarform from '@/components/stellar_form.vue'
export default {
	name: 'primary_buy_form',
	components: {
		validbutton,
		stellarform
	},
	props: {},
	data: () => ({

		virement: false,
		position_stepper: 1,
		tab_paymodel: "paypal",
		email: '',
		quantity: 0,

		step: 1,
		field_euro: 500,
		transactionstate: false,
		savestate: undefined,
		uid: undefined,
		etat_transaction: undefined,
		error_why: undefined,
		progress: false,
		paypal_return: undefined,
		menu_select: 0,
		order: undefined,
		asset_xlm: undefined,
		asset_berlcoin: undefined,
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		if (!this.kyc_wallet) this.field_euro = 250;
		this.$i18n.locale = this.$route.params.lang

		this.quantity = this.field_euro / this.daily_rate;
		console.log(this.paypal_key)
		const script = document.createElement("script");
		script.src = "https://www.paypal.com/sdk/js?&currency=EUR&client-id=" + this.paypal_key;
		script.addEventListener("load", this.setLoaded);
		document.body.appendChild(script);

		this.email = this.public_key;

		if (this.email != '') this.position_stepper = 1;
		this.uid = (Date.now().toString(36) + Math.random().toString(36).substr(2)).substr(10).toUpperCase();
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		token() {
			return this.$store.getters.asset("wallet", "Berlcoin", "")
		},
		public_key() {
			return this.$store.getters.public_key;
		},
		paypal_key() {
			return this.$store.getters.paypal_key;
		},
		daily_rate() {
			return this.$store.getters.daily_rate;
		},
		vdp_index() {
			return this.$store.getters.vdp_index;
		},
		kyc_wallet() {
			return this.$store.getters.kyc_wallet;
		},
		cours_XLM_inverse() {
			return this.$store.getters.cours_XLM_inverse;
		},
		master_asset() {
			return this.$store.getters.master_asset;
		},
		cryptocurrency() {
			return this.$store.getters.cryptocurrency;
		}


	},
	methods: {
		stellarform_result(result) {
			// --retour paiement crypto
			console.log(result)
			this.$emit('changepage', 'dashboard');
		},
		imprimer() {
			this.print = false;
			console.log("imprimer")
			window.print();
		},
		coupe: function (pourcentage) {
			this.amount_berlcoin = Number(this.token.balance) * (pourcentage / 100);
			//		console.log(Number(this.token.balance))
			//		console.log(pourcentage / 100)
			//		console.log(Number(this.token.balance) * (pourcentage / 100))
		},
		send_transaction: function (key) {
			this.virement = true;
			this.progress = true;
			this.etat_transaction = "";
			try {
				var source = StellarSdk.Keypair.fromSecret(key);
				if (this.public_key == source.publicKey()) {
					this.etat_transaction = "success";
					this.progress = false;
					this.souscrire();
				} else {
					this.progress = false;
					this.etat_transaction = "Echec de la transaction";
					this.error_why = this.$t('transaction_reject_false')
				}
			} catch (error) {
				this.etat_transaction = this.$t('transaction_reject_wallet') + error.toString().replace("TypeError: ", '');
				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 144, got undefined")
					this.etat_transaction = this.$t('transaction_reject_false');
				this.progress = false;
			}
		},
		setLoaded: function () {
			this.loaded = true;
			window.paypal
				.Buttons({
					createOrder: (data, actions) => {
						return actions.order.create({
							purchase_units: [{
								description: this.quantity.toFixed(5) + " Berlcoin",
								amount: {
									currency_code: "EUR",
									value: this.field_euro
								}
							}]
						});
					},
					onApprove: async (data, actions) => {
						const order = await actions.order.capture();
						this.paidFor = true;
						this.position_stepper = 3
						//	console.log(order);
						//		console.log(JSON.stringify(order));

						this.transactionstate = true;
						this.paypal_return = JSON.stringify(order);
						this.opt = [];
						this.opt["api"] = "buyberlcoin"
						this.opt["selection"] = 'berlcoin';
						this.opt["json"] = this.paypal_return;
						this.opt["uid"] = this.uid
						this.opt["debug"] = false;
						this.$store.dispatch('post', this.opt).then(response => {
							if (response != undefined) this.souscrire()
						})
					},
					onError: err => {
						console.log(err);
						this.transactionstate = false;
					}
				})
				.render(this.$refs.paypal);

		},

		convert_berlcoin: function () {
			this.quantity = Number(this.field_euro) / Number(this.daily_rate);
			this.verif_nb_berlcoin();
		},

		verif_nb_berlcoin: function () {
			this.quantity = (Math.round(this.quantity * 100000) / 100000).toFixed(5);
			this.field_euro = (Math.round(this.field_euro * 100) / 100).toFixed(2);


		},
		souscrire() {

			this.opt = [];
			this.opt["api"] = "write_contract_berlcoin"
			this.opt["debug"] = false
			this.opt["virement"] = this.virement;
			this.opt["uid"] = this.uid;
			this.opt["json"] = this.paypal_return;
			if (this.email.length == 56) this.opt["cle"] = this.email;
			this.opt["field_euro"] = this.field_euro
			this.$store.dispatch('post', this.opt).then(response => {})
			this.souscription = true;
			this.position_stepper = 3;
		},
	},
	watch: {
		field_euro() {
			this.quantity = this.field_euro / this.daily_rate;
		}
	}
}
</script>

<style scoped>
</style>
