<template>
<div>
	<v-row>
		<v-col cols=12>
			<template>
				<v-banner single-line
									elavation=0
									color="yellow lighten-5">
					<v-icon color="warning"
									@click="$emit('changepage', 'dashboard')"
									class="mr-6"
									size="24">
						mdi-home
					</v-icon>
					<v-icon color="success"
									class="mr-6"
									@click="$emit('changepage', 'nft')"
									size="24">
						mdi-credit-card
					</v-icon>

					<template v-slot:actions>
						<v-btn color="primary"
									 text>
							<v-btn-toggle v-model="coin_select">
								<v-btn small
											 :color="coin_select==0 ? 'teal lighten-4' : ''">
									Achat
								</v-btn>
								<v-btn small
											 :color="coin_select==1 ? 'teal lighten-4' : ''">
									Vente
								</v-btn>
								<v-btn small
											 :color="coin_select==2 ? 'teal lighten-4' : ''">
									NFT
								</v-btn>
							</v-btn-toggle>
						</v-btn>
					</template>
				</v-banner>
			</template>
		</v-col>
	</v-row>

	<v-card v-show="public_key== undefined"
					color="red darken-4"
					dark
					@click="$emit('changepage', 'keys')"
					class="mb-12 ma-6 pa-6"> {{$t('message_no_key2')}}
	</v-card>

	<v-row v-if="coin_select==1 &&  public_key != undefined">
		<v-col cols=12
					 sm=12
					 md=12
					 lg=12
					 xl=12>
			<template>

				<v-card class="mx-auto"
								elevation=1>
					<v-toolbar color="red darken-4"
										 flat
										 dark>
						<v-toolbar-title>Vente Fraction</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn icon>
						</v-btn>
					</v-toolbar>
					<b v-if="borders.length==0"
						 v-html="'<br/> &nbsp; '+$t('noorderwait')" />
					<v-list three-line>
						<template v-for="(article, index) in sellorders">
							<v-divider v-if="index>0"
												 :key="article.last_change"></v-divider>
							<v-list-item :key="index">
								<v-list-item-avatar>
									<v-img src="@/assets/berlcoin.svg"></v-img>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title v-html="'<b>'+parseFloat(article.moneyback).toFixed(2)+' €</b> : ' + parseFloat(article.amount).toFixed(2)   " />
									<v-list-item-subtitle v-html="article.last_change" />
									<v-spacer></v-spacer>
								</v-list-item-content>

								<v-list-item-action>
									<v-btn small
												 elevation=0
												 style="float:right"
												 :color="article.process =='pending' ? 'warning' : 'success' ">{{(( parseFloat(article.moneyback)).toFixed(2) )  }}</v-btn>
								</v-list-item-action>
							</v-list-item>
						</template>
					</v-list>
				</v-card>
			</template>
		</v-col>
	</v-row>



	<v-row v-if="coin_select==0 &&  public_key != undefined">
		<v-col cols=12
					 sm=12
					 md=12
					 lg=12
					 xl=12>
			<template>
				<v-card class="mx-auto"
								elevation=1>
					<v-toolbar color="red darken-4"
										 flat
										 dark>
						<v-toolbar-title>Achat Fraction</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn icon>
						</v-btn>
					</v-toolbar>
					<b v-if="borders.length==0"
						 v-html="'<br/> &nbsp; '+$t('noorderwait')" />
					<v-list three-line>
						<template v-for="(article, index) in borders">
							<v-divider v-if="index>0"
												 :key="article.last_change"></v-divider>
							<v-list-item :key="index">
								<v-list-item-avatar>
									<v-img src="@/assets/berlcoin.svg"></v-img>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title v-html="'<b>'+parseFloat(article.field_euro).toFixed(2)+' €</b> : ' + parseFloat(article.montant).toFixed(2) + ( article.recompense > 0 ? parseFloat(article.recompense).toFixed(2) : '') " />
									<v-list-item-subtitle v-html="article.last_change" />
									<v-spacer></v-spacer>
								</v-list-item-content>

								<v-list-item-action>
									<v-btn small
												 elevation=0
												 style="float:right"
												 :color="article.id_payment =='' ? 'warning' : 'success' ">{{((parseFloat(article.recompense)+parseFloat(article.montant)).toFixed(2) )  }}</v-btn>
								</v-list-item-action>
							</v-list-item>
						</template>
					</v-list>
				</v-card>
			</template>
		</v-col>
	</v-row>



	<v-row v-if="coin_select==2 &&  public_key != undefined">




		<v-col cols=12
					 sm=12
					 md=12
					 lg=12
					 xl=12>
			<template>
				<v-card class="mx-auto"
								elevation=1>
					<v-toolbar color="red darken-4"
										 flat
										 dark>
						<v-toolbar-title>NFT</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn icon>
						</v-btn>
					</v-toolbar>



					<b v-if="orders.length==0"
						 v-html="'<br/> &nbsp; '+$t('noorderwait')+'<br/>&nbsp; '" />
					<v-col cols=12
								 v-for="(article, asset_code, index) in orders"
								 style="width:100%"
								 :key="index">
						<v-row>



							<v-col cols=12
										 sm=3
										 md=3
										 lg=3
										 xl=3>
								<v-img class="rounded"
											 :src="media +article.asset_code + '.png'" />
							</v-col>

							<v-col cols=12
										 sm=4
										 md=4
										 lg=4
										 xl=4>

								{{(article.address )  }}
								<v-btn small
											 elevation=0
											 style="float:right"
											 :color="article.id_payment =='' ? 'warning' : 'success' ">{{( article.price_end )  }}</v-btn>
								<br /> <b class="">{{article.asset_code}}</b><br />
								<br />

								<v-icon large
												color="orange darken-2">
									mdi-arrow-up-bold-box-outline
								</v-icon><b class="success--text">{{(parseFloat(base_croissance) + parseFloat(article.annual_yield)).toFixed(2) }}% /{{$t("year")}} </b>

							</v-col>
							<v-col cols=12
										 sm=5
										 md=5
										 lg=5
										 xl=5>
								<v-expansion-panels flat
																		accordion>
									<v-expansion-panel v-show="false">
										<v-expansion-panel-header> <b>Croissance du marché Berlinois </b></v-expansion-panel-header>
										<v-expansion-panel-content class="ma-0 pa-0">
											<v-card-text class="ma-0 pa-0">

												{{$t("over10")}} 142,12% = 9.24%/an
												<br /> {{$t("over5")}} 54.92% = 9.15%/an
												<br /> {{$t("over1")}} {{vdp_index.toFixed(2)}}%
												<br />
												<br /> {{$t("expected")}} {{base_croissance.toFixed(2)}}%
												<br />
												<a target="_blank"
													 href="https://www.pfandbrief.de/site/de/vdp/immobilie/finanzierung_und_markt/vdp-immobilienpreisindex.html">Der Verband deutscher Pfandbriefbanken (vdp)</a>
											</v-card-text>
										</v-expansion-panel-content>
									</v-expansion-panel>
									<v-expansion-panel>
										<v-expansion-panel-header> <b>{{$t("staking_contact")}} </b></v-expansion-panel-header>
										<v-expansion-panel-content class="ma-0 pa-0">
											<v-card-text class="ma-0 pa-0">
												{{$t("invest6")}}: {{(article.last_change )  }}
												<br /> {{$t("order")}} : {{(article.id_transaction )  }}
												<br /> {{$t("staking_amount")}} {{article.price_start}} berlcoin
												<br /> {{$t("guaranteed")}} <b>{{article.total_yield}}%</b>
												<br /> {{$t("guaranteed")}} {{article.reward}} Berlcoin
												<br /> {{$t("liquidation")}} 28/{{article.liquidity}}
												<br /> {{$t("totalvalue")}} {{parseFloat(article.price_end).toFixed(2) }} Berlcoin

											</v-card-text>
										</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>
							</v-col>
						</v-row>
					</v-col>
				</v-card>
</template>


</v-col>
	</v-row>


</div>
</template>


<script>
export default {
	name: 'empty',
	components: {},
	props: {},
	data: () => ({
		orders: [],
		borders: [],
		sellorders: [],
		total_shop: 0,
		coin_select: 1,
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		let opt = [];
		opt["api"] = "order_nft"
		opt["public_key"] = this.public_key;

		this.$store.dispatch('post', opt).then(response => {
			if (response != undefined)
				this.orders = response.data;
		})

		let optb = [];
		optb["api"] = "order_berlcoin"
		optb["public_key"] = this.public_key;

		this.$store.dispatch('post', optb).then(response => {
			if (response != undefined)
				this.borders = response.data;
		})

		let optd = [];
		optd["api"] = "divest_berlcoin"
		optd["public_key"] = this.public_key;

		this.$store.dispatch('post', optd).then(response => {
			if (response != undefined)
				this.sellorders = response.data;
		})

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		media() {
			return this.$store.getters.media;
		},
		public_key() {
			return this.$store.getters.public_key;
		},
		base_croissance() {
			return this.$store.getters.base_croissance;
		},
		vdp_index() {
			return this.$store.getters.vdp_index;
		},
	},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
