<template>
<div>
	<v-row>
		<v-col cols=12>

			<v-banner single-line
								elavation=0
								color="yellow lighten-5">
				<v-icon color="warning"
								@click="$emit('changepage', 'dashboard')"
								class="mr-6"
								size="24">
					mdi-home
				</v-icon>
				<v-icon color="warning"
								class="mr-6"
								@click="$emit('changepage', 'secondary')"
								size="24">
					mdi-circle-outline
				</v-icon>
				<v-icon color="success"
								class="mr-6"
								size="24">
					mdi-backburger
				</v-icon>

			</v-banner>

		</v-col>
	</v-row>
	<v-row class="blue-grey lighten-5">
		<v-col cols=12>

			<v-card class="mx-auto"
							elevation=1
							style="max-width:500px;">

				<stellarform operation="validate_sponsor"
										 css="pa-3"
										 @result="stellarform_result" />
			</v-card>
		</v-col>
	</v-row>
</div>
</template>


<script>
import stellarform from '@/components/stellar_form.vue'


export default {
	name: 'validate_sponsor',
	components: {
		stellarform
	},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		token() {
			return this.$store.getters.asset("wallet", "Berlcoin", "")
		},
		public_key() {
			return this.$store.getters.public_key;
		},
		daily_rate() {
			return this.$store.getters.daily_rate;
		},
	},
	methods: {
		stellarform_result() {
			this.$emit('changepage', 'dashboard');
		},
	},
	watch: {}
}
</script>

<style scoped>
</style>
