import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const axios = require("axios");
var StellarSdk = require('stellar-sdk');


export default new Vuex.Store({
  state: {
    // --------------------------------------------------------------------------------------------------------------- MODE DEBUG

    debug: false,

    // --------------------------------------------------------------------------------------------------------------- BLOCKCHAIN

    stellarapi: "https://horizon.stellar.org/",
    teststellarapi: "https://horizon-testnet.stellar.org/",
    blockchain: "public",
    passets: [{
        "asset_code": "Berlcoin",
        "asset_issuer": "GCSMNUEEBQIWW6YX5A3XR2L7WNXACSGNCKRJSOPOKACIQTCRME6ZENYF",
        "asset": "Berlcoin:GCSMNUEEBQIWW6YX5A3XR2L7WNXACSGNCKRJSOPOKACIQTCRME6ZENYF",
        "asset_type": "credit_alphanum12",
        "limit": 99999999999999
      },
      {
        "asset_code": "XLM",
        "asset_issuer": "native",
        "asset": "XLM:native",
        "asset_type": "native"
      },
    ],
    tassets: [{
        "asset_code": "Berlcoin",
        "asset_issuer": "GA6RDH4X7D6DVLPBYPMDMCWIDVPBTX64OBDKT62ZEIRPCWXUSAWX7AIL",
        "asset": "Berlcoin:GA6RDH4X7D6DVLPBYPMDMCWIDVPBTX64OBDKT62ZEIRPCWXUSAWX7AIL",
        "asset_type": "credit_alphanum12",
        "limit": 99999999999999
      },
      {
        "asset_code": "XLM",
        "asset_issuer": "native",
        "asset": "XLM:native",
        "asset_type": "native"
      },
    ],

    bct_keys: {
      "master": {
        "public": "GA6RDH4X7D6DVLPBYPMDMCWIDVPBTX64OBDKT62ZEIRPCWXUSAWX7AIL"
      },
      "distributor": {
        "public": "GCZ7SBJK4ZJL4W5PV7PKLLEP3BVE6DRHW5HKKRJFJPEM2DAOQ2BCY25F"
      },
      "portfolio": {
        "public": "GANRM6SFLAZNQVH6EDBUK4XOB3X4WJ57HA6SMWTHMJY42XJVZRX7SWMH",
        "secret": "",
      },
      "trust": {
        "public": "GAINQ62RDG3C3MKDXCD6WSM27ILHCVXZZEYQH65WPWIJJTX7DU72S4DI",
        "secret": ""
      },
      "nft_stock": {
        "public": "GDAWBGEVPO6DP2LNF2L6NZTKKHQLPRBJDHRIIKOISS76Y6AUX7XY4EI2",
        "secret": "",
      },
      "sequestre": {
        "public": "GCDGBQBFDR7Q6EQF6VRV6OY2T3MWHEVP64NA4KFWT4S5CMS4O2PWRBVU",
        "secret": ""
      },
      "erreur": {
        "public": "GBL6C73DA4HVOCEYFWODXAXWYPOJ2VUZGVZXHXPVFDA2LGVNHJ7EYXQV",
        "secret": "",
      },
      "claim": {
        "public": "GBYMGH5QFGZ5QOGB34STDTE4OKE2742K4VZZO3HIII6EJ56O2IKBEIGS",
        "secret": "",
      },
      "demo": {
        "public": "GDTL2GHIWGEI2FWJKYNLAFMWKZQ5JADJHJZRYHLGUHPDSNLQPXIESOBL",
        "secret": "SCNKMRGK2EYUSCAE3CJF3FWLRZWWZDAYZSPAWACNSY6ROYTHOBBGFI2C",
      },
      "divest": {
        "public": "GDWNWKXI7GTXGOMZFWF3K7IRCTDOYJED2E72L2FA6VKNIKRBSLAQNQLX",
        "secret": "",
      }
    },
    bcp_keys: {
      "master": {
        "public": "GCSMNUEEBQIWW6YX5A3XR2L7WNXACSGNCKRJSOPOKACIQTCRME6ZENYF"
      },
      "distributor": {
        "public": "GDPLN3XF6OU3N2YNOAOSTDHHASRPVA3WT7J6K4MIITWMUY3AS2KGQWYH"
      },
      "trust": {
        "public": "GDIAPOAR3CKUPEFVODE4UTCY7GV4LL37NOG76TXO7YUU7527YTL5KJJQ",
        "secret": ""
      },
      "nft_stock": {
        "public": "GBOIXEDRGRFWLXILO7JQCSVXEZ3L6PTAJHZ3MFVPS3VK4M2KOHYDTK6S",
        "secret": "",
      },
      "sequestre": {
        "public": "GDOYRS2KS4EQPZ7XQ6INOT6HCCTWPXWDYOZNTNXKA4Q7GBZFBKVASZZY",
        "secret": ""
      },
      "erreur": {
        "public": "GDMQMLBFA53ECL2CILJ7VQG63GJXIVSLPQNPKDBRW3Y5CLLDVXR3EU7R",
        "secret": "",
      },
      "claim": {
        "public": "GCV4RWPKH5EL5HHFAZKKHBTUWLBQJWNWWPPK643NEVFWZC7RZBYWLJYE",
        "secret": "",
      },
      "portfolio": {
        "public": "GC3ARDNWCAAZFCMR2VETG64G6ZSQPGF56YQJ4WNPZO3KBH7KP6SO3WNT",
        "secret": "",
      }
    },
    account: {
      "master": {
        "balances": {},
        "data": {},
        "liquidity_pools": {},
        "offers": {},
        "buying": [],
        "selling": [],
        "claimable_balances": [],
        "soldes": {},
      },
      "distributor": {
        "balances": {},
        "data": {},
        "liquidity_pools": {},
        "offers": {},
        "buying": [],
        "selling": [],
        "claimable_balances": [],
        "soldes": {
          "token": 0,
          "liquidity_pools": 0,
          "claimable_balances": 0,
          "offers": 0,
          "buying": 0,
          "selling": 0,
          "trust": 0,
        },
      },
      "nft_stock": {
        "balances": {},
        "data": {},
        "liquidity_pools": {},
        "offers": {},
        "buying": [],
        "selling": [],
        "claimable_balances": [],
        "soldes": {},
        "buying_nft": [],
        "selling_nft": [],
      },
      "portfolio": {
        "balances": {},
        "data": {},
        "liquidity_pools": {},
        "offers": {},
        "buying": [],
        "selling": [],
        "claimable_balances": [],
        "soldes": {},
      },
      "sequestre": {
        "balances": {},
        "data": {},
        "liquidity_pools": {},
        "offers": {},
        "buying": [],
        "selling": [],
        "claimable_balances": [],
        "soldes": {},
      },
      "trust": {
        "balances": {},
        "data": {},
        "liquidity_pools": {},
        "offers": {},
        "buying": [],
        "selling": [],
        "claimable_balances": [],
        "soldes": {},
      },
      "wallet": {
        "balances": {},
        "data": {},
        "liquidity_pools": {},
        "offers": {},
        "buying": [],
        "selling": [],
        "buying_nft": [],
        "selling_nft": [],
        "trust": [],
        "claimable_balances": [],
        "soldes": {
          "token": 0,
          "liquidity_pools": 0,
          "claimable_balances": 0,
          "offers": 0,
          "buying": 0,
          "selling": 0,
          "trust": 0,
        },
      },

    },
    // --------------------------------------------------------------------------------------------------------------- IDENTIFICATION
    publickey: undefined,
    privatekey: "",
    accessgranted: true,
    two_factor: "",
    pin: undefined,
    // --------------------------------------------------------------------------------------------------------------- SERVEUR
    https_production: "https://api.fraction.re/berlcoin.market/",
    https_test: "  http://localhost:82/berlcoin.market/",

    // --------------------------------------------------------------------------------------------------------------- PAIEMENT
    paypal_test_key: "AeNxu9t1hHXlqBzQJqNFEr-Yr2DfV1XDLq93pAwXZeS71cbZrrP1Dw_YosrqQq3GGJyU_kmJvCx69B6N",
    paypal_production_key: "ASlyYL-EF0lufGf1in7r_wfB6TwQ_YECc1hZEfgPpJ-5Y8XYFBftNYuRfgkE4tXbUxJ-j5sZIbsCxX8t",
    currency_asset_code: "fcEURO",
    master_asset_code: "Berlcoin",
    currency_asset_issuer: undefined,
    master_asset_issuer: undefined,

    cours_XLM: 0,
    cours_XLM_inverse: 0,
    // --------------------------------------------------------------------------------------------------------------- SHOP
    media_test: "http://localhost/ecosysteme.fraction/berlcoin.market/src/assets/media/",
    media_production: "https://media.berlcoin.fr/",

    shopping: {},
    nb_article: 0,
    total_shop: 0,
    base_croissance: 9.35,
    vdp_index: 0,
    berlcoin_date_maj: "",
    berlcoin_valeur_maj: 1,
    berlcoin_price: 0,
    kyc_wallet: false,
    sponsor_wallet: false,
  },
  mutations: {

    // --------------------------------------------------------------------------------------------------------------- IDENTIFICATION

    update_pin(state, result) {
      state.pin = result;
    },
    update_privatekey(state, privatekey) {
      state.privatekey = privatekey;
    },
    update_publickey(state, publickey) {
      if (publickey == "demo")
        publickey = state.bct_keys.demo.public;

      if (publickey == state.bct_keys.demo.public) this.commit('update_blockchain', 'test');

      state.publickey = publickey;
    },
    update_access(state, result) {
      state.accessgranted = result;
      if (!result) {
        state.publickey = undefined;
        state.privatekey = "";
        state.pin = undefined;
        state.two_factor = "";
        state.shopping = {};
        state.nb_article = 0;
        state.total_shop = 0;
        state.kyc_wallet = false;
        state.sponsor_wallet = false;
        state.account.wallet = {
          "balances": {},
          "data": {},
          "liquidity_pools": {},
          "offers": {},
          "operations": {},
          "buying": [],
          "selling": [],
          "buying_nft": [],
          "selling_nft": [],
          "trust": [],
          "claimable_balances": [],
          "soldes": {
            "token": 0,
            "liquidity_pools": 0,
            "claimable_balances": 0,
            "offers": 0,
            "buying": 0,
            "selling": 0,
            "trust": 0,
          }
        }
      }
    },
    update_totp(state, result) {
      state.two_factor = result;
    },
    update_kyc_wallet(state, result) {
      state.kyc_wallet = result;
    },
    update_sponsor_wallet(state, result) {
      state.sponsor_wallet = result;
    },
    // --------------------------------------------------------------------------------------------------------------- BLOCKCHAIN
    update_blockchain(state) {
      console.log(state.blockchain)
      if (state.blockchain == 'test') {
        state.blockchain = 'public';

      } else {
        state.blockchain = 'test'
      };
      console.log(state.blockchain)
    },

    update_account(state, params) {
      // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ operations


      if (params.destination == "operations") {
        params.data.forEach((item, i) => {
            state.account[params.account].operations = params.data;
        })
      }

      // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ Data


      if (params.destination == "data") {
        params.data.forEach((item, i) => {

          if (item.value.substring(10, 11) == ':') {
            let temp = {}

            let date_maj = item.value.substr(0, 10);
            let coupe = item.value.substr(11)
            let initial_price = coupe.substr(0, coupe.indexOf("%"));
            let index = coupe.substr(coupe.indexOf("%") + 1);
            let actual_price = 0

            let date1 = new Date(date_maj);
            let date2 = new Date();
            let Diff_temps = date2.getTime() - date1.getTime();
            let Diff_jours = Diff_temps / (1000 * 3600 * 24);
            actual_price = parseFloat(initial_price) + (Diff_jours * (parseFloat(initial_price) * parseFloat(index) / 100) / 365);

            if (item.name == state.master_asset_code) {

              state.vdp_index = Number(index)
              state.berlcoin_date_maj = date_maj
              state.berlcoin_valeur_maj = Number(initial_price)
            }

            temp = {
              "name": item.name,
              "asset_code": item.name,
              "value": item.value,
              "date": date_maj,
              "initial_price": initial_price,
              "actual_price": actual_price,
              "index": index
            }
            params.data[i] = temp;
          }

        })

        state.account[params.account].data = params.data;

      }
      // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ claimable_balances
      if (params.destination == "claimable_balances") {
        state.account[params.account].claimable_balances = [];
        state.account[params.account].trust = [];
        params.data.forEach((item, i) => {

          item.asset_code = item.asset.substr(0, item.asset.indexOf(':'));
          item.asset_issuer = item.asset.substr(item.asset.indexOf(':') + 1);

          item.price_unit = 0;
          item.price_total = 0;


          state.account.master.data.find(token => {
            if (token.asset_code == item.asset_code) {
              item.price_unit = token.actual_price;
            }
          })

          item.price_total = parseFloat(item.price_unit) * parseFloat(item.amount);

          if (item.asset_issuer == this.getters.account_key("master")) {
            state.account[params.account].soldes.claimable_balances += parseFloat(item.price_total);
            state.account[params.account].claimable_balances.push(item)
          }


          if ((item.claimants[0].predicate.not != undefined) && item.asset_issuer == this.getters.account_key("sequestre")) {
            state.account[params.account].trust.push(item)
            this.dispatch('get', this.getters.horizon + "claimable_balances/" + item.id + "/transactions").then(response => {
              state.account[params.account].trust.find(file => {
                if (response.data._links.self.href.indexOf(file.id) > 0) {
                  file.memo = response.data._embedded.records[0].memo;
                  file.price_unit = 0;
                  file.price_total = 0;
                }
              })
            })
          }

        })

      }

      // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ liquidity_pools

      if (params.destination == "liquidity_pools") {
        params.data.forEach((item, i) => {
          item.balance = 0;

          state.account[params.account].balances.find(balance => {
            if (balance.asset_type == "liquidity_pool_shares" && item.id == balance.liquidity_pool_id) {
              item.balance = balance.balance;


              state.account[params.account].balances.find(token => {
                for (let i = 0; i <= 1; i++)
                  if (token.asset_code == item.reserves[i].asset.substr(0, item.reserves[i].asset.indexOf(':')) && token.asset_issuer == item.reserves[i].asset.substr(item.reserves[i].asset.indexOf(':') + 1)) {
                    item.reserves[i].price_unit = token.price_unit;
                    if (item.reserves[i].asset_type == "native") {
                      item.reserves[i].price_unit = state.cours_XLM;
                      item.reserves[i].asset_code = "XLM"
                      item.reserves[i].asset_issuer = "native"
                    } else {
                      item.reserves[i].asset_code = item.reserves[i].asset.substr(0, item.reserves[i].asset.indexOf(':'));
                      item.reserves[i].asset_issuer = item.reserves[i].asset.substr(item.reserves[i].asset.indexOf(':') + 1);
                    }
                    item.reserves[i].price_total = parseFloat(item.reserves[i].price_unit) * parseFloat(item.reserves[i].amount);

                    if (item.reserves[i].asset_code == state.master_asset_code) item.master_asset_code = true;
                    if (item.reserves[i].asset_code == state.master_asset_code) item.master_asset_code = true;


                  }
              })

            }
          })

          item.price_total = (parseFloat(item.reserves[1].price_total) + parseFloat(item.reserves[1].price_total));
          item.price_unit = parseFloat(item.price_total) / parseFloat(item.total_shares);
          item.price_total = Number(item.price_unit) * Number(item.balance)

          //    console.log(params.account)
          //      console.log(state.account[params.account].soldes.liquidity_pools)
          //      console.log(item.price_total)

          state.account[params.account].soldes.liquidity_pools += Number(item.price_total);

          //      console.log(state.account[params.account].soldes.liquidity_pools)
          //      console.log(item.price_total)

          params.data[i] = item;
        })
        state.account[params.account].liquidity_pools = params.data;



        // ---- On met a jour les liquidity pools du distributeur : les liquiditypool du distributeur et la balance du wallet doit avoir été initialisé

        if (params.account == "wallet") {
          if (state.account['distributor'].liquidity_pools.length != undefined)
            state.account['distributor'].liquidity_pools.forEach((item, i) => {
              let available_0 = false;
              let available_1 = false;
              let available = false;
              let wallet = false;
              let wallet_balance = 0;

              let master_asset_code = false;
              state.account['wallet'].balances.find(file => {
                if (item.reserves[0].asset_code == state.master_asset_code) master_asset_code = true;
                if (item.reserves[0].asset_code == file.asset_code && item.reserves[0].asset_issuer == file.asset_issuer) available_0 = true;
                if (item.reserves[1].asset_code == file.asset_code && item.reserves[1].asset_issuer == file.asset_issuer) available_1 = true;
              })

              state.account['wallet'].liquidity_pools.find(ltw => {
                if (item.id == ltw.id) {
                  wallet = true;
                  wallet_balance = ltw.balance;
                }
              })

              item.master_asset_code = master_asset_code;
              if (available_0 && available_1) available = true;
              item.available = available;
              item.wallet = wallet;
              item.wallet_balance = wallet_balance;

            })
        }

      }
      // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ Balances
      if (params.destination == "balances") {
        state.account[params.account].soldes.token = 0;
        params.data.forEach((item, i) => {
          item.price_unit = 0;
          item.price_total = 0;
          state.account.master.data.find(file => {
            if (file.asset_code == item.asset_code) {
              item.price_unit = file.actual_price;
            }
          })

          if (item.asset_code == state.currency_asset_code) item.price_unit = 1;
          if (item.asset_type == "native") {
            item.price_unit = state.cours_XLM;
            item.asset_code = "XLM"
            item.asset_issuer = "native"
          }
          item.asset = item.asset_code + ':' + item.asset_issuer;
          item.price_total = parseFloat(item.price_unit) * parseFloat(item.balance);
          params.data[i] = item;

          if (item.asset_type == "native" || item.asset_type == 'credit_alphanum4' || item.asset_type == 'credit_alphanum12') state.account[params.account].soldes.token += parseFloat(item.price_total);
          if (item.asset_issuer == this.getters.account_key("sequestre")) {
            state.account[params.account].soldes.trust++;

            // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ Token de Tiers de confiances
            if (params.account == "wallet" && state.account[params.account].soldes.trust == 1)
              this.dispatch('get', this.getters.horizon + "accounts/" + this.getters.account_key("trust") + "/operations?limit=200&order=desc").then(response => {
                //      console.log(this.getters.horizon + "accounts/" + this.getters.account_key("trust") + "/operations?limit=200&order=desc")
                state.account.trust.operations = response.data._embedded.records;
                //      console.log(state.account.trust.operations)
                state.account.wallet.balances.forEach((ite) => {
                  if (ite.asset_issuer == this.getters.account_key("sequestre")) {
                    //    console.log("on cherche :", ite.asset_code)
                    state.account.trust.operations.forEach((pay) => {

                      if (pay.asset != undefined)
                        if (pay.asset.substring(0, pay.asset.indexOf(":")) == ite.asset_code && pay.type == "create_claimable_balance") {
                          if (pay.claimants[0].destination != state.publickey) {
                            state.account.wallet.balances.find(bal => {
                              if (bal.asset_code == ite.asset_code) bal.destination = pay.claimants[0].destination;
                            })


                            //  console.log("on a trouvé ca  :",ite.asset_code,  pay.claimants[0].destination)
                            this.dispatch('get', pay._links.transaction.href).then(response => {

                              let temp_amount;
                              let temp_asset_code
                              let temp_price_unit
                              let temp_price_total
                              let temp_asset_issuer
                              state.account.trust.operations.find(token => {

                                if (token.transaction_hash == response.data.id && token.type == "payment") {
                                  //console.log(token)
                                  temp_amount = token.amount;
                                  temp_asset_code = token.asset_code
                                  temp_asset_issuer = token.asset_issuer
                                  state.account.master.data.find(file => {
                                    if (file.asset_code == token.asset_code) {
                                      //console.log(file)
                                      temp_price_unit = file.actual_price
                                      temp_price_total = Number(temp_price_unit) * Number(temp_amount)
                                    }
                                  })
                                }


                                if (token.transaction_hash == response.data.id && token.type == "create_claimable_balance" && token.claimants[0].destination != state.publickey) {

                                  //    console.log("temp_price_unit", temp_price_unit)
                                  //    console.log("temp_price_total", temp_price_total)
                                  //    console.log("temp_asset_code", temp_asset_code)
                                  //      console.log("temp_amount", temp_amount)
                                  state.account[params.account].balances.find(bal => {
                                    if (bal.asset_code == token.asset.substr(0, token.asset.indexOf(":"))) {
                                      bal.price_total = temp_price_total;
                                      bal.price_unit = temp_price_unit;
                                      bal.destination_asset_code = temp_asset_code;
                                      bal.destination_asset_issuer = temp_asset_issuer;
                                      bal.destination_amount = temp_amount;

                                      bal.memo = response.data.memo;

                                    }
                                  })
                                }
                              })
                            })
                          }

                        }



                    })
                  }
                })
              })





          }
        })

        state.account[params.account].balances = params.data;





      }
      // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ offers

      if (params.destination == "offers") {
        state.account[params.account].offers = params.data;
        state.account[params.account].selling = [];
        state.account[params.account].buying = [];
        state.account[params.account].selling_nft = [];
        state.account[params.account].buying_nft = [];

        // ---- La lecture des offres permet de trouver la valeur d'echange du XLM
        params.data.forEach((item, i) => {
          if (params.account == "distributor") {


            if ((item.buying.asset_code == state.currency_asset_code) && (item.selling.asset_type == "native")) state.cours_XLM_inverse = (1 / item.price);
            if ((item.selling.asset_code == state.currency_asset_code) && (item.buying.asset_type == "native")) state.cours_XLM = (1 / item.price);


          }

          state.account[params.account].soldes.offers += parseFloat(item.price_total);
          // --- si on achete des euros c'est que l'on vend un token

          item.price_unit = 0;
          item.balance = 0;
          item.price_total = 0;

          if (item.buying.asset_code == state.currency_asset_code) {
            if (item.selling.asset_type == "native") {
              item.selling.asset_code = "XLM";
              item.selling.asset_issuer = "native"
            }
            item.balance = item.amount;
            item.price_unit = item.price
            item.price_total = parseFloat(item.price_unit) * parseFloat(item.balance);
            state.account[params.account].soldes.selling += parseFloat(item.price_total);
            state.account[params.account].selling.push(item)
          }

          // --- si on vend des euros c'est que l'on achete un token
          if (item.selling.asset_code == state.currency_asset_code) {
            if (item.buying.asset_type == "native") {
              item.buying.asset_code = "XLM";
              item.buying.asset_issuer = "native"
            }
            item.price_unit = item.price_r.d / item.price_r.n
            item.balance = item.amount * item.price
            item.price_total = parseFloat(item.price_unit) * parseFloat(item.balance);
            state.account[params.account].buying.push(item)
            state.account[params.account].soldes.buying += parseFloat(item.price_total);
          }
          // -- nft
          if (item.amount < 0.000009) {

            if (item.buying.asset_code == state.master_asset_code) {
              if (item.selling.asset_type == "native") {
                item.selling.asset_code = "XLM";
                item.selling.asset_issuer = "native"
              }
              item.balance = item.amount * 10000000;
              item.price_unit = item.price
              item.price_total = parseFloat(item.price_unit) * parseFloat(item.balance);
              state.account[params.account].soldes.selling_nft += parseFloat(item.price_total);
              state.account[params.account].selling_nft.push(item)
            }

            // --- si on vend des euros c'est que l'on achete un token
            if (item.selling.asset_code == state.master_asset_code) {
              if (item.buying.asset_type == "native") {
                item.buying.asset_code = "XLM";
                item.buying.asset_issuer = "native"
              }
              item.price_unit = item.price_r.d / item.price_r.n
              item.balance = item.amount * item.price * 10000000
              item.price_total = parseFloat(item.price_unit) * parseFloat(item.balance);

              state.account[params.account].buying_nft.push(item)
              state.account[params.account].soldes.buying_nft += parseFloat(item.price_total);
            }


          }

        })

      }
    },
    // --------------------------------------------------------------------------------------------------------------- SHOP
    init_shopping(state) {
      state.shopping = {}
      state.nb_article = 0
      state.total_shop = 0
    },

    plus(state, param) {
      if (state.shopping[param.article] == undefined) state.shopping[param.article] = {
        "asset_code": param.article,
        "asset_issuer": param.asset_issuer,
        "quantity": 0,
        "price": param.price,
        "max": param.max
      };
      if (state.shopping[param.article].quantity < state.shopping[param.article].max) {
        state.shopping[param.article].quantity++;
        state.nb_article++;
        state.total_shop = parseFloat(state.total_shop) + (parseFloat(param.price) * state.berlcoin_price);
      }
    },
    minus(state, param) {
      if (state.shopping[param.article] == undefined) state.shopping[param.article] = {
        "quantity": 0,
        "asset_code": param.article,
        "asset_issuer": param.asset_issuer,
        "price": param.price,
        "max": param.max
      };

      if (state.shopping[param.article].quantity > 0) {
        state.shopping[param.article].quantity--;
        state.nb_article--;
        state.total_shop = parseFloat(state.total_shop) - (parseFloat(param.price) * state.berlcoin_price);
      }
    }
  },

  getters: {
    // --------------------------------------------------------------------------------------------------------------- HTTPS
    https: state => {
      return state.debug ? state.https_test : state.https_production;
    },
    blockchain: state => {
      return state.blockchain;
    },
    media: state => {
      return state.debug ? state.media_test : state.media_production;
    },
    // --------------------------------------------------------------------------------------------------------------- BLOCKCHAIN
    horizon: state => {
      return state.blockchain == "test" ? state.teststellarapi : state.stellarapi;
    },
    net_pass: state => {
      return state.blockchain == "test" ? StellarSdk.Networks.TESTNET : StellarSdk.Networks.PUBLIC;
    },
    public_key: state => {
      return state.publickey;
    },
    private_key: state => {
      return state.privatekey;
    },
    pin: state => {
      return state.pin;
    },
    two_factor: state => {
      return state.two_factor;
    },
    keys: state => {
      return state.blockchain == "test" ? state.bct_keys : state.bcp_keys;
    },
    key: state => (account) => {
      return state.blockchain == "test" ? state.bct_keys[account].public : state.bcp_keys[account].publics;
    },

    balances: (state) => (account) => {
      return state.account[account].balances
    },
    asset: (state, getters) => (account, asset_code, asset_issuer) => {
      if (asset_issuer == "") asset_issuer = getters.account_key('master')

      if (Array.isArray(state.account[account].balances))
        return state.account[account].balances.find(token => {
          if (token.asset_code == asset_code && token.asset_issuer == asset_issuer) return token;
        })

    },
    liquidity_pools: (state) => (account) => {
      return state.account[account].liquidity_pools
    },
    claimable_balances: (state) => (account) => {
      return state.account[account].claimable_balances
    },
    trust: (state) => (account) => {
      return state.account[account].trust
    },
    offers: (state) => (account) => {
      return state.account[account].offers
    },
    selling: (state) => (account) => {
      return state.account[account].selling
    },
    buying: (state) => (account) => {
      return state.account[account].buying
    },
    selling_nft: (state) => (account) => {
      return state.account[account].selling_nft
    },
    buying_nft: (state) => (account) => {
      return state.account[account].buying_nft
    },
    operations: (state) => (account) => {
      return state.account[account].operations
    },
    data: (state, getters) => (account, name) => {
      return state.account[account].data.find(token => {
        if (token.name == name) return token;
      })
    },
    account_key: (state) => (account) => {
      return state.blockchain == "test" ? state.bct_keys[account].public : state.bcp_keys[account].public;
    },
    account_solde: (state) => (solde) => {
      return state.account.wallet.soldes[solde];
    },
    assets: (state) => {
      return state.blockchain == "test" ? state.tassets : state.passets;
    },

    kyc_wallet: (state) => {
      return state.kyc_wallet;
    },
    sponsor_wallet: (state) => {
      return state.sponsor_wallet;
    },
    master_asset: (state, getters) => {

      let temp = state.master_asset_issuer;
      if (temp == undefined) temp = getters.account_key("master");
      if (state.master_asset_code == "XLM") temp = "native";
      return {
        "asset_code": state.master_asset_code,
        "asset_issuer": temp,
        "asset": state.master_asset_code + ':' + temp
      }

    },
    cryptocurrency: () => {
      return {
        "asset_code": "XLM",
        "asset_issuer": "native",
        "asset": "XLM:native",
        "asset_type": "native"
      }
    },

    currency: (state, getters) => {

      let temp = state.currency_asset_issuer;
      if (temp == undefined) temp = getters.account_key("master");
      if (state.currency_asset_code == "XLM") temp = "native";
      return {
        "asset_code": state.currency_asset_code,
        "asset_issuer": temp,
        "asset": state.currency_asset_code + ':' + temp
      }

    },
    // --------------------------------------------------------------------------------------------------------------- SHOP
    shops: state => {
      return state.nb_article;
    },
    daily_rate: state => {
      let date1 = new Date(state.berlcoin_date_maj);
      let date2 = new Date();
      let Diff_temps = date2.getTime() - date1.getTime();
      let Diff_jours = Diff_temps / (1000 * 3600 * 24);
      state.berlcoin_price = parseFloat(state.berlcoin_valeur_maj) + (Diff_jours * (parseFloat(state.berlcoin_valeur_maj) * parseFloat(state.vdp_index) / 100) / 365);
      return state.berlcoin_price;

    },
    cours_XLM_inverse: state => {
      return state.cours_XLM_inverse;
    },
    total_shop: state => {
      return state.total_shop;
    },
    paypal_key: state => {
      return state.debug ? state.paypal_test_key : state.paypal_production_key;
    },
    base_croissance: state => {
      return state.base_croissance;
    },
    vdp_index: state => {
      return state.vdp_index;
    },

  },
  actions: {

    // --------------------------------------------------------------------------------------------------------------- SHOP
    plus(context, article) {
      context.commit('plus', article)
    },
    minus(context, article) {
      context.commit('minus', article)
    },
    init_shopping(context) {
      context.commit('init_shopping')
    },

    // --------------------------------------------------------------------------------------------------------------- BLOCKCHAIN
    generate_key(context) {
      const pair = StellarSdk.Keypair.random();
      context.commit('update_publickey', pair.publicKey())
      context.commit('update_privatekey', pair.secret())
    },


    stellar_transaction({
      getters
    }, params) {

      return new Promise(function(myResolve, myReject) {
        try {

          let source = params.source
          let operations = params.operations
          let memo = params.memo
          var server = new StellarSdk.Server(getters.horizon);
          var net_pass = getters.net_pass;

          server
            .loadAccount(source.publicKey())
            .then(function(sourceAccount) {
              var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                  fee: StellarSdk.BASE_FEE,
                  networkPassphrase: net_pass,
                })
                .addMemo(StellarSdk.Memo.text(memo))
              transaction.operations = operations;
              let tx = transaction.setTimeout(180).build();
              tx.sign(source);
              return server.submitTransaction(tx);
            })
            .then(response => {
              myResolve(Promise.resolve(response));
              //  console.log(response)
            })
            .catch(error => {
              console.log(error)
              //    console.log(error.response.data.extras.result_codes.operations)
              let temp_error = "unknown error"
              if (error.response != undefined) temp_error = error.response.data.extras.result_codes.operations;
              myReject("Error:" + temp_error);
            });
        } catch (error) {
          myReject(error)
        }
      })
    },

    stellar_transaction_sign_source(context, params) {

      try {

        let source = params.source
        let operations = params.operations
        let memo = params.memo
        var server = new StellarSdk.Server(this.getters.horizon);
        var net_pass = this.getters.net_pass;
        return server
          .loadAccount(source.publicKey())
          .then(function(sourceAccount) {
            var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: StellarSdk.BASE_FEE,
                networkPassphrase: net_pass,
              })
              .addMemo(StellarSdk.Memo.text(memo))
            transaction.operations = operations;
            let tx = transaction.setTimeout(180).build();
            tx.sign(source);
            return server.submitTransaction(tx);
          })
          .then(response => {
            return Promise.resolve(response);
          })
          .catch(error => {
            return Promise.resolve("Error:" + error.response.data.extras.result_codes.operations);
          });
      } catch (error) {}
    },

    // --------------------------------------------------------------------------------------------------------------- AXIOS GET

    get(context, url) {
      return axios
        .get(url)
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => {
          if (error.response.status == 401) this.state.session_token = "";
          return Promise.resolve(error);
        });
    },

    // --------------------------------------------------------------------------------------------------------------- AXIOS UPLOAD

    upload: function(context, opt) {

      // ------------ definition de l'api et des parametres
      let data = new FormData();
      let api = this.getters.https + opt["api"] + ".php";
      opt["token"] = this.getters.public_key;

      // ------------ defini les parametres du post
      for (let [key, value] of Object.entries(opt)) {
        if (key != "api") data.append(key, value);
      }

      // ------------ affichage du debug
      if (opt["debug"]) {
        let log = "";
        let logget = "";
        for (var pair of data.entries()) {
          log += "$_POST['" + pair[0] + "']= '" + pair[1] + "' ;\n";
          logget += "&" + pair[0] + "=" + pair[1];
        }
      }
      return axios
        .post(api, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (opt["debug"]) {}
          return Promise.resolve(response);
        })
        .catch(error => {
          if (opt["debug"]) {
            if (error.response.status == 401) this.state.session_token = "";
          }
          return Promise.resolve(error);
        });


    },

    // --------------------------------------------------------------------------------------------------------------- AXIOS POST

    post(context, opt) {
      //    console.log("APPEL post " + opt["api"]);
      //    console.log(opt);
      // ------------ definition de l'api et des parametres
      let data = new FormData();
      let api = this.getters.https + opt["api"] + ".php";

      // ------------ defini les parametres du post
      for (let [key, value] of Object.entries(opt)) {
        if (key != "api") data.append(key, value);
      }

      // ------------ affichage du debug
      if (opt["debug"]) {
        let log = "";
        let logget = "";
        for (var pair of data.entries()) {
          log += "$_POST['" + pair[0] + "']= '" + pair[1] + "' ;\n";
          logget += "&" + pair[0] + "=" + pair[1];
        }
        console.log("APPEL API  : " + api);
        console.log(log + "\n" + api + "?" + logget + "\n");
      }

      // ------------- l'envoi au serveur et la gestion de la reponse

      return axios
        .post(api, data)
        .then(response => {
          if (opt["debug"]) {
            console.log("Retour axios de " + opt["api"] + " : " + response.data);
            console.log(response.data);
          }
          return Promise.resolve(response);
        })
        .catch(error => {
          if (opt["debug"]) {
            console.log("Une erreur est survenue");
            console.log("error headers " + " " + error.response.headers);
            console.log(error.response.headers);
            console.log("error status " + " " + error.response.status);
            console.log("error data " + " " + error.response.data);

            if (error.response.status == 401) this.state.session_token = "";
          }
          return Promise.resolve(error);
        });
    }


  },
  modules: {}
})
