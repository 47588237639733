<template>
<section class="wrapper ">



	<v-row>
		<v-col cols=12>
			<template>
				<v-banner single-line
									elavation=0
									color="yellow lighten-5">
					<v-icon color="warning"
									@click="$emit('changepage', 'dashboard')"
									class="mr-6"
									size="24">
						mdi-home
					</v-icon>
					<v-icon color="success"
									class="mr-6"
									@click="$emit('changepage', 'nft')"
									size="24">
						mdi-currency-eur
					</v-icon>


				</v-banner>
			</template>

		</v-col>
	</v-row>


	<v-row>
		<v-col cols=12
					 v-if="!kyc_wallet && public_key != undefined">
			<v-alert dark
							 color="warning">

				<v-icon>
					mdi-checkbox-multiple-marked-outline
				</v-icon> {{$t('nokyc')}}
				<template>
					<v-btn class="    float-right "
								 color="primary"
								 @click="$emit('changepage', 'validate_kyc')"
								 elevation=0
								 small>{{$t('nokyc1')}}</v-btn>
				</template>
			</v-alert>
		</v-col>

		<v-col cols=12>
			<div style="font-size:85%"
					 v-if="!kyc_wallet"
					 class="my-2">
				{{$t('kyc_alert')}}
			</div>
		</v-col>
	</v-row>
	<v-row>

		<v-col v-show="public_key== undefined"
					 cols=12>
			<v-card elevation=0
							color="red darken-4"
							dark
							@click="$emit('changepage', 'keys')"
							class="mx-3 mt-3 pa-2"> {{$t('message_no_key')}}
			</v-card>
		</v-col>

	</v-row>

	<v-row>
		<v-col cols=12
					 v-if="false"
					 sm=6
					 md=6
					 lg=4
					 class="ma-0 pa-0"
					 xl=3>


			<v-col cols=4
						 class="float-left">
				<v-card color="success"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/achat.png"
								 @click="$emit('changepage', 'primary_buy')"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3">ACHAT </p>
				</v-card>
			</v-col>

			<v-col cols=4
						 class="float-left">
				<v-card color="success"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/vente.png"
								 @click="$emit('changepage', 'primary_sell')"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3">VENTE </p>
				</v-card>
			</v-col>

			<v-col cols=4
						 class="float-left">
				<v-card color="success"
								@click="$emit('changepage', 'nft')"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/nft.png"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3">NFT </p>
				</v-card>
			</v-col>

		</v-col>
		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=4>

			<cardprimarybuying v-show="public_key!= undefined"
												 @changepage="change_page" />
		</v-col>

		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=4>

			<cardprimaryselling v-show="public_key!= undefined"
													@changepage="change_page" />
		</v-col>

		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=4>

			<cardprimarynft v-show="public_key!= undefined"
											@changepage="change_page" />
		</v-col>

	</v-row>

</section>
</template>


</div>
</template>


<script>
import cardprimarybuying from '@/components/card_primary_buying.vue'
import cardprimaryselling from '@/components/card_primary_selling.vue'
import cardprimarynft from '@/components/card_primary_nft.vue'

export default {
	name: 'primary',
	components: {
		cardprimarybuying,
		cardprimaryselling,
		cardprimarynft
	},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		public_key() {
			return this.$store.getters.public_key;
		},
		kyc_wallet() {
			return this.$store.getters.kyc_wallet;
		},
	},
	methods: {
		change_page(page) {
			this.$emit('changepage', page)
		}
	},
	watch: {

	}
}
</script>

<style scoped>
</style>
