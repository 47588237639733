<template>
<div>
	<v-row>
		<v-col cols=12>
			<template>
				<v-banner single-line
									elavation=0
									color="yellow lighten-5">
					<v-icon color="warning"
									@click="$emit('changepage', 'dashboard')"
									class="mr-6"
									size="24">
						mdi-home
					</v-icon>
					<v-icon color="success"
									@click="$emit('changepage', 'dashboard')"
									class="mr-6"
									size="24">
						mdi-key-change
					</v-icon>

				</v-banner>
			</template>
		</v-col>
	</v-row>
	<v-row>

		<v-col cols=12
					 sm=6
					 md=6
					 v-if="!show_keys"
					 lg=4
					 xl=3>
			<template>
				<v-card class="mx-auto "
								style="height:100%;">
					<v-img v-if="(publickey_temp==undefined ||publickey_temp=='')"
								 src="@/assets/media/keys.jpg"></v-img>

					<div class="ma-2 caption"
							 v-if="!(publickey_temp==undefined ||publickey_temp=='')">

						<div class="mx-auto"
								 style="text-align:center;">
							<qrcode-vue :value="publickey_temp"></qrcode-vue>
						</div>
						<v-icon style="float:right;"
										class="mt-3"
										@click="copyToClipBoard(publickey_temp)">mdi-content-copy
						</v-icon>


						<p class="text-center">
							{{publickey_temp.substring(0,28)}}
							<br />
							{{publickey_temp.substring(28)}}
						</p>
						<hr class="my-2" />
					</div>
					<v-card-title v-if="false"
												v-html="$t('public_key')" />
					<v-card-text>

						<v-dialog transition="dialog-bottom-transition"
											v-model="dialog2"
											max-width="600">
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs"
												large
												class="mt-6"
												style="float:right; border:1px solid gray; border-radius:2px;"
												v-on="on">
									mdi-qrcode
								</v-icon>
							</template>
							<v-card>
								<v-toolbar color="primary"
													 dark>Scanner le QR Code</v-toolbar>
								<v-card-text>
									<p class="error">{{ error }}</p>
									<qrcode-stream @decode="onDecode"
																 @init="onInit" />
								</v-card-text>
								<v-card-actions class="justify-end">
									<v-btn icon
												 @click="dialog2 = false">
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
						<v-text-field v-model="publickey_temp"
													@keydown="write_public_key()"
													class="ma-1 "
													:label="$t('public_key')"
													:placeholder="$t('public_key')"></v-text-field>
					</v-card-text>

					<v-divider class="mx-4"></v-divider>
					<v-card-actions>
						<v-btn color="deep-purple lighten-2"
									 v-if="public_key==undefined"
									 text
									 @click="generate_key()">
							{{$t("generate_key")}}
						</v-btn>

						<v-btn color="red darken-4"
									 v-if="public_key !=undefined"
									 dark
									 block
									 @click="deconnect()">
							{{$t("deconnect")}}
						</v-btn>


					</v-card-actions>
					<div class="pa-4"
							 v-if="false">

						<v-icon style="float:right;"
										color="success"
										@click="copyToClipBoard($t('public_key')+' '+publickey_temp+' '+$t('secret_key')+' '+private_key)">mdi-content-copy
						</v-icon>
						<v-icon style="float:right;"
										color="success"
										class="mx-3"
										@click="copyToClipBoard($t('public_key')+' '+publickey_temp+' '+$t('secret_key')+' '+private_key)">mdi-printer
						</v-icon>
						<div class="ml-3"
								 style="font-size:60%">{{$t('public_key')}} : <br /> {{publickey_temp.substr(0,28)}}<br /> {{publickey_temp.substr(28,28)}} </div>
						<br />
						<div class="ml-3"
								 style="font-size:60%"> {{$t('secret_key')}} : <br />{{private_key.substr(0,28)}}<br /> {{private_key.substr(28,28)}} </div>
					</div>
				</v-card>
			</template>
		</v-col>

		<v-col cols=12
					 v-if="show_keys  && print"
					 sm=6
					 md=6
					 lg=4
					 xl=3>
			<v-card class="mx-auto "
							id="keys_qr"
							style="height:100%;">
				<v-card-text>
					<v-card-title v-html="$t('public_key')" />

					<div class="mx-auto my-8"
							 style="width:150px;">
						<qrcode-vue size=150
												:value="publickey_temp"></qrcode-vue>
					</div>
					<div class=" text-center caption"> {{publickey_temp.substr(0,28)}}<br /> {{publickey_temp.substr(28,28)}} </div>
					<br />


					<v-card-title v-html="$t('secret_key')" />
					<div class="mx-auto my-8"
							 style="width:150px;">
						<qrcode-vue size=150
												:value="private_key"></qrcode-vue>

					</div>
					<div class="text-center  caption">{{private_key.substr(0,28)}}<br /> {{private_key.substr(28,28)}} </div>
					<v-divider class="my-4"></v-divider>
					<a @click="imprimer('keys_qr')"> {{$t("print")}} </a>
					<v-icon style="float:right;"
									color="success"
									class="mx-3"
									large
									@click="copyToClipBoard($t('public_key')+' '+publickey_temp+' '+$t('secret_key')+' '+private_key)">mdi-content-copy
					</v-icon> <A style="text-decoration:none;"
						 class="  white--text ">
						<v-icon color="success"
										@click="imprimer('keys_qr')"
										class="mx-3"
										large>mdi-printer
						</v-icon>

					</A>


				</v-card-text>
			</v-card>
		</v-col>

		<v-col cols=12
					 v-show="publickey_temp!='' && !print &&otp.length!=6"
					 sm=6
					 md=6
					 lg=4
					 xl=3>
			<template>
				<v-card class="mx-auto "
								style="height:100%;">
					<v-img src="@/assets/media/secret.jpg"></v-img>
					<v-card-title v-html="$t('secret_key')" />
					<v-card-text>
						<v-dialog transition="dialog-bottom-transition"
											v-model="dialog3"
											max-width="600">
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs"
												large
												class="mt-6"
												style="float:right; border:1px solid gray; border-radius:2px;"
												v-on="on">
									mdi-qrcode
								</v-icon>
							</template>
							<v-card>
								<v-toolbar color="primary"
													 dark>Scanner le QR Code</v-toolbar>
								<v-card-text>
									<p class="error">{{ error }}</p>
									<qrcode-stream @decode="onDecode2"
																 @init="onInit" />
								</v-card-text>
								<v-card-actions class="justify-end">
									<v-btn icon
												 @click="dialog3 = false">
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
						<v-text-field v-model="privatekey"
													label="Clé privée"
													clearable></v-text-field>

						<v-otp-input v-model="otp"
												 :disabled="privatekey==''"
												 @finish="onFinish"></v-otp-input>


					</v-card-text>
					<v-divider class="mx-4"></v-divider>
					<v-card-actions>
						<v-btn color="deep-purple lighten-4"
									 block
									 :disabled="!(privatekey==undefined || privatekey=='')"
									 v-if="public_key!=undefined"
									 @click="ouvrir('dashboard')">
							{{$t("no-private_key")}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-col>


		<v-col cols=12
					 v-show="otp.length==6"
					 sm=6
					 md=6
					 lg=4
					 xl=3>
			<template>
				<v-card class="mx-auto "
								style="height:100%;">
					<v-img src="@/assets/media/doublesecurity.jpg"></v-img>
					<v-card-title v-html="$t('double_key')" />
					<v-card-text>
						<br /> <i style="font-size:95%"
							 class="mt-0 pt-0">{{$t('double_key_do')}}</i>
						<br />
						<v-icon style="float:right;"
										class="mt-3"
										@click="copyToClipBoard(secret.base32)">mdi-content-copy
						</v-icon>

						<span style="font-size:60%"> {{secret.base32}} </span>
						<br /> <br />
						<div class="mx-auto"
								 style="text-align:center;">
							<qrcode-vue :value="url"></qrcode-vue>
						</div>
						<br />
						{{$t('enter_code_double')}}
						<v-otp-input v-model="otp2"
												 @finish="onFinish2"></v-otp-input>

					</v-card-text>
					<v-divider class="mx-4"></v-divider>
					<v-card-actions>
						<v-btn color="deep-purple lighten-4"
									 block
									 v-if="public_key!=undefined"
									 @click="ouvrir('dashboard')">
							{{$t("no-double_key")}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-col>


	</v-row>

	<v-snackbar v-model="snackbar"
							:timeout="timeout">
		{{ $t("error_otp") }}

		<template v-slot:action="{ attrs }">
			<v-btn color="blue"
						 text
						 v-bind="attrs"
						 @click="snackbar = false">
				x
			</v-btn>
		</template>
	</v-snackbar>


</div>
</template>


<script>
const CryptoJS = require("crypto-js");

import speakeasy from "@levminer/speakeasy"
import QrcodeVue from 'qrcode.vue'
import {
	QrcodeStream,
	QrcodeDropZone,
	QrcodeCapture
} from 'vue-qrcode-reader'

export default {
	name: 'empty',
	components: {
		QrcodeStream,
		QrcodeDropZone,
		QrcodeCapture,
		QrcodeVue
	},
	props: {},
	data: () => ({
		privatekey: "",
		otp: '',
		otp2: '',
		dialog2: false,
		dialog3: false,
		error: '',
		publickey_temp: '',
		url: '',
		secret: '',
		user: [],
		show_keys: false,
		snackbar: false,
		timeout: 2000,
		print: true,
	}),
	beforeCreate: function () {},
	created: function () {
		this.secret = speakeasy.generateSecret()

		this.user.two_factor_temp_secret = this.secret.base32;
		this.user.two_factor_enabled = false;
		this.url = speakeasy.otpauthURL({
			secret: this.secret.ascii,
			label: "berlcoin.market"
		})
	},
	beforeMount: function () {},
	mounted: function () {
		if (this.public_key != undefined) this.publickey_temp = this.public_key;
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		public_key() {
			return this.$store.getters.public_key;
		},
		private_key() {
			return this.$store.getters.private_key;
		},
		two_factor() {
			return this.$store.getters.two_factor;
		},
	},
	methods: {
		deconnect() {
			window.localStorage.clear('berlcoin.market');
			this.$store.commit("update_access", false);
		},
		write_public_key() {
			if (this.publickey_temp != undefined)
				if (this.publickey_temp.length > 55) {
					this.$store.commit('update_publickey', this.publickey_temp);
					this.print = false;
					this.show_keys = true;
				}
		},
		imprimer() {
			this.print = false;
			window.print();
		},
		generate_key() {
			this.$store.dispatch('generate_key')
			this.show_keys = true;

		},
		onDecode(result) {
			this.publickey_temp = result
			this.dialog2 = false;
		},
		onDecode2(result) {
			this.privatekey = result
			this.dialog3 = false;
		},

		async onInit(promise) {
			try {
				await promise
			} catch (error) {
				if (error.name === 'NotAllowedError') {
					this.error = "ERROR: Pas de permission pour la caméra"
				} else if (error.name === 'NotFoundError') {
					this.error = "ERROR: Pas de caméra"
				} else if (error.name === 'NotSupportedError') {
					this.error = "ERROR: secure context required (HTTPS, localhost)"
				} else if (error.name === 'NotReadableError') {
					this.error = "ERROR: La caméra semble déjà en cours d'utilisation"
				} else if (error.name === 'OverconstrainedError') {
					this.error = "ERROR: installed cameras are not suitable"
				} else if (error.name === 'StreamApiNotSupportedError') {
					this.error = "ERROR: Stream API is not supported in this browser"
				} else if (error.name === 'InsecureContextError') {
					this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
				} else {
					this.error = `ERROR: Camera error (${error.name})`;
				}
			}

		},
		onFinish() {
			if (this.privatekey.length == 56)
				this.$store.commit('update_privatekey', CryptoJS.AES.encrypt(this.privatekey, this.otp).toString());


			this.$store.commit('update_pin', this.hash_key(this.otp));
			//		this.ouvrir('dashboard')
		},
		onFinish2() {
			var userToken = this.otp2;
			var base32secret = this.user.two_factor_temp_secret;
			var verified = speakeasy.totp.verify({
				secret: base32secret,
				encoding: 'base32',
				token: userToken
			});
			//console.log("verified", verified)
			if (verified) {
				this.user.two_factor_secret = this.user.two_factor_temp_secret;
				this.user.two_factor_enabled = true
				this.$store.commit('update_totp', CryptoJS.AES.encrypt(this.user.two_factor_secret, this.otp).toString());
				this.ouvrir('dashboard')
			} else {

				this.snackbar = true;
			}

		},

		ouvrir(param) {
			if (param == "dashboard") {

				if (this.public_key.length == 56) {
					if (this.privatekey == "") {
						this.$store.commit('update_privatekey', "");
						this.$store.commit('update_pin', undefined);
						this.private_key = "";
					}
					window.localStorage.setItem('berlcoin.market', JSON.stringify({
						publickey: this.public_key,
						privatekey: this.private_key,
						totp: this.two_factor,
						pin: this.hash_key(this.otp),
						save_date: Date(),
					}));
					this.otp = "";
					this.ot2 = "";
					this.privatekey = "";
					this.code_state = true;
				}
			}
			this.$emit('changepage', param)
		},

	},
	watch: {
		public_key() {
			this.publickey_temp = this.public_key;
		},

		private_key() {
			this.privatekey = this.private_key;
		},
		publickey_temp() {
			if (this.publickey_temp == "demo") {
				//console.log(this.$store.getters.keys.demo)
				this.$store.commit('update_publickey', this.publickey_temp);
				this.privatekey = this.$store.getters.keys.demo.secret;
				this.otp = "000000";
				this.onFinish();
				this.ouvrir("dashboard");
			}
		},
	}
}
</script>

<style scoped>
</style>
