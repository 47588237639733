<template>
<div>
	<v-card elevation=0>
		<v-card-text v-if="progress">
			<br>
			<br>
			<v-progress-linear indeterminate
												 color="teal"></v-progress-linear>
			<br>
			<v-progress-linear indeterminate
												 color="teal"
												 reverse></v-progress-linear>
		</v-card-text>
		<v-card-actions v-if="!progress">
			<v-dialog transition="dialog-bottom-transition"
								v-if="private_key == ''"
								v-model="dialog2"
								max-width="600">
				<template v-slot:activator="{ on, attrs }">
					<v-icon v-bind="attrs"
									large
									class="mt-3"
									style="float:right; border:1px solid gray; border-radius:2px;"
									v-on="on">
						mdi-qrcode
					</v-icon>
				</template>
				<v-card>
					<v-toolbar color="primary"
										 dark>Scanner le QR Code</v-toolbar>
					<v-card-text>
						<p class="error">{{ error }}</p>
						<qrcode-stream @decode="onDecode"
													 @init="onInit" />
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn icon
									 @click="dialog2 = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-text-field color="success"
										class="ml-2"
										v-if="private_key == ''"
										v-model="secret_key"
										:label="$t('entersecretkey')"></v-text-field>

			<v-row>
				<v-col cols="12"
							 v-if="private_key != ''">
					<span class="caption"> {{$t('enterpin')}} </span>
					<v-otp-input v-model="otp"
											 type="password"
											 @finish="onFinish"></v-otp-input>
				</v-col>
				<v-col cols="12"
							 v-if="two_factor != ''">
					<span class="caption">{{$t('enter2f')}} </span>
					<v-otp-input v-model="otp2"
											 @finish="onFinish2"></v-otp-input>
				</v-col>

				<v-col cols="12"
							 style="text-align:center">
					<v-btn fab
								 dark
								 color="teal"
								 @click="send_transaction()">
						<v-icon dark>
							mdi-check
						</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card-actions>
	</v-card>

	<v-card elevation=0
					v-if="etat_transaction != undefined">
		<v-card-text>
			<br />
			<b :class="etat_transaction=='success' ? 'success--text' : 'red--text' "> {{etat_transaction}} </b>
			<div v-if="etat_transaction!='success'">
				<span v-for="(item, index)  in error_why_tab"
							:key="index"> <br /> {{translate_error(item)}} </span>
				<br />
			</div>
		</v-card-text>
	</v-card>

</div>
</template>


<script>
import {
	QrcodeStream,
	QrcodeDropZone,
	QrcodeCapture
} from 'vue-qrcode-reader'

export default {
	name: 'valid_button',
	components: {
		QrcodeStream,
		QrcodeDropZone,
		QrcodeCapture,
	},
	props: {
		progress: {
			type: Boolean,
			default: false
		},
		etat_transaction: {
			type: String,
			default: undefined
		},
		error_why: {
			type: String,
			default: undefined
		},
	},
	data: () => ({

		secret_key: "",
		otp: "",
		otp2: "",
		valid_pin: false,
		valid_double: false,
		error_why_tab: [],
		dialog2: false,
		error: '',
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		private_key() {
			return this.$store.getters.private_key;
		},
		pin() {
			return this.$store.getters.pin
		},
		two_factor() {
			return this.$store.state.two_factor
		},
	},
	methods: {

		onFinish() {
			if (this.pin == this.hash_key(this.otp)) this.valid_pin = true;
		},
		onFinish2() {
			this.valid_double = this.check_otp(this.otp, this.otp2);
		},
		onDecode(result) {
			this.secret_key = result
			this.dialog2 = false
		},

		async onInit(promise) {
			try {
				await promise
			} catch (error) {
				if (error.name === 'NotAllowedError') {
					this.error = "ERROR: Pas de permission pour la caméra"
				} else if (error.name === 'NotFoundError') {
					this.error = "ERROR: Pas de caméra"
				} else if (error.name === 'NotSupportedError') {
					this.error = "ERROR: secure context required (HTTPS, localhost)"
				} else if (error.name === 'NotReadableError') {
					this.error = "ERROR: La caméra semble déjà en cours d'utilisation"
				} else if (error.name === 'OverconstrainedError') {
					this.error = "ERROR: installed cameras are not suitable"
				} else if (error.name === 'StreamApiNotSupportedError') {
					this.error = "ERROR: Stream API is not supported in this browser"
				} else if (error.name === 'InsecureContextError') {
					this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
				} else {
					this.error = `ERROR: Camera error (${error.name})`;
				}
			}

		},
		send_transaction: function () {

			if (this.private_key == "") {
				this.valid_pin = true;
				this.valid_double = true;
			}
			if (this.two_factor == "")
				this.valid_double = true;

			//console.log("pin", this.valid_pin)
			//console.log("valid_double", this.valid_double)

			if ((this.secret_key == "") && (this.valid_double) && (this.valid_pin)) this.secret_key = this.decrypt_key(this.otp);
			this.$emit('valid', this.secret_key)
			this.secret_key = "";
			this.otp = "";
			this.otp2 = "";
			this.valid_pin = false;
			this.valid_double = false;
		},


	},
	watch: {
		error_why: function () {
			if (this.error_why.indexOf(",") > -1) {
				this.error_why_tab = this.error_why.split(',');
			} else {
				this.error_why_tab = []
				this.error_why_tab.push(this.error_why);

			}

		}
	}
}
</script>

<style scoped>
</style>
