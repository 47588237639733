<template>
<div>
	<v-snackbar v-model="snackbar"
							color="error"
							:timeout="timeout">
		{{ text }}

		<template v-slot:action="{ attrs }">
			<v-btn color="blue"
						 v-show="false"
						 small
						 v-bind="attrs"
						 @click="snackbar = false">
				x
			</v-btn>
		</template>
	</v-snackbar>


	<v-stepper v-model="e1"
						 class="ma-0">
		<v-stepper-header>
			<v-stepper-step :complete="e1 > 1"
											step="1">

			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step :complete="e1 > 2"
											step="2">

			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step :complete="e1 > 3"
											step="3">

			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step :complete="e1 > 4"
											step="4">

			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step :complete="e1>5"
											step="5">

			</v-stepper-step>

			<v-divider></v-divider>
			<v-stepper-step :complete="e1>6"
											v-if="public_key==undefined"
											step="6">

			</v-stepper-step>

			<v-divider v-if="public_key==undefined"></v-divider>

			<v-stepper-step :step="public_key==undefined ? 7 : 6">

			</v-stepper-step>

		</v-stepper-header>

		<v-stepper-items>
			<v-stepper-content step="1">

				<v-row>
					<v-col cols="12">

						<b class="primary--text"
							 v-html="$t('messagekyc1')" />
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="emailcontact"
													label="Email"
													:error="emailcontact==''"
													counter
													maxlength="199"></v-text-field>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="telephone"
													:label="$t('phone')"
													:error="telephone==''"
													counter
													maxlength="199"></v-text-field>
					</v-col>


				</v-row>



				<v-btn small
							 color="success"
							 @click="e1 =e1+1">
					{{$t('next')}}
				</v-btn>

			</v-stepper-content>

			<v-stepper-content step="2">
				<v-row>
					<v-col cols="12">
						<b class="primary--text"
							 v-html="$t('messagekyc2')" />
						<br /> {{$t('messagekyc3')}}
					</v-col>


					<v-col cols="12"
								 sm="6">
						<v-select v-model="civilite"
											:error="civilite==''"
											:items="$t('civilite_items')"
											:label="$t('civility')"> </v-select>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="name"
													:label="$t('lastname')"
													:error="name==''"
													counter
													maxlength="199"></v-text-field>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="firstname"
													:label="$t('firstname')"
													counter
													:error="firstname==''"
													maxlength="199"></v-text-field>
					</v-col>



					<v-col cols="12"
								 sm="6">

						<template>
							<div>
								<v-menu ref="menudatenaissance"
												v-model="menudatenaissance"
												:close-on-content-click="false"
												transition="scale-transition"
												offset-y
												min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="datenaissance"
																	:label="$t('birthday')"
																	:error="datenaissance==''"
																	readonly
																	v-bind="attrs"
																	v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="datenaissance"
																 :active-picker.sync="activePickerdatenaissance"
																 :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
																 min="1920-01-01"
																 @change="savedatenaissance"></v-date-picker>
								</v-menu>
							</div>
						</template>

					</v-col>


					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="departementnaissance"
													:label="$t('departmentofbirth')"
													counter
													:error="departementnaissance==''"
													maxlength="9"></v-text-field>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="villenaissance"
													:label="$t('cityofbirth')"
													:error="villenaissance==''"
													counter
													maxlength="99"></v-text-field>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-autocomplete v-model="paysnaissance"
														:items="$t('id_pays')"
														:label="$t('countryofbirth')"
														:error="paysnaissance==''"> </v-autocomplete>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-autocomplete v-model="nationalite"
														:items="$t('id_pays')"
														:label="$t('nationality')"
														:error="nationalite==''"> </v-autocomplete>
					</v-col>
					<v-col cols="12"
								 sm="6">

						<v-autocomplete v-model="profession"
														:label="$t('messagekyc10')"
														:items="$t('id_metier')"> </v-autocomplete>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-select v-model="situationfamille"
											:items="$t('situationfamille_items')"
											:label="$t('familyfstatus')"
											:error="situationfamille==''"> </v-select>
					</v-col>
				</v-row>

				<v-btn small
							 color="success"
							 @click="e1 =e1+1">
					{{$t('next')}}
				</v-btn>
				<v-btn small
							 text
							 @click="e1 =e1-1">
					{{$t('back')}}
				</v-btn>
			</v-stepper-content>

			<v-stepper-content step="3">
				<v-row>
					<v-col cols="12">

						<b class="primary--text"
							 v-html="$t('messagekyc5')" />
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-autocomplete v-model="residencefiscale"
														:items="$t('id_pays')"
														:label="$t('taxresidence')"
														:error="residencefiscale==''"> </v-autocomplete>
					</v-col>


					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="adresse"
													:label="$t('address')"
													counter
													:error="adresse==''"
													maxlength="199"></v-text-field>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="codepostal"
													:label="$t('zipcode')"
													counter
													:error="codepostal==''"
													maxlength="199"></v-text-field>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="ville"
													:label="$t('messagekyc6')"
													counter
													:error="ville==''"
													maxlength="199"></v-text-field>
					</v-col>



					<v-col cols="12">

						<b class="primary--text">Si Société</b>
					</v-col>


					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="siren"
													:label="$t('messagekyc8')"
													counter
													maxlength="199"></v-text-field>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="raison_sociale"
													:label="$t('messagekyc9')"
													counter
													maxlength="199"></v-text-field>
					</v-col>

				</v-row>

				<v-btn small
							 color="success"
							 @click="e1 =e1+1">
					{{$t('next')}}
				</v-btn>
				<v-btn small
							 text
							 @click="e1 =e1-1">
					{{$t('back')}}
				</v-btn>
			</v-stepper-content>


			<v-stepper-content step="4">
				<v-row>
					<v-col cols="12">

						<b class="primary--text"
							 v-html="$t('Banque')" />
					</v-col>
					<span class="ml-3"
								v-html="$t('messagekyc11')" />

					<v-col cols="12"
								 sm="6">
						<v-text-field v-on:blur="verif_iban()"
													v-model="iban"
													:label="$t('iban')"
													:error="iban==''"
													counter
													maxlength="199"></v-text-field>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="bic"
													:label="$t('bic')"
													counter
													:error="bic==''"
													maxlength="199"></v-text-field>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="nomiban"
													:label="$t('nameoftheaccountholder')"
													counter
													:error="nomiban==''"
													maxlength="199"></v-text-field>
					</v-col>

				</v-row>

				<v-btn small
							 color="success"
							 @click="e1 =e1+1">
					{{$t('next')}}
				</v-btn>
				<v-btn small
							 text
							 @click="e1 =e1-1">
					{{$t('back')}}
				</v-btn>
			</v-stepper-content>
			<v-stepper-content step="5">
				<v-row>
					<v-col cols="12">

						<b class="primary--text"
							 v-html="$t('messagekyc33')+' 1'" />
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-select v-model="typeid1"
											:items="$t('id_items')"
											:label="$t('credentialtype1')"
											:error="typeid1==''"> </v-select>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="numeroid1"
													:label="$t('credentialnumber1')"
													counter
													:error="numeroid1==''"
													maxlength="199"></v-text-field>
					</v-col>




					<v-col cols="12"
								 sm="6">

						<template>

							<v-menu ref="menudatevalide1"
											v-model="menudatevalide1"
											:close-on-content-click="false"
											transition="scale-transition"
											offset-y
											min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="datevalide1"
																:label="$t('messagekyc34')"
																:error="datevalide1==''"
																readonly
																v-bind="attrs"
																v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="datevalide1"
															 :active-picker.sync="activePickerdatevalide1"
															 :min="datemindatevalide1"
															 @change="savedatevalide1"></v-date-picker>
							</v-menu>

						</template>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-file-input show-size
													:error="file_ci1==''"
													:rules="uploadrules"
													counter
													accept="image/png, image/jpeg , application/pdf"
													:placeholder="$t('messagekyc12')+' '+name+' '+firstname"
													@blur="blur('file_ci1')"
													@change="upload"> </v-file-input>
					</v-col>
					<v-col cols="12">

						<b class="primary--text"
							 v-html="$t('messagekyc33')+' 2'" />
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-select v-model="typeid2"
											:items="$t('id_items')"
											:label="$t('messagekyc33')+' 2'"
											:error="typeid2==''"> </v-select>
					</v-col>
					<v-col cols="12"
								 sm="6">
						<v-text-field v-model="numeroid2"
													:error="numeroid2==''"
													:label="$t('credentialnumber2')"
													counter
													maxlength="199"></v-text-field>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<template>
							<v-menu ref="menudatevalide2"
											v-model="menudatevalide2"
											:close-on-content-click="false"
											transition="scale-transition"
											offset-y
											min-width="auto">
								<template v-slot:activator="{ on, attrs2 }">
									<v-text-field v-model="datevalide2"
																:label="$t('messagekyc34')"
																:error="datevalide2==''"
																readonly
																v-bind="attrs2"
																v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="datevalide2"
															 :min="datemindatevalide2"
															 :active-picker.sync="activePickerdatevalide2"
															 @change="savedatevalide2"></v-date-picker>
							</v-menu>
						</template>

					</v-col>


					<v-col cols="12"
								 sm="6">

						<v-file-input show-size
													:error="file_ci2==''"
													:rules="uploadrules"
													counter
													accept="image/png, image/jpeg , application/pdf"
													:placeholder="$t('messagekyc12')+' '+name+' '+firstname"
													@blur="blur('file_ci2')"
													@change="upload"> </v-file-input>

					</v-col>

					<v-col cols="12">

						<b class="primary--text"
							 v-html="$t('messagekyc13')" />
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-select v-model="typedom"
											:items="$t('domicile_items')"
											:label="$t('messagekyc37')"
											:error="typeid2==''"> </v-select>
					</v-col>

					<v-col cols="12"
								 sm="6">
						<v-file-input show-size
													:error="file_dom==''"
													:rules="uploadrules"
													counter
													accept="image/png, image/jpeg , application/pdf"
													:placeholder="$t('messagekyc35')+' '+adresse+' '+codepostal +' '+ville"
													@blur="blur('file_dom')"
													@change="upload"> </v-file-input>

					</v-col>

					<v-col cols="12"
								 sm="6">

						<v-file-input show-size
													:error="file_iban==''"
													:rules="uploadrules"
													counter
													accept="image/png, image/jpeg , application/pdf"
													:placeholder="$t('messagekyc14')+' '+iban"
													@blur="blur('file_iban')"
													@change="upload"> </v-file-input>

					</v-col>
					<span class="ma-4">
						<h5 v-html="$t('messagekyc15')" />
						<p style="font-size:80%">
							<br /> - {{$t('messagekyc16')}}
							<br /> - {{$t('messagekyc17')}}
							<br /> - {{$t('messagekyc18')}}
							<br /> - {{$t('messagekyc19')}}
							<br /> - {{$t('messagekyc20')}}
							<br /> - {{$t('messagekyc21')}}
							<br /> - {{$t('messagekyc22')}}(<b class="text-red">{{name}} {{firstname}}</b>) {{$t('messagekyc23')}}
							<br />
							<br />
							{{$t('messagekyc24')}}
							<br /> {{$t('messagekyc25')}}
						</p>
					</span>

				</v-row>

				<v-btn small
							 color="success"
							 @click="public_key != undefined  ? e1 =e1+2 :  e1 =e1+1">
					{{$t('next')}}
				</v-btn>
				<v-btn small
							 text
							 @click="e1 =e1-1">
					{{$t('back')}}
				</v-btn>
			</v-stepper-content>
			<v-stepper-content step="6">
				<v-row>
					<v-col cols="12">
						<b class="primary--text"
							 v-html="$t('messagekyc26')" />
						<br />
						<br />
						<b v-html="$t('messagekyc27')" />
						<br />
						<span class="pb-3"
									v-if="source_keys != undefined"
									style="font-size:75%"> {{source_keys.publicKey()}}</span>
						<br />
						<b v-html="$t('messagekyc28')" />

						<br />
						<span v-if="source_keys != undefined"
									style="font-size:75%"> {{source_keys.secret()}}</span>
						<br />


					</v-col>
					<v-col cols="6"
								 v-if="source_keys != undefined"
								 sm="6">
						<qrcode-vue :value="source_keys.publicKey()"></qrcode-vue>
						{{$t('messagekyc27')}}

					</v-col>
					<v-col cols="6"
								 v-if="source_keys != undefined"
								 sm="6">
						<qrcode-vue :value="source_keys.secret()"></qrcode-vue>
						{{$t('messagekyc28')}}
					</v-col>
					<v-col cols="12"
								 v-if="source_keys != undefined">
						{{$t('messagekyc29')}}
						<br /> {{$t('messagekyc30')}}
						<br /> <br /> {{$t('messagekyc31')}}
						<v-switch v-model="switchconfirm"
											class="mx-4"
											:label="$t('messagekyc36')"
											inset></v-switch>

					</v-col>
				</v-row>
				<v-row>

					<v-col cols="12"
								 v-if="source_keys != undefined"
								 sm="6">



					</v-col>
					<v-col cols="12"
								 v-if="false"
								 sm="6">

					</v-col>
				</v-row>

				<v-btn class="success my-4 mr-6">
					<A style="text-decoration:none;"
						 class="  white--text "
						 href="javascript:window.print()">
						<v-icon dark> mdi-printer </v-icon>
					</A>
				</v-btn>
				<v-btn small
							 v-if="switchconfirm"
							 color="success"
							 @click="e1 =e1+1">
					{{$t('next')}}
				</v-btn>
				<v-btn small
							 text
							 @click="e1 =e1-1">
					{{$t('back')}}
				</v-btn>

			</v-stepper-content>
			<v-stepper-content step="7">
				<v-row>
					<v-col cols="12">

						<b class="primary--text"
							 v-html="$t('messagekyc32')" />
					</v-col>

					<table class="pa-3">

						<tr>
							<td><span class="caption"
											v-html="$t('civility')" />
							</td>
						</tr>
						<tr>


							<td><b v-if="civilite > 0"
									 class="red--text"> {{$t('civilite_items')[civilite-1].text}}</b></td>

						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('lastname')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{name}}</b>
							</td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('firstname')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{firstname}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('birthday')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{datenaissance}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('departmentofbirth')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{departementnaissance}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('cityofbirth')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{villenaissance}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('nationality')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{nationalite}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('familyfstatus')" />
							</td>
						</tr>
						<tr>
							<td><b v-if="situationfamille > 0"
									 class="red--text"> {{$t('situationfamille_items')[situationfamille-1].text}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('countryofbirth')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{paysnaissance}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('taxresidence')" />

							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{residencefiscale}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('address')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{adresse}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('zipcode')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{codepostal}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('city')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{ville}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('iban')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{iban}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('bic')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{bic}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('nameoftheaccountholder')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{nomiban}}</b></td>
						</tr>
						<tr>
							<td><span class="caption">Email</span>
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{emailcontact}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('phone')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{telephone}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('credentialtype1')" />
							</td>
						</tr>
						<tr>
							<td><b v-if="typeid1 > 0"
									 class="red--text">{{$t('id_items')[typeid1-1].text}} </b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('credentialdate1')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{datevalide1}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('credentialnumber1')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{numeroid1}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('typeofdocument2')" />
							</td>
						</tr>
						<tr>
							<td><b v-if="typeid2 > 0"
									 class="red--text"> {{$t('id_items')[typeid2-1].text}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('dateofdocument2')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{datevalide2}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('credentialnumber2')" />
							</td>
						</tr>
						<tr>
							<td><b class="red--text"> {{numeroid2}}</b></td>
						</tr>
						<tr>
							<td><span class="caption"
											v-html="$t('typedom')" />
							</td>
						</tr>
						<tr>
							<td><b v-if="typedom > 0"
									 class="red--text">{{$t('domicile_items')[typedom-1].text}} </b></td>
						</tr>

					</table>

				</v-row>
				<v-btn small
							 v-if="switchconfirm"
							 color="success"
							 @click="validationfinale()">{{switchconfirm ? $t('validateyourkyc') : $t('switchvalidation') }}

				</v-btn>
				<v-btn small
							 text
							 @click="public_key != undefined ? e1 =e1-2 :  e1 =e1-1">


					{{$t('back')}}
				</v-btn>
			</v-stepper-content>
		</v-stepper-items>
	</v-stepper>
</div>
</template>


<script>
var StellarSdk = require('stellar-sdk');

import IBAN from "iban";
import QrcodeVue from 'qrcode.vue'


export default {
	name: 'ouverture',
	components: {
		QrcodeVue
	},
	props: {},
	data: () => ({
		rules: {
			required: value => !"" || 'Indispensable.',
		},
		e1: 1,
		uploadrules: [

			(value) =>
			!value ||
			value.size < 2000000 ||
			"Ne doit pas dépasser 2Mo",

		],


		activePickerdatenaissance: null,
		menudatenaissance: false,

		activePickerdatevalide1: null,
		menudatevalide1: false,

		activePickerdatevalide2: null,
		menudatevalide2: false,

		datemindatevalide1: undefined,
		datemindatevalide2: undefined,

		move_tab: true,
		tab: null,
		table_id: 0,

		id: "",
		private_key: "",

		save_associate: [],
		axios_private: [],
		data_list: [],
		search: '',


		raison_sociale: '',
		civilite: '',
		name: '',
		firstname: '',
		datenaissance: '',
		departementnaissance: '',
		villenaissance: '',
		nationalite: '',
		profession: '',
		situationfamille: '',
		residencefiscale: '',
		paysnaissance: '',
		iban: '',
		bic: '',
		nomiban: '',
		siren: '',
		emailcontact: '',
		typeid1: '',
		typeid2: '',
		typedom: '',
		numeroid1: '',
		datevalide1: '',
		numeroid2: '',
		datevalide2: '',
		telephone: '',
		adresse: '',
		codepostal: '',
		ville: '',
		file_ci1: '',
		file_ci2: '',
		file_dom: '',
		file_iban: '',
		crypt: '',
		origin_upload: '',
		snackbar: false,
		text: 'IBAN FALSE !!! ',
		timeout: 2000,
		step: 1,

		nofield: "",
		source_keys: undefined,
		switchconfirm: false,
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		let datenow = new Date();
		this.datemindatevalide1 = datenow.toISOString().slice(0, 10);
		this.datemindatevalide2 = datenow.toISOString().slice(0, 10);
		this.source_keys = StellarSdk.Keypair.random();

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {


		public_key() {
			if (this.$store.getters.public_key != undefined) this.switchconfirm = true;
			return this.$store.getters.public_key;
		},
	},
	methods: {

		verif_iban() {
			let valid = IBAN.isValid(this.iban);
			this.text = "Erreur dans l'IBAN"
			if (!valid) this.snackbar = true
			//console.log("verif iban :" + valid)

		},
		validationfinale() {
			this.text = "C'est sauvegardé";
			this.nofield = "";
			this.snackbar = true;
			this.e1 = 8;
			//	this.save_table_associate();

		},

		savedatenaissance(date) {
			this.$refs.menudatenaissance.save(date)
		},
		savedatevalide1(date) {
			this.$refs.menudatevalide1.save(date)
		},
		savedatevalide2(date) {
			this.$refs.menudatevalide2.save(date)
		},
		save_table_associate() {


			this.opt = [];
			this.opt["api"] = "save_kyc"
			this.opt["debug"] = false
			this.opt["id"] = "0"
			this.opt["civilite"] = this.civilite
			this.opt["name"] = this.name
			this.opt["firstname"] = this.firstname
			this.opt["datenaissance"] = this.datenaissance
			this.opt["departementnaissance"] = this.departementnaissance
			this.opt["villenaissance"] = this.villenaissance
			this.opt["nationalite"] = this.nationalite
			this.opt["profession"] = this.profession
			this.opt["situationfamille"] = this.situationfamille
			this.opt["residencefiscale"] = this.residencefiscale

			this.opt["raison_sociale"] = this.raison_sociale
			this.opt["paysnaissance"] = this.paysnaissance
			this.opt["iban"] = this.iban
			this.opt["bic"] = this.bic
			this.opt["nomiban"] = this.nomiban
			this.opt["siren"] = this.siren
			this.opt["emailcontact"] = this.emailcontact
			this.opt["typeid1"] = this.typeid1
			this.opt["typeid2"] = this.typeid2
			this.opt["numeroid1"] = this.numeroid1
			this.opt["numeroid2"] = this.numeroid2
			this.opt["telephone"] = this.telephone
			this.opt["adresse"] = this.adresse
			this.opt["codepostal"] = this.codepostal
			this.opt["ville"] = this.ville
			this.opt["datevalide1"] = this.datevalide1
			this.opt["datevalide2"] = this.datevalide2
			this.opt["step"] = this.e1 - 1
			this.opt["public_key"] = this.public_key
			this.opt["typedom"] = this.typedom






			this.$store.dispatch('post', this.opt).then(response => {
				if (response != undefined) this.save_associate = response.data
			})
		},

		blur: function (origin) {

			this.origin_upload = origin;
		},
		upload: function (file) {

			//		this.internal_key = "?W.fzQzeMQKo5HgD;K@z8-.En"

			this.opt = [];
			this.opt["api"] = "kyc_document"
			this.opt["debug"] = false
			this.opt["file"] = file
			this.opt["key"] = this.public_key
			this.opt["origin"] = this.origin_upload

			if (this.origin_upload == "file_ci1") this.file_ci1 = "ok";
			if (this.origin_upload == "file_ci2") this.file_ci2 = "ok";
			if (this.origin_upload == "file_dom") this.file_dom = "ok";
			if (this.origin_upload == "file_iban") this.file_iban = "ok";



			this.$store.dispatch('upload', this.opt).then(response => {
				if (response != undefined) {

					//console.log(response);

					//			this.save_associate = response.data
				}
			})

		},
		extention: function (filename) {
			return filename.split('.').pop();
		},

	},
	watch: {
		e1() {


			if (this.e1 == 2) {

				if (this.emailcontact == "") this.nofield = this.nofield + " " + "Email ";
				if (this.telephone == "") this.nofield = this.nofield + " " + this.$t('phone');
			}

			if (this.e1 == 3) {
				if (this.emailcontact == "") this.nofield = this.nofield + " " + "Email ";
				if (this.telephone == "") this.nofield = this.nofield + " " + this.$t('phone');
				if (this.civilite == "") this.nofield = this.nofield + " " + this.$t('civility');
				if (this.firstname == "") this.nofield = this.nofield + " " + this.$t('firstname');
				if (this.name == "") this.nofield = this.nofield + " " + this.$t('name');
				if (this.datenaissance == "") this.nofield = this.nofield + " " + this.$t('birthday');
				if (this.departementnaissance == "") this.nofield = this.nofield + " " + this.$t('departmentofbirth');
				if (this.villenaissance == "") this.nofield = this.nofield + " " + this.$t('cityofbirth');
				if (this.paysnaissance == "") this.nofield = this.nofield + " " + this.$t('countryofbirth');
				if (this.nationalite == "") this.nofield = this.nofield + " " + this.$t('nationality');
				if (this.situationfamille == "") this.nofield = this.nofield + " " + this.$t('familyfstatus');
			}

			if (this.e1 == 4) {
				if (this.residencefiscale == "") this.nofield = this.nofield + " " + this.$t('taxresidence');
				if (this.adresse == "") this.nofield = this.nofield + " " + this.$t('address');
				if (this.codepostal == "") this.nofield = this.nofield + " " + this.$t('zipcode');
				if (this.ville == "") this.nofield = this.nofield + " " + this.$t('city');
			}

			if (this.e1 == 5) {
				if (this.iban == "") this.nofield = this.nofield + " " + this.$t('iban');
				if (this.bic == "") this.nofield = this.nofield + " " + this.$t('bic');
				if (this.nomiban == "") this.nofield = this.nofield + " " + this.$t('nameoftheaccountholder');
			}

			if ((this.e1 == 6) || (this.e1 == 7)) {
				if (this.numeroid1 == "") this.nofield = this.nofield + " " + this.$t('credentialnumber1');
				if (this.numeroid2 == "") this.nofield = this.nofield + " " + this.$t('credentialnumber2');
				if (this.typeid1 == "") this.nofield = this.nofield + " " + this.$t('credentialtype1');
				if (this.typeid2 == "") this.nofield = this.nofield + " " + this.$t('credentialtype2');
				if (this.datevalide1 == "") this.nofield = this.nofield + " " + this.$t('credentialdate1');
				if (this.datevalide2 == "") this.nofield = this.nofield + " " + this.$t('dateofdocument2');

				if (this.file_ci1 == "") this.nofield = this.nofield + " " + this.$t('messagekyc33') + " 1";
				if (this.file_ci2 == "") this.nofield = this.nofield + " " + this.$t('messagekyc33') + " 2";
				if (this.file_dom == "") this.nofield = this.nofield + " " + this.$t('messagekyc35');
				if (this.file_iban == "") this.nofield = this.nofield + " " + this.$t('iban');


			}



			if (this.nofield != "") {
				this.e1 = this.e1 - 1;
				this.text = this.$t('messagekyc38') + ": " + this.nofield;
				this.nofield = "";
				this.snackbar = true
			}
			this.save_table_associate();
		},

		menu(val) {
			val && setTimeout(() => (this.activePicker = 'YEAR'))
		},
		menudatenaissance(val) {
			val && setTimeout(() => (this.activePickerdatenaissance = 'YEAR'))
		},
		menudatevalide1(val) {
			val && setTimeout(() => (this.activePickerdatevalide1 = 'YEAR'))
		},
		menudatevalide2(val) {
			val && setTimeout(() => (this.activePickerdatevalide2 = 'YEAR'))
		},
		save_associate: function () {
			if (this.e1 == 8) {

				this.$emit('changepage', "dashboard")
			}
		},

	}
}
</script>

<style scoped>
</style>
