<template>
<div>
	<v-alert v-if="selection != undefined && operation != 'amm_buy'"
	dense
	text
	class="m-1"
	type="success"
>
	{{Number(selection.balance - selection.selling_liabilities).toString(7)}} <b> {{selection.asset_code}}</b>
		<span v-if="selection.selling_liabilities > 0"> {{( '(Selling offers: '+ selection.selling_liabilities).toString(7) +')'}}  </span>
</v-alert>


	<v-list two-line  v-if="display=='list'"
					style="height:450px; overflow-y: scroll;">

			<template v-for="(item, index) in balances">
				<v-list-item :key="item.title" @click="selected(item)"
										 v-if="(item.asset_issuer == $store.getters.account_key('sequestre') && (item.asset_type=='credit_alphanum4' || item.asset_type=='credit_alphanum12' || item.asset_type=='native')) && !(operation=='amm_sell' && item.asset_code == currency.asset_code)">
					<template v-slot:default="{ active }">
						<v-list-item-content class="py-2">
							<v-list-item-title>
								<v-chip color="primary" v-show="operation != 'amm_buy'"
												label>


									<b v-html="monetary_aspect(item.balance)" />
								</v-chip>

								<b class="pl-3"> {{ item.asset_code}}</b>
							</v-list-item-title>

							<v-list-item-subtitle class="text--primary">
								{{ (item.asset_type == 'native' || parseInt(item.limit) > 1000000) ? '∞' : ( parseInt(item.limit) == 1 ? 'Unique' :  parseInt(item.limit) )}}
								by {{ item.asset_type == 'native' ? 'Stellar Foundation' : account_name(item.asset_issuer,false)}}


							</v-list-item-subtitle>


						</v-list-item-content>
						<v-list-item-action class="py-2" v-show="operation != 'amm_buy' && item.price_total > 0 ">
							<v-chip label>
								<v-icon left
												small
												color="primary">
									mdi-currency-eur
								</v-icon>
								<span v-html="monetary_aspect(item.price_total)" />
							</v-chip>
							<v-list-item-action-text v-html="monetary_aspect(item.destination_amount)+' '+ item.destination_asset_code+ ' '+monetary_aspect(item.price_unit)" />
						</v-list-item-action>


					</template>
				</v-list-item>

				<v-divider v-if=" item.asset_type=='credit_alphanum4' || item.asset_type=='credit_alphanum12' || item.asset_type=='native'"
									 :key="index"></v-divider>
			</template>

	</v-list>

		<div v-if="display=='form'">
			<stellarform :operation="operation" v-if="operation != undefined"
									 css="pa-3"
									 :amount="selection.balance"
									 :send_asset="send_asset"
									 @result="stellarform_result" />
			<v-divider></v-divider>

			<v-btn icon
						 @click="display='list';$emit('event', 'show_list')"
						 color="warning">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>


		</div>
	</div>
	</template>


<script>
import stellarform from '@/components/stellar_form.vue'
export default {
	name: 'list_balances',
	components: {
		stellarform
	},
	props: {
		show: {
			type: String,
			default: 'list'
		},
		operation: {
			type: String
		},

	},
	data: () => ({
		selection: undefined,
		display: "list",
		send_asset: undefined,
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		balances() {
			return this.operation != "amm_buy" ? this.$store.getters.balances('wallet') : this.$store.getters.assets;
		},
		currency() {
			return this.$store.getters.currency;
		},
	},
	methods: {
		stellarform_result(result) {
			if (result) {

				this.display = "list";
				this.$emit('askaction', "init")
				//			console.log("askaction - list balance")
			}
			//		console.log(result)
		},
		selected(item) {
			if (item != undefined) {
				console.log(item)
				this.send_asset = {
					"asset_code": item.asset_code,
					"asset_issuer": item.asset_issuer,
					"asset": item.asset_code + ':' + item.asset_issuer,
					"destination": item.destination,
					"destination_asset_code": item.destination_asset_code,
					"destination_asset_issuer": item.destination_asset_issuer,
				}
				if (item.destination_asset_code != undefined) {
					this.display = "form";
					this.$emit('event', "show_form")
					this.selection = item;
				}
			}
		}
	},
	watch: {
		display() {
			if (this.display == "list") this.selection = undefined
		},
		show() {
			if (this.show == 'new') {
				this.order = undefined;
				this.display = 'form';
			}
		},

	}
}
</script>

<style scoped>
</style>
