<template>
<div>
	<v-row>

		<v-col cols=12>
			<template>
				<v-banner single-line
									elavation=0
									color="yellow lighten-5">
					<v-icon color="warning"
									@click="$emit('changepage', 'dashboard')"
									class="mr-6"
									size="24">
						mdi-home
					</v-icon>
					<v-icon color="success"
									class="mr-6"
									size="24">
						mdi-autorenew
					</v-icon>


					<template v-slot:actions>
						<v-btn class="mx-2"
									 fab
									 dark
									 small
									 @click="$emit('changepage', 'keys')"
									 :color="public_key == undefined ? 'red' : 'success'">
							<v-icon dark>
								mdi-lock
							</v-icon>
						</v-btn>
					</template>
				</v-banner>
			</template>
		</v-col>
	</v-row>

	<v-col v-show="public_key== undefined"
				 cols=12>
		<v-card elevation=0
						color="red darken-4"
						dark
						@click="$emit('changepage', 'keys')"
						class="mx-3 mt-3 pa-2"> {{$t('message_no_key')}}
		</v-card>
	</v-col>


	<v-row>
		<v-col cols=12
					 v-if="false"
					 sm=6
					 md=6
					 lg=4
					 class="ma-0 pa-0"
					 xl=3>

			<v-col cols=6
						 class="float-left">
				<v-card color="success"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/liquiditypool-on.png"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3">LIQUIDITY POOL<br /> Intégrer</p>
				</v-card>
			</v-col>

			<v-col cols=6
						 class="float-left">
				<v-card color="success"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/liquiditypool-off.png"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3">LIQUIDITY POOL<br /> Quitter</p>
				</v-card>
			</v-col>


			<v-col cols=6
						 class="float-left">
				<v-card color="success"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/liquiditypool-plus.png"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3">LIQUIDITY POOL<br /> Déposer</p>
				</v-card>
			</v-col>

			<v-col cols=6
						 class="float-left">
				<v-card color="success"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/liquiditypool-moins.png"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3">LIQUIDITY POOL<br /> Retirer </p>
				</v-card>
			</v-col>



		</v-col>


		<v-col cols=12
					 v-if="false"
					 sm=6
					 md=6
					 lg=4
					 class="ma-0 pa-0"
					 xl=3>

			<v-col cols=6
						 class="float-left">
				<v-card color="success"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/achat.png"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3">ACHAT </p>
				</v-card>
			</v-col>

			<v-col cols=6
						 class="float-left">
				<v-card color="success"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/vente.png"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3">VENTE </p>
				</v-card>
			</v-col>


			<v-col cols=6
						 class="float-left">
				<v-card color="success"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/envoi.png"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3">TRANSFERT<br> garantie<br> d'envoi </p>
				</v-card>
			</v-col>

			<v-col cols=6
						 class="float-left">
				<v-card color="success"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/reception.png"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3">TRANSFERT<br> garantie<br> de reception </p>
				</v-card>
			</v-col>



		</v-col>

		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 c
					 xl=3>

			<cardliquiditypool @askaction="askaction"
												 account="distributor" />

		</v-col>



		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=3>

			<cardliquiditypool @askaction="askaction"
												 account="wallet" />

		</v-col>
		<v-col cols=12
					 v-if="$store.getters.account_solde('token')>0"
					 sm=6
					 md=6
					 lg=4
					 xl=3>
			<cardtransfert @askaction="askaction"
										 label="Transfert"
										 operation="amm_transfert" />
		</v-col>
		<v-col cols=12
					 v-if="$store.getters.account_solde('token')>0"
					 sm=6
					 md=6
					 lg=4
					 xl=3>
			<cardtransfert @askaction="askaction"
										 label="Achat"
										 operation="amm_buy" />
		</v-col>

		<v-col cols=12
					 v-if="$store.getters.account_solde('token')>0"
					 sm=6
					 md=6
					 lg=4
					 xl=3>
			<cardtransfert @askaction="askaction"
										 label="Vente"
										 operation="amm_sell" />
		</v-col>







	</v-row>
</div>
</template>


<script>
import listbalances from "@/components/list_balances.vue"
import listliquiditypools from "@/components/list_liquidity_pools.vue"
import listclaimablebalances from "@/components/list_claimable_balances.vue"
import listsellingoffers from "@/components/list_selling_offers.vue"
import cardbuying from "@/components/card_buying.vue"
import cardselling from "@/components/card_selling.vue"

import cardliquiditypool from "@/components/card_liquidity_pool.vue"

import cardtransfert from "@/components/card_transfert.vue"

export default {
	name: 'secondary',
	components: {
		listbalances,
		listliquiditypools,
		listclaimablebalances,
		cardbuying,
		cardselling,
		listsellingoffers,

		cardliquiditypool,
		cardtransfert
	},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		public_key() {
			return this.$store.getters.public_key;
		},
	},
	methods: {
		askaction(action) {
			//console.log("askaction - amm")
			if (action == "init") this.$emit('askaction', "init")
		},
		change_page(page) {
			this.$emit('changepage', page)
		}
	},
	watch: {}
}
</script>

<style scoped>
</style>
