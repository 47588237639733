<template>
<div>
	<v-alert v-if="order != undefined"
					 dense
					 text
					 class="m-1 text-right"
					 type="success">

		1 {{order.reserves[0].asset_code}} = {{( order.reserves[1].amount /order.reserves[0].amount ).toFixed(5)   }} {{order.reserves[1].asset_code}} - {{ Number(order.balance).toFixed(3)}} Shares
	</v-alert>
	<v-list two-line
					v-if="display=='list'"
					style="min-height:450px; overflow-y: scroll;">
		<template v-for="(item, index) in liquidity_pools">
			<v-list-item v-show="item.master_asset_code"
									 :class="item.available? 'teal darken-5' : ''"
									 @click="select(item)"
									 :key="item.id">
				<template v-slot:default="{ active }">
					<v-list-item-content class="py-3 my-2  ">
						<v-list-item-title>
							<v-chip color="primary"
											label>
								<b v-html="account== 'distributor' ? monetary_aspect(item.total_shares) : monetary_aspect(item.balance)" />
							</v-chip>
							<b class="pl-3"> Shares</b>
						</v-list-item-title>
						<v-list-item-subtitle class="text--primary">
							<span v-html="monetary_aspect(item.reserves[0].amount)" /> <b> {{item.reserves[0].asset_code}} </b><br />
							<span v-html="monetary_aspect(item.reserves[1].amount)" /> <b> {{item.reserves[1].asset_code}} </b>
						</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action class="py-2">

						<v-chip label>
							<v-icon left
											small
											color="primary">
								mdi-currency-eur
							</v-icon>

							<span v-html="monetary_aspect(item.price_total)" />
						</v-chip>
						<v-list-item-action-text v-html=" monetary_aspect(item.price_unit)"></v-list-item-action-text>
					</v-list-item-action>
				</template>
			</v-list-item>
			<v-divider :key="index"></v-divider>
		</template>
	</v-list>

	<div v-if="display=='form'">
		<stellarform :operation="operation"
								 css="pa-3"
								 :order="order"
								 @result="stellarform_result" />
		<v-divider></v-divider>

		<v-btn icon
					 @click="back()"
					 color="warning">
			<v-icon>mdi-arrow-left</v-icon>
		</v-btn>
	</div>
</div>
</template>


<script>
import stellarform from '@/components/stellar_form.vue'
export default {
	name: 'list_liquidity_pools',
	components: {
		stellarform
	},
	props: {
		show: {
			type: String,
			default: 'list'
		},
		account:
		{
			type: String,
			default: 'distributor'
		}
	},
	data: () => ({
		operation:undefined,
		order: undefined,
		display: "list"
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		liquidity_pools() {
			return this.$store.getters.liquidity_pools(this.account)
		},
	},
	methods: {
		back()
		{
			this.order=undefined;
			this.display='list';
			this.$emit('event', 'show_list')
		},
select(item)
{
	if(item.available)
	{
		if (!item.wallet)
		{
			this.order=item
			this.operation = "liquidypool_addtrustline";
			this.display = 'form';
		}
	}

	if (this.account =="wallet")
	{
			this.order=item
		this.operation = "liquiditypool";
		this.display = 'form';
		this.$emit('event', "show_form")
	}

},
		stellarform_result(result) {
			if (result) {

				this.display = "list";
				this.$emit('askaction', "init")
					this.order = undefined;
}
		}
	},
	watch: {
		show() {
			if (this.show == 'new') {
				this.order = undefined;
				this.display = 'form';
			}
		},

	}
	}
	</script>

	<style scoped>
	</style>
