<template>
<div>
	<v-row>

		<v-col cols=12>
			<template>
				<v-banner single-line
									elavation=0
									color="yellow lighten-5">
					<v-icon color="warning"
									@click="$emit('changepage', 'dashboard')"
									class="mr-6"
									size="24">
						mdi-home
					</v-icon>
					<v-icon color="success"
									class="mr-6"
									size="24">
						mdi-circle-outline
					</v-icon>


					<template v-slot:actions>
						<v-btn class="mx-2"
									 fab
									 dark
									 small
									 @click="$emit('changepage', 'keys')"
									 :color="public_key == undefined ? 'red' : 'success'">
							<v-icon dark>
								mdi-lock
							</v-icon>
						</v-btn>
					</template>
				</v-banner>
			</template>
		</v-col>
	</v-row>

	<v-row>


		<v-col v-show="public_key== undefined"
					 cols=12>
			<v-card elevation=0
							color="red darken-4"
							dark
							@click="$emit('changepage', 'keys')"
							class="mx-3 mt-3 pa-2"> {{$t('message_no_key')}}
			</v-card>
		</v-col>



		<v-col cols=12
					 v-if="$store.getters.account_solde('token')>0"
					 sm=6
					 md=6
					 lg=4
					 xl=3>
			<cardtransfert label="Tokens"
										 @askaction="askaction"
										 operation="payment" />
		</v-col>

		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=3>
			<cardtransfertnft label=" NFTs"
												@askaction="askaction"
												operation="payment_nft" />
		</v-col>
		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 v-show="public_key != undefined"
					 xl=3>
			<cardclaimablebalances @askaction="askaction" />
		</v-col>

		<v-col cols=12
					 v-show="public_key!= undefined"
					 sm=6
					 md=6
					 lg=4
					 xl=3>
			<cardbuying @askaction="askaction" />
		</v-col>

		<v-col cols=12
					 v-show="public_key!= undefined"
					 sm=6
					 md=6
					 lg=4
					 xl=3>
			<cardselling @askaction="askaction" />
		</v-col>

		<v-col cols=12
					 v-show="public_key!= undefined"
					 sm=6
					 md=6
					 lg=4
					 xl=3>
			<cardbuyingnft @askaction="askaction" />
		</v-col>

		<v-col cols=12
					 v-show="public_key!= undefined"
					 sm=6
					 md=6
					 lg=4
					 xl=3>
			<cardsellingnft @askaction="askaction" />
		</v-col>

		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 v-show="public_key != undefined"
					 xl=3>
			<cardclaimabletrust @askaction="askaction" />
		</v-col>
		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 v-show="public_key != undefined"
					 xl=3>
			<cardsequestre @askaction="askaction" />
		</v-col>
	</v-row>
</div>
</template>


<script>
import listbalances from "@/components/list_balances.vue"
import listliquiditypools from "@/components/list_liquidity_pools.vue"
import listclaimablebalances from "@/components/list_claimable_balances.vue"
import listsellingoffers from "@/components/list_selling_offers.vue"
import cardbuying from "@/components/card_buying.vue"
import cardselling from "@/components/card_selling.vue"
import cardtransfert from "@/components/card_transfert.vue"
import cardclaimablebalances from "@/components/card_claimable_balances.vue"
import cardclaimabletrust from "@/components/card_claimable_trust.vue"
import cardsequestre from "@/components/card_sequestre.vue"
import cardbuyingnft from "@/components/card_buying_nft.vue"
import cardsellingnft from "@/components/card_selling_nft.vue"
import cardtransfertnft from "@/components/card_transfert_nft.vue"
export default {
	name: 'secondary',
	components: {
		listbalances,
		listliquiditypools,
		listclaimablebalances,
		cardbuying,
		cardselling,
		listsellingoffers,
		cardclaimabletrust,
		cardtransfert,
		cardclaimablebalances,
		cardsequestre,
		cardbuyingnft,
		cardsellingnft,
		cardtransfertnft
	},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		public_key() {
			return this.$store.getters.public_key;
		},
	},
	methods: {
		askaction(action) {
			//console.log("askaction - dashboard")
			if (action == "init") this.$emit('askaction', "init")
		},
		change_page(page) {
			this.$emit('changepage', page)
		}
	},
	watch: {}
}
</script>

<style scoped>
</style>
