<template>
<div>
	<v-list three-line
					v-if="display=='list'"
					style="height:450px; overflow-y: scroll;">
		<v-list-item-group v-model="selected"
											 active-class="pink--text">
			<template v-for="(article, index) in borders">
				<v-divider v-if="index>0"
									 :key="article.last_change"></v-divider>
				<v-list-item :key="index">
					<v-list-item-avatar>
						<v-img src="@/assets/berlcoin.svg"></v-img>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title v-html="'<b>'+parseFloat(article.field_euro).toFixed(2)+' €</b> : ' + parseFloat(article.montant).toFixed(2) + ( article.recompense > 0 ? parseFloat(article.recompense).toFixed(2) : '') " />


						<v-list-item-subtitle v-html="article.last_change" />
						<v-spacer></v-spacer>
					</v-list-item-content>

					<v-list-item-action>
						<v-btn small
									 elevation=0
									 style="float:right"
									 :color="article.id_payment =='' ? ( article.process =='cancel' ? 'red': 'warning') : 'success' ">{{((parseFloat(article.recompense)+parseFloat(article.montant)).toFixed(2) )  }}</v-btn>
					</v-list-item-action>
				</v-list-item>
			</template>
		</v-list-item-group>
	</v-list>
	<div v-if="display=='form'">
		<primarybuyform />
	</div>
	<div class="ma-3"
			 v-if="display=='detail'">


		<h3 v-if="borders[selected].process=='cancel'"
				class="red text-center white--text"
				v-html="$t('cancel_transaction')" />
		<br /> {{$t('invest6')}} : <b style=" color:red"
			 v-html="'<b>'+ (borders[selected].last_change)+' </b> '" />

		<br /> EURO : <b style=" color:red"
			 v-html="'<b>'+parseFloat(borders[selected].field_euro).toFixed(2)+' </b> '" />

		<br /> BERLCOIN : <b style=" color:red"
			 v-html="'<b>'+parseFloat(borders[selected].montant).toFixed(2) + ( borders[selected].recompense > 0 ? parseFloat(borders[selected].recompense).toFixed(2) : '') " />
		<br /> {{$t('paiement')}} : <b class="success--text"
			 v-html="'<b>'+borders[selected].id_payment+' </b> '" />
		<p v-if="borders[selected].virement=='true'">
			<br />
			<br /> {{$t("reftranfert")}} : <b style=" color:red">{{borders[selected].id_transaction}}</b>
			<br />
			<br />
			<b class="caption">{{$t('ibanlabel')}} </b>
			<br /><b style="font-size:92%"
				 class="red--text">FR76 1679 8000 0100 0085 4071 638</b>
			<br /> {{$t('codebic')}}: TRZOFR21XXX
			<br /> {{$t('titulaire')}}: <b> Fraction Real Estate </b>
			<br /><br /> <b class="caption">{{$t('bank')}} </b> <br />
			Shine 5 rue Charlot, 75003 Paris


			<br /> <br /> <i style="font-size:80%">{{$t('assist4')}}</i>
		</p>
		<hr class="mt-3" />
		<v-btn small
					 text
					 class="ma-3"
					 @click="display='list'">{{$t('back')}}
		</v-btn>
		<v-btn small
					 v-if="borders[selected].process=='pending'"
					 text
					 class="ma-3 red--text float-right"
					 @click="show_valid=true">{{$t('cancel_invest')}} </v-btn>

		<validbutton :etat_transaction="etat_transaction"
								 v-if="show_valid"
								 :error_why="error_why"
								 :progress="progress"
								 @valid="send_transaction" />
	</div>

</div>
</template>


<script>
var StellarSdk = require('stellar-sdk');
import primarybuyform from '@/components/primary_buy_form.vue'
import validbutton from '@/components/valid_button.vue'
export default {
	name: 'list_primary_offer',
	components: {
		primarybuyform,
		validbutton
	},
	props: {
		show: {
			type: String,
			default: 'list'
		}
	},
	data: () => ({
		selected: undefined,
		order: undefined,
		display: "list",
		borders: [],
		show_valid: false,
		etat_transaction: undefined,
		error_why: undefined,
		progress: false,

	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		this.init();
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		public_key() {
			return this.$store.getters.public_key;
		},
		paypal_key() {
			return this.$store.getters.paypal_key;
		},
		daily_rate() {
			return this.$store.getters.daily_rate;
		},
	},
	methods: {
		send_transaction: function (key) {

			this.progress = true;
			this.etat_transaction = "";
			try {
				var source = StellarSdk.Keypair.fromSecret(key);
				if (this.public_key == source.publicKey()) {
					this.etat_transaction = "success";
					this.progress = false;
					this.cancel("");
				} else {
					this.progress = false;
					this.etat_transaction = "Echec de la transaction";
					this.error_why = this.$t('transaction_reject_false')
				}
			} catch (error) {
				this.etat_transaction = this.$t('transaction_reject_wallet') + error.toString().replace("TypeError: ", '');
				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 144, got undefined")
					this.etat_transaction = this.$t('transaction_reject_false');
				this.progress = false;
			}
		},


		cancel() {
			//		if (window.confirm(this.$t('cancel_invest')) == true)
			{

				this.opt = [];
				this.opt["debug"] = false;
				this.opt["api"] = "cancel_berlcoin"
				this.opt["selection"] = 'berlcoin';

				this.opt["uid"] = this.borders[this.selected].id_transaction
				this.$store.dispatch('post', this.opt).then(response => {
					if (response != undefined) {
						this.init();
						this.display = 'list';
						this.show_valid = false;
					}
				})
			}
		},
		init() {
			let opt = [];
			opt["api"] = "order_berlcoin"
			opt["public_key"] = this.public_key;
			this.$store.dispatch('post', opt).then(response => {
				if (response != undefined)
					this.borders = response.data;
			})
		}
	},
	watch: {
		show() {
			if (this.show == 'new') {
				this.display = 'form';
			}
			if (this.show == 'list') {
				this.init();
				this.display = 'list';
			}
		},
		selected() {
			if (this.selected != undefined) {
				this.display = "detail";
				//		this.$emit('event', "show_form")
			}
		}
	}
}
</script>

<style scoped>
</style>
