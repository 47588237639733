<template>
<v-card class="mx-auto  "
				style="height:100%">
	<v-card-title class="white--text red darken-4"> {{$t('stoken')}}
		<v-spacer> </v-spacer>
	</v-card-title>
	<hr />
	<listbalancessequestre :operation="operation"
												 @askaction="askaction"
												 @event="event" />
</v-card>
</template>

<script>
import listbalancessequestre from "@/components/list_balances_sequestre.vue"
export default {
	name: 'card_sequestre',
	components: {
		listbalancessequestre
	},
	props: {},
	data: () => ({
		show_action: 'list',
		operation: "sequestre"
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		plus() {
			this.show_action = 'new'
		},
		askaction(action) {
			if (action == "init") this.$emit('askaction', "init")
		},
		event(event) {
			if (event == "show_form") this.show_action = 'form';
			if (event == "show_list") this.show_action = 'list';
		},
	},
	watch: {}
}
</script>
<style scoped>
</style>
