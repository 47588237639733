<template>
<div>

	<v-list two-line
					v-if="display=='list'"
					style="height:450px; overflow-y: scroll;">
		<v-list-item-group v-model="selected"
											 active-class="pink--text">
			<template v-for="(item, index) in buying">
				<v-list-item :key="item.title">
					<template v-slot:default="{ active }">
						<v-list-item-content class="py-2">
							<v-list-item-title>
								<v-chip color="primary"
												label>
									<b v-html="monetary_aspect(item.balance)" />
								</v-chip>
								<b class="pl-3"> {{ item.buying.asset_code}}</b>
							</v-list-item-title>
							<v-list-item-subtitle class="text--primary">
								by {{ item.buying.asset_type == 'native' ? 'Stellar Foundation' : account_name(item.buying.asset_issuer,false)}}
							</v-list-item-subtitle>

						</v-list-item-content>
						<v-list-item-action class="py-2">
							<v-chip label>
								<v-icon left
												small
												color="primary">
									mdi-currency-eur
								</v-icon>
								<span v-html="monetary_aspect(item.price_total)" />
							</v-chip>
							<v-list-item-action-text v-html=" monetary_aspect(item.price_unit)"></v-list-item-action-text>
						</v-list-item-action>
					</template>
				</v-list-item>
				<v-divider :key="index"></v-divider>
			</template>
		</v-list-item-group>
	</v-list>

	<div v-if="display=='form'">
		<stellarform operation="manageBuyOfferNFT"
								 css="pa-3"
								 :order="order"
								 @result="stellarform_result" />
		<v-divider></v-divider>

		<v-btn icon
					 @click="display='list';$emit('event', 'show_list')"
					 color="warning">
			<v-icon>mdi-arrow-left</v-icon>
		</v-btn>


	</div>
</div>
</template>


<script>
import stellarform from '@/components/stellar_form.vue'
export default {
	name: 'list_buying_offers_nft',
	components: {
		stellarform
	},
	props: {
		show: {
			type: String,
			default: 'list'
		}
	},
	data: () => ({
		selected: undefined,
		order: undefined,
		display: "list"

	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		buying() {
			return this.$store.getters.buying_nft('wallet')
		},
	},
	methods: {
		stellarform_result(result) {
			if (result) {
				this.display = "list";
				this.$emit('askaction', "init")
			}
		}
	},
	watch: {
		show() {
			if (this.show == 'new') {
				this.order = undefined;
				this.display = 'form';
			}
		},
		selected() {
			if (this.selected != undefined) {
				this.order = this.buying[this.selected];
				this.display = "form";
				this.$emit('event', "show_form")
			}
		}
	}
}
</script>

<style scoped>
</style>
