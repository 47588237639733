<template>
<v-card class="mx-auto  "
				style="height:100%">

	<v-card-title class="white--text red darken-4"> {{label}}
		<v-spacer> </v-spacer>
		<v-btn v-show="show_action == 'no---list'"
					 fab
					 elevation=0
					 small
					 text
					 dark
					 @click="plus"
					 color="white">
			<v-icon dark>
				mdi-plus-circle
			</v-icon>
		</v-btn>

	</v-card-title>
	<hr />
	<listbalancesnft :operation="operation"
									 @askaction="askaction"
									 @event="event" />

</v-card>
</template>


<script>
import listbalancesnft from "@/components/list_balances_nft.vue"
export default {
	name: 'card_transfert',
	components: {
		listbalancesnft
	},
	props: {
		operation: String,
		label: String,
	},
	data: () => ({
		show_action: 'list',
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		plus() {
			this.show_action = 'new'
		},
		askaction(action) {
			console.log("askaction - card transfert")
			if (action == "init") this.$emit('askaction', "init")
		},
		event(event) {
			if (event == "show_form") this.show_action = 'form';
			if (event == "show_list") this.show_action = 'list';
		},

	},
	watch: {}
}
</script>

<style scoped>
</style>
