<template>
<div>
	<v-row>

		<v-col cols=12>
			<template>
				<v-banner single-line
									elavation=0
									color="yellow lighten-5">
					<v-icon color="warning"
									@click="$emit('changepage', 'dashboard')"
									class="mr-6"
									size="24">
						mdi-home
					</v-icon>
					<v-icon color="warning"
									@click="$emit('changepage', 'primary')"
									class="mr-6"
									size="24">

						mdi-currency-eur
					</v-icon>
					<v-icon color="success"
									class="mr-6"
									size="24">
						mdi-cards
					</v-icon>

					<template v-slot:actions>
						<v-btn color="primary"
									 text>
							{{total_shop.toFixed(2)}} €
						</v-btn>
						<v-icon color="success"
										v-show="$store.getters.shops>0"
										class="mr-6"
										@click="$emit('changepage', 'shoppingcart')"
										size="24">
							mdi-shopping
						</v-icon>
					</template>
				</v-banner>
			</template>
		</v-col>

	</v-row>
	<v-row>
		<v-col v-show="public_key== undefined"
					 cols=12>
			<v-card elevation=0
							color="red darken-4"
							dark
							@click="$emit('changepage', 'keys')"
							class="mx-3 mt-3 pa-2"> {{$t('message_no_key')}}
			</v-card>
		</v-col>
	</v-row>
	<v-row v-show="public_key != undefined">



		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=4
					 v-for="(item, index) in cards"
					 :key="index">
			<template>
				<v-card class="mx-auto my-4">
					<template slot="progress">
						<v-progress-linear color="deep-purple"
															 height="10"
															 indeterminate></v-progress-linear>
					</template>

					<v-img :src="media + item.asset_code + '.png'" />



					<v-card-title>{{item.asset_code}}
						<v-spacer></v-spacer>
						<div class="grey--text ms-4 caption ">
							{{item.quantity_start}} / {{item.quantity_end}}
						</div>




					</v-card-title>

					<v-card-text>
						<v-row align="center"
									 class="mx-0">

							<v-progress-linear background-color="grey"
																 color="success "
																 :value="item.quantity_start/item.quantity_end*100"></v-progress-linear>
						</v-row>

						<div class="my-4 text-subtitle-1">
							{{item.address}}
						</div>
						<v-row no-gutters
									 align="center"
									 class="mx-0">
							<v-col cols="4"
										 class="pa-1">
								<v-btn color="deep-purple accent-4"
											 dark
											 block
											 elavation=0
											 column>
									{{item.price_start}}
								</v-btn>
							</v-col>
							<v-col cols="4"
										 class="pa-1">
								<v-btn color="deep-purple accent-4"
											 dark
											 block
											 column>
									{{item.total_yield}}%
								</v-btn>
							</v-col>
							<v-col cols="4"
										 class="pa-1">
								<v-btn color="deep-purple accent-4"
											 dark
											 block
											 column>
									{{item.reward}}
								</v-btn>
							</v-col>
							<v-col cols="4"
										 class="pa-1">
								<v-btn color="deep-purple accent-4"
											 dark
											 block
											 column>
									{{(parseFloat(item.price_end) ).toFixed(2) }}
								</v-btn>
							</v-col>
							<v-col cols="4"
										 class="pa-1">
								<v-btn color="deep-purple accent-4"
											 dark
											 block
											 column>
									{{item.liquidity}}
								</v-btn>
							</v-col>
							<v-col cols="4"
										 class="pa-1">
								<v-btn color="deep-purple accent-4"
											 dark
											 block
											 column>
									{{(parseFloat(base_croissance) + parseFloat(item.annual_yield)).toFixed(2) }}%
								</v-btn>
							</v-col>
						</v-row>

					</v-card-text>


					<v-expansion-panels flat
															accordion>
						<v-expansion-panel>
							<v-expansion-panel-header> <b>Croissance du marché Berlinois </b></v-expansion-panel-header>
							<v-expansion-panel-content class="ma-0 pa-0">
								<v-card-text class="ma-0 pa-0">

									{{$t("over10")}} 142,12% = 9.24%/an
									<br /> {{$t("over5")}} 54.92% = 9.15%/an
									<br /> {{$t("over1")}} 11.5%

									<br />
									<br /> {{$t("expected")}} {{base_croissance.toFixed(2)}}%
									<br />
									<a target="_blank"
										 href="https://www.pfandbrief.de/site/de/vdp/immobilie/finanzierung_und_markt/vdp-immobilienpreisindex.html">Der Verband deutscher Pfandbriefbanken (vdp)</a>
								</v-card-text>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel>
							<v-expansion-panel-header> <b>{{$t("staking_contact")}} </b></v-expansion-panel-header>
							<v-expansion-panel-content class="ma-0 pa-0">
								<v-card-text class="ma-0 pa-0">
									{{$t("staking_amount")}} {{item.price_start}} berlcoin<br />
									{{$t("guaranteed")}} {{item.total_yield}}% soit {{item.reward}} Berlcoin
									<br /> {{$t("liquidation")}} 28/{{item.liquidity}}
									<br /> {{$t("totalvalue")}} {{parseFloat(item.price_end).toFixed(2) }} Berlcoin
									<br /> <br /> {{$t("averageannual")}}<b class="success--text">{{(parseFloat(base_croissance) + parseFloat(item.annual_yield)).toFixed(2) }}%</b>
								</v-card-text>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>


					<v-divider class="mx-4"></v-divider>

					<v-card-actions class="py-4">
						<v-btn color="warning accent-4"
									 dark
									 v-if="item.quantity_start==0"
									 class="float-right "
									 column>
							{{$t('tolate')}}
						</v-btn>

						<v-btn-toggle v-model="button[item.asset_code]"
													v-if="item.quantity_start>0">
							<v-btn @click="minus(item.asset_code,item.asset_issuer,item.price_start,item.quantity_start)">
								<v-icon>mdi-minus</v-icon>
							</v-btn>
							<v-btn @click="plus(item.asset_code,item.asset_issuer,item.price_start,item.quantity_start)"
										 :color="button[item.asset_code] > 0 ? 'success' : ''">
								{{shopping[item.asset_code] == undefined ? 0 : shopping[item.asset_code].quantity}}
							</v-btn>
							<v-btn>
								<v-icon @click="plus(item.asset_code, item.asset_issuer,item.price_start,item.quantity_start)">mdi-plus</v-icon>
							</v-btn>
						</v-btn-toggle>
						<v-icon color="success"
										v-show="$store.getters.shops>0"
										class="mx-6"
										@click="$emit('changepage', 'shoppingcart')"
										size="24">
							mdi-shopping
						</v-icon>
						<v-spacer></v-spacer>
						<v-btn color="success accent-4"
									 dark
									 @click="$store.getters.shops>0 ? $emit('changepage', 'shoppingcart') : ''"
									 class="float-right "
									 column>
							{{(daily_rate * item.price_start).toFixed(2)}} €
						</v-btn>

					</v-card-actions>
				</v-card>
			</template>
		</v-col>
	</v-row>
</div>
</template>


<script>
export default {
	name: 'nftcard',
	components: {},
	props: {},
	data: () => ({

		cards: [],

		button: [],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		let opt = [];
		opt["debug"] = false;
		opt["api"] = "market.berlin.cards"
		opt["selection"] = 'berlcoin';
		this.$store.dispatch('post', opt).then(response => {
			if (response != undefined) this.cards = response.data.data
		})
		this.console("this.cards", this.cards);
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		shopping() {
			return this.$store.state.shopping;
		},
		daily_rate() {
			return this.$store.getters.daily_rate;
		},
		total_shop() {
			return this.$store.getters.total_shop;
		},
		base_croissance() {
			return this.$store.getters.base_croissance;
		},
		base_croissance() {
			return this.$store.getters.base_croissance;
		},
		vdp_index() {
			return this.$store.getters.vdp_index;
		},
		media() {
			return this.$store.getters.media;
		},
		public_key() {
			return this.$store.getters.public_key;
		},
	},
	methods: {
		plus(article, asset_issuer, price, max) {
			let param = {
				"article": article,
				"asset_issuer": asset_issuer,
				"price": price,
				"max": max
			}
			this.$store.dispatch('plus', param)
		},
		minus(article, asset_issuer, price, max) {
			let param = {
				"article": article,
				"asset_issuer": asset_issuer,
				"price": price,
				"max": max
			}
			this.$store.dispatch('minus', param)
		}
	},
	watch: {}
}
</script>

<style scoped>
</style>
