<template>
<div :class="css">

	<v-chip class="float-right ma-2 primary"
					v-if="order_id>0"
					dark>{{order_id}} </v-chip>
	<h4 v-html="$t('source_label')"
			v-show="show.source_label" />
	<v-icon large
					v-show="show.source_key"
					class="mt-6"
					@click="icon_qr='source_key'; dialog=true"
					style="float:right; border:1px solid gray; border-radius:2px;">
		mdi-qrcode
	</v-icon>



	<v-text-field class="my-5"
								dense
								v-show="show.source_key"
								required
								v-model="source_key"
								:label="$t('source_key')"
								:hint="account_name(source_key,false)"
								persistent-hint />


	<v-select :items="send_asset_list"
						v-show="show.send_asset_codeissuer"
						item-text="asset_code"
						item-value="asset"
						v-model="send_asset_codeissuer"
						:label="$t('send_asset_code')"></v-select>

	<v-text-field class="my-5"
								dense
								v-show="show.send_asset_code"
								v-model="send_asset_code"
								:label="$t('send_asset_code')" />


	<v-text-field class="my-5"
								dense
								v-show="show.send_asset_issuer"
								v-model="send_asset_issuer"
								:label="$t('send_asset_issuer')"
								:hint="account_name(send_asset_issuer,false)"
								persistent-hint />


	<h4 v-html="$t('destination_label')"
			v-show="show.destination_label" />
	<v-icon large
					v-show="show.destination_key"
					@click="icon_qr='destination_key'; dialog=true"
					class="mt-6"
					style="float:right; border:1px solid gray; border-radius:2px;">
		mdi-qrcode
	</v-icon>
	<v-text-field class="my-5"
								required
								dense
								v-show="show.destination_key"
								v-model="destination_key"
								:label="$t('destination_key')"
								:hint="account_name(destination_key,false)"
								persistent-hint />

	<v-select :items="receive_asset_list"
						v-show="show.receive_asset_codeissuer"
						item-text="asset_code"
						item-value="asset"
						v-model="receive_asset_codeissuer"
						:label="$t('receive_asset_code')"></v-select>



	<v-btn-toggle v-if="operation=='amm_transfert' && receive_asset_codeissuer != send_asset_codeissuer"
								v-model="garanty"
								mandatory>
		<v-btn :color="garanty == 0 ? 'success' : ''">
			<v-icon>mdi-arrow-right</v-icon>
		</v-btn>
		<v-btn :color="garanty == 1 ? 'success' : ''">
			<v-icon>mdi-arrow-left</v-icon>
		</v-btn>

	</v-btn-toggle>


	<v-text-field class="my-5"
								dense
								v-show="show.receive_asset_code"
								v-model="receive_asset_code"
								:label="$t('receive_asset_code')" />


	<v-text-field class="my-5"
								dense
								v-show="show.receive_asset_issuer"
								v-model="receive_asset_issuer"
								:label="$t('receive_asset_issuer')"
								:hint="account_name(receive_asset_issuer,false)"
								persistent-hint />

	<h4 v-html="$t('transaction_label')"
			v-show="show.transaction_label" />


	<v-text-field class="my-5"
								dense
								v-show="show.manage_name"
								v-model="manage_name"
								:label="$t('manage_name')" />
	<v-text-field class="my-5"
								dense
								v-show="show.manage_value"
								v-model="manage_value"
								:label="$t('manage_value')" />


	<v-text-field class="my-5"
								reverse
								dense
								v-show="show.price_unit"
								v-model="price_unit"
								:label="$t('price_unit')"></v-text-field>

	<div v-show="show.staking">
		{{$t('staking_label')}}
		<v-radio-group v-model="staking">
			<v-radio v-for="(item, index) in term"
							 :key="index"
							 v-show="item.staking>0"
							 :value="item.an"
							 :label="item.label+' : '+item.staking+'%'"></v-radio>
		</v-radio-group>
	</div>




	<v-btn-toggle v-if="operation == 'liquiditypool'"
								v-model="amm"
								mandatory>
		<v-btn :color="check_payment == 0 ? 'success' : ''">
			<v-icon> mdi-plus </v-icon>
		</v-btn>
		<v-btn :color="check_payment == 1 ? 'success' : ''">
			<v-icon> mdi-minus </v-icon>
		</v-btn>
		<v-btn :color="check_payment == 2 ? 'success' : ''">
			<v-icon> mdi-power</v-icon>
		</v-btn>

	</v-btn-toggle>



	<v-text-field class="my-5"
								reverse
								dense
								v-show="show.quantity"
								v-model="quantity"
								:label="$t('quantity')+ ' '+quantity_asset_code"></v-text-field>

	<v-text-field class="my-5"
								reverse
								dense
								v-show="show.receive_quantity"
								v-model="receive_quantity"
								:label="$t('quantity')+ ' '+receive_quantity_asset_code"></v-text-field>

	<v-btn-toggle v-if="operation == 'payment'"
								v-model="check_payment"
								mandatory>
		<v-btn :color="check_payment == 0 ? 'success' : ''">
			<v-icon>mdi-send</v-icon>
		</v-btn>
		<v-btn :color="check_payment == 1 ? 'success' : ''">
			<v-icon>mdi-calendar-clock</v-icon>
		</v-btn>
		<v-btn :color="check_payment == 2 ? 'success' : ''">
			<v-icon>mdi-shield-outline</v-icon>
		</v-btn>
	</v-btn-toggle>

	<v-file-input v-if="check_payment==2"
								class="my-5"
								accept="application/pdf"
								:placeholder="$t('tdcpdf')"
								prepend-icon="mdi-file-pdf-box"
								@change="readURL" />

	<v-text-field class="my-5"
								dense
								reverse
								v-show="show.day"
								v-model="day"
								:label="$t('day')"></v-text-field>

	<v-text-field class="my-5"
								reverse
								dense
								v-show="show.price_total"
								@blur="change_price_total"
								v-model="price_total"
								:label="$t('price_total')"></v-text-field>


	<p class="caption mt-5 sucess--text"
		 v-show="show.staking">
		{{$t('staking_1')}}
		<br /> <b class="red--text">{{quantity}}</b> <code>{{send_asset_code}}</code> {{$t('for')}} <b class="red--text">{{staking}} </b>ans
		<br /> {{$t('staking_2')}} : <b class="red--text">{{reward}}</b> <code>{{send_asset_code}}</code>
		<br /> {{$t('staking_3')}} : <b class="red--text">{{total}}</b> <code>{{send_asset_code}}</code>
		<br /> {{$t('staking_4')}} : <b class="success--text">{{win}}</b> <code> {{currency.asset_code}} </code>
	</p>

	<p class="caption mt-5 sucess--text"
		 v-show="operation=='liquiditypool' && amm=='0'">

		<br /> <b class="red--text">{{quantity}}</b> <code>{{send_asset_code}}</code> & <b class="red--text">{{receive_quantity}}</b> <code>{{receive_asset_code}}</code>
		<br /> <b class="red--text">1 </b> <code>{{send_asset_code}}</code> = <b class="red--text">{{( receive_quantity /quantity ).toFixed(5)   }}</b> <code>{{receive_asset_code}}</code>
		<br /> <b class="red--text">1 </b> <code>{{receive_asset_code}}</code> = <b class="red--text">{{ (quantity /receive_quantity).toFixed(5)   }}</b> <code>{{send_asset_code}}</code>
	</p>

	<p class="caption mt-5 sucess--text"
		 v-show="operation=='amm_buy'  ">
		<br /> Achat via AMM de <code>{{send_asset_code}}</code> pour <b class="red--text">{{quantity}}</b> <code>{{currency.asset_code}}</code>
	</p>

	<p class="caption mt-5 sucess--text"
		 v-show="operation=='amm_sell'  ">
		<br /> Vente via AMM de <b class="red--text">{{quantity}}</b> <code>{{send_asset_code}}</code>
	</p>

	<p class="caption mt-5 sucess--text"
		 v-show="operation=='amm_transfert' && garanty== 0 ">
		<br /> Transfert via AMM : <br />Envoi de <b class="red--text">{{quantity}}</b> <code>{{send_asset_code}}</code> et réception en <code>{{receive_asset_code}}</code>
	</p>

	<p class="caption mt-5 sucess--text"
		 v-show="operation=='amm_transfert' && garanty== 1 ">
		<br /> Transfert via AMM : <br />Envoi de <code>{{send_asset_code}}</code> et réception de <b class="red--text">{{quantity}}</b> <code>{{receive_asset_code}}</code>
	</p>

	<p class="  mt-5 sucess--text"
		 v-show="operation=='sequestre' && amount==1">
		<v-file-input class="my-5"
									accept="application/pdf"
									:placeholder="$t('tdcpdf')"
									prepend-icon="mdi-file-pdf-box"
									@change="check_contract" />
		<span v-if="compare != undefined"
					:class="compare ? 'green-text' :'red--text'"
					v-html="compare ? $t('compare') : $t('notcompare')" />

		<br />
	</p>

	<p class="  mt-5 sucess--text"
		 v-show="operation=='sequestre' && amount==1 && send_asset.asset_code.substring(0,3) == public_key.substring(public_key.length -3)">

		<br />
		<b> {{$t("sequestre_valid")}}</b>
		<br />
	</p>

	<p class=" mt-5 sucess--text"
		 v-show="operation=='sequestre' && amount==1 && send_asset.asset_code.substring(6,9) ==  public_key.substring(public_key.length -3)">
		{{$t("sequestre_reject")}}
		<br />
	</p>

	<p class=" mt-5 sucess--text"
		 v-show="operation=='sequestre' && amount==2  ">
		{{$t("sequestre_recup")}}
		<br />
	</p>

	<p class=" mt-5 sucess--text"
		 v-show="operation=='claim_claimable_balance'">
		{{$t("recup_claim")}}
		<br />
	</p>

	<p class=" mt-5 sucess--text"
		 v-show="operation=='validate_sponsor'">
		{{$t("validate_sponsor")}}
		<br />
	</p>

	<p class="caption mt-5 sucess--text"
		 v-show="(this.operation == 'manageBuyOfferNFT')">
		<br />
		Achat de <b class="red--text">{{quantity}}</b> carte NFT <code>{{receive_asset_code}}</code> pour <b class="red--text">{{price_unit}}</b> <code>{{send_asset_code}}</code> par NFT<br />
		<br />
	</p>




	<p class="caption mt-5 sucess--text"
		 v-show="(this.operation == 'manageBuyOfferNFT_XLM')">
		<br />
		Achat de cartes NFT
	<div v-for="(article, index) in shopping">
		<span v-if="article.quantity>0">
			{{article.quantity}} {{index}} = {{Number(article.price * daily_rate * cours_XLM_inverse).toFixed(2) }} Lumens XLM</span>
	</div>
	<br />
	</p>


	<p class="caption mt-5 sucess--text"
		 v-show="(this.operation == 'manageBuyOffer')">
		<br />
		Achat de <b class="red--text">{{quantity}}</b> <code>{{receive_asset_code}}</code> au prix unitaire de <b class="red--text">{{price_unit}}</b> <code>{{send_asset_code}}</code> <br />
		pour un total de <b class="red--text">{{price_total}}</b> <code>{{send_asset_code}}</code>
		<br />
	</p>


	<p class="caption mt-5 sucess--text"
		 v-show="(this.operation == 'manageSellOffer')">
		<br />
		Vente de <b class="red--text">{{quantity}}</b> <code>{{send_asset_code}}</code> au prix unitaire de <b class="red--text">{{price_unit}}</b> <code>{{receive_asset_code}}</code> <br />
		pour un total de <b class="red--text">{{price_total}}</b> <code>{{receive_asset_code}}</code>
		<br />
	</p>

	<p class="caption mt-5 sucess--text"
		 v-show="(this.operation == 'manageBuyOffer_XLM')">

		Achat de <b class="red--text">{{quantity}}</b> <code>{{receive_asset_code}}</code> au prix unitaire de <b class="red--text">{{price_unit}}</b> <code>{{send_asset_code}}</code> <br />
		pour un total de <b class="red--text">{{price_total}}</b> <code>{{send_asset_code}}</code>
		<br />
	</p>



	<validbutton :etat_transaction="etat_transaction"
							 :error_why="error_why"
							 :progress="progress"
							 @valid="send_transaction" />


	<v-dialog transition="dialog-bottom-transition"
						v-model="dialog"
						max-width="600">
		<v-card>
			<v-toolbar color="primary"
								 dark> {{$t('scanqr')}}</v-toolbar>
			<v-card-text>
				<p class="error">{{ error }}</p>
				<qrcode-stream @decode="onDecode"
											 @init="onInit" />
			</v-card-text>
			<v-card-actions class="justify-end">
				<v-btn icon
							 @click="dialog= false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</div>
</template>


<script>
var StellarSdk = require('stellar-sdk');
import validbutton from '@/components/valid_button.vue'
import {
	QrcodeStream,
	QrcodeDropZone,
	QrcodeCapture
} from 'vue-qrcode-reader'
import BigNumber from 'bignumber.js'

export default {
	name: 'stellar_form',
	components: {
		QrcodeStream,
		QrcodeDropZone,
		QrcodeCapture,
		validbutton
	},
	props: {
		css: "",
		send_asset: undefined,
		receive_asset: undefined,
		receive_key: undefined,
		operation: undefined,
		amount: undefined,
		memo: undefined,
		order: undefined,
		price: undefined,
		data_name: undefined,
		data_value: undefined,
		term: [],
	},
	data: () => ({
		send_asset_issuer: '',
		send_asset_code: '',
		receive_asset_issuer: '',
		receive_asset_code: '',
		receive_asset_codeissuer: '',
		send_asset_codeissuer: '',
		source_key: "",
		destination_key: "",
		price_unit: 0,
		price_total: 0,
		manage_name: undefined,
		manage_value: undefined,
		quantity: undefined,
		receive_quantity: undefined,
		send_quantity: 0,
		dialog: false,
		error: '',
		etat_transaction: undefined,
		error_why: undefined,
		progress: false,
		secret_key: "",
		icon_qr: undefined,
		show: [],
		day: undefined,
		order_id: 0,
		send_asset_list: undefined,
		receive_asset_list: undefined,
		staking: 5,

		reward: 0,
		total: 0,
		totaleuro: 0,
		win: 0,
		garanty: 0,
		contrat: 5,
		check_payment: false,
		compare: undefined,
		quantity_asset_code: "",
		receive_quantity_asset_code: "",
		amm: 0,
		hash: undefined,
		shopping_bag: [],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		this.init();
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		public_key() {
			return this.$store.getters.public_key;
		},
		assets() {
			return this.$store.getters.assets;
		},
		currency() {
			return this.$store.getters.currency;
		},
		master_asset() {
			return this.$store.getters.master_asset;
		},
		daily_rate() {
			return this.$store.getters.daily_rate;
		},
		balances() {
			return this.$store.getters.balances("wallet");
		},
		balancesnft() {
			return this.$store.getters.balances("nft_stock");
		},
		shopping() {
			return this.$store.state.shopping;
		},
		cours_XLM_inverse() {
			return this.$store.getters.cours_XLM_inverse;
		},
	},
	methods: {
		init() {

			this.send_asset_issuer = '';
			this.send_asset_code = '';
			this.receive_asset_issuer = '';
			this.receive_asset_code = '';

			this.source_key = "";
			this.destination_key = "";
			this.price_unit = 0;
			this.price_total = 0;
			this.quantity = undefined;
			this.receive_quantity = undefined;
			this.send_quantity = undefined;
			this.dialog = false;
			this.error = '';
			this.etat_transaction = undefined;
			this.error_why = undefined;
			this.progress = false;
			this.secret_key = "";
			this.icon_qr = undefined;
			this.show = [];
			this.day = undefined;
			this.order_id = "0";

			this.garanty = 0;
			this.send_asset_list = Object.values(this.assets);
			this.receive_asset_list = Object.values(this.assets);
			this.source_key = this.public_key;

			this.show.send_asset_issuer = false;
			this.show.send_asset_code = false;
			this.show.receive_asset_issuer = false;
			this.show.receive_asset_code = false;
			this.show.source_key = false;
			this.show.destination_key = false;
			this.show.price_unit = false;
			this.show.price_total = false;
			this.show.receive_quantity = false;
			this.show.quantity = false;
			this.show.send_quantity = false;
			this.show.source_label = false;
			this.show.destination_label = false;
			this.show.transaction_label = false;
			this.show.day = false;
			this.show.receive_asset_codeissuer = false;
			this.show.send_asset_codeissuer = false;
			this.show.manage_name = false;
			this.show.manage_value = false;
			this.show.staking = false;
			if (true != true) {
				this.show.send_asset_issuer = true;
				this.show.send_asset_code = true;
				this.show.receive_asset_issuer = true;
				this.show.receive_asset_code = true;
				this.show.source_key = true;
				this.show.destination_key = true;
				this.show.price_unit = true;
				this.show.price_total = true;
				this.show.receive_quantity = true;
				this.show.quantity = true;
				this.show.send_quantity = true;
				this.show.source_label = true;
				this.show.destination_label = true;
				this.show.transaction_label = true;
				this.show.day = true;
				this.show.receive_asset_codeissuer = true;
				this.show.send_asset_codeissuer = true;
				this.show.manage_name = true;
				this.show.manage_value = true;
			}

			if (this.send_asset != undefined) {
				this.send_asset_issuer = this.send_asset.asset_issuer
				this.send_asset_code = this.send_asset.asset_code
			}
			if (this.receive_asset != undefined) {
				this.receive_asset_issuer = this.receive_asset.asset_issuer
				this.receive_asset_code = this.receive_asset.asset_code
			}

			this.destination_key = this.receive_key;

			if (this.amount != undefined) this.quantity = Number(this.amount).toFixed(7);
			if (this.price != undefined) this.price_unit = Number(this.price).toFixed(7);

			if (this.data_name != undefined) this.manage_name = this.data_name
			if (this.data_value != undefined) this.manage_value = this.data_value;




			if (this.operation == "manageBuyOfferNFT") {
				this.quantity_asset_code = "NFT Card"


				this.send_asset_codeissuer = this.master_asset.asset_code + ':' + this.master_asset.asset_issuer;

				this.receive_asset_list = []
				this.receive_asset_list = this.balancesnft.filter(tk => tk.asset_code != this.master_asset.asset_code && tk.asset_type != 'native' && tk.asset_code != this.currency.asset_code);

				if (this.order != undefined) {
					this.send_asset_codeissuer = this.order.selling.asset_code + ':' + this.order.selling.asset_issuer;
					this.receive_asset_codeissuer = this.order.buying.asset_code + ':' + this.order.buying.asset_issuer;
					this.quantity = Number(this.order.balance).toFixed(7);
					this.price_unit = Number(this.order.price_unit).toFixed(7);
					this.order_id = this.order.id;
				}

				this.show.price_unit = true;
				this.show.quantity = true;
				this.show.receive_asset_codeissuer = true;
				this.show.price_total = true;
			}





			if (this.operation == "manageSellOfferNFT") {
				this.quantity_asset_code = "NFT Card"
				this.receive_asset_codeissuer = this.master_asset.asset_code + ':' + this.master_asset.asset_issuer;

				this.send_asset_list = []
				this.send_asset_list = this.balances.filter(tk => tk.asset_code != this.master_asset.asset_code && tk.balance < 0.000009 && tk.asset_type != 'native' && tk.asset_code != this.currency.asset_code);

				if (this.order != undefined) {
					this.send_asset_codeissuer = this.order.selling.asset_code + ':' + this.order.selling.asset_issuer;
					this.receive_asset_codeissuer = this.order.buying.asset_code + ':' + this.order.buying.asset_issuer;
					this.quantity = Number(this.order.balance).toFixed(7);
					this.price_unit = Number(this.order.price_unit).toFixed(7);
					this.order_id = this.order.id;
				}
				this.show.price_unit = true;
				this.show.price_total = true;
				this.show.quantity = true;
				this.show.send_asset_codeissuer = true;
			}

			if (this.operation == "staking") {
				this.show.staking = true;
				this.show.quantity = true;
			}

			//		console.log(this.operation)
			if (this.operation == "manageBuyOffer") {
				this.send_asset_list.push(this.currency)
				this.send_asset_codeissuer = this.currency.asset_code + ':' + this.currency.asset_issuer;

				if (this.receive_asset_code != undefined) this.receive_asset_codeissuer = this.receive_asset_code + ':' + this.receive_asset_issuer;
				//		if (this.send_asset_codeissuer != undefined) this.send_asset_codeissuer = this.send_asset_code + ':' + this.send_asset_issuer;

				if (this.order != undefined) {
					this.send_asset_codeissuer = this.order.selling.asset_code + ':' + this.order.selling.asset_issuer;
					this.receive_asset_codeissuer = this.order.buying.asset_code + ':' + this.order.buying.asset_issuer;
					this.quantity = Number(this.order.balance).toFixed(7);
					this.price_unit = Number(this.order.price_unit).toFixed(7);
					this.order_id = this.order.id;
				}
				this.show.price_unit = true;
				this.show.price_total = true;
				this.show.quantity = true;
				this.show.receive_asset_codeissuer = true;
				this.quantity_asset_code = this.receive_asset.asset_code
			}

			if (this.operation == "manageSellOffer") {
				this.receive_asset_list.push(this.currency)
				this.receive_asset_codeissuer = this.currency.asset_code + ':' + this.currency.asset_issuer;

				//		if (this.receive_asset_code != undefined) this.receive_asset_codeissuer = this.receive_asset_code + ':' + this.receive_asset_issuer;
				if (this.send_asset_codeissuer != undefined) this.send_asset_codeissuer = this.send_asset_code + ':' + this.send_asset_issuer;

				if (this.order != undefined) {
					this.send_asset_codeissuer = this.order.selling.asset_code + ':' + this.order.selling.asset_issuer;
					this.receive_asset_codeissuer = this.order.buying.asset_code + ':' + this.order.buying.asset_issuer;
					this.quantity = Number(this.order.balance).toFixed(7);
					this.price_unit = Number(this.order.price_unit).toFixed(7);
					this.order_id = this.order.id;
				}
				this.show.price_unit = true;
				this.show.price_total = true;
				this.show.quantity = true;
				this.show.send_asset_codeissuer = true;
				this.quantity_asset_code = this.send_asset.asset_code
			}

			if (this.operation == "manageBuyOffer_XLM") {

				if (this.order != undefined) {
					this.send_asset_codeissuer = this.order.selling.asset_code + ':' + this.order.selling.asset_issuer;
					this.receive_asset_codeissuer = this.order.buying.asset_code + ':' + this.order.buying.asset_issuer;
					this.quantity = Number(this.order.balance).toFixed(7);
					this.price_unit = Number(this.order.price_unit).toFixed(7);
					this.order_id = this.order.id;
				}


			}

			/*
						if (this.operation == "manageSellOfferXLM") {
							this.receive_asset_list.push(this.currency)
							this.receive_asset_codeissuer = this.currency.asset_code + ':' + this.currency.asset_issuer;
							if (this.order != undefined) {
								this.send_asset_codeissuer = this.order.selling.asset_code + ':' + this.order.selling.asset_issuer;
								this.receive_asset_codeissuer = this.order.buying.asset_code + ':' + this.order.buying.asset_issuer;
								this.quantity = Number(this.order.balance).toFixed(7);
								this.price_unit = Number(this.order.price_unit).toFixed(7);
								this.order_id = this.order.id;
							}
							this.show.price_unit = true;
							this.show.price_total = true;
							this.show.quantity = true;

						}

			*/


			if (this.operation == "validate_kyc") {
				this.destination_key = this.$store.getters.account_key("distributor")
			}

			if (this.operation == "claim_claimable_balance") {
				if (this.order != undefined) {
					this.receive_asset_codeissuer = this.order.asset_code + ':' + this.order.asset_issuer;
					this.order_id = this.order.id;
				}

			}



			if (this.operation == "payment_nft") {
				this.show.destination_key = true;
				this.show.quantity = true;
				this.quantity_asset_code = this.send_asset.asset_code
				this.check_payment = 0;
			}

			if (this.operation == "payment") {
				this.show.destination_key = true;
				this.show.quantity = true;
				this.quantity_asset_code = this.send_asset.asset_code
				this.check_payment = 0;
			}

			if (this.operation == "payment_term") {
				this.show.destination_key = true;
				this.show.quantity = true;
				this.quantity_asset_code = this.send_asset.asset_code
				this.check_payment = 1;
			}



			if (this.operation == "payment_trust") {
				this.show.destination_key = true;
				this.show.quantity = true;
				this.quantity_asset_code = this.send_asset.asset_code
				this.check_payment = 2;
			}

			if (this.operation == "validate_sponsor") {
				this.show.destination_key = true;

			}

			if (this.operation == "liquiditypool") {

				this.send_asset_codeissuer = this.order.reserves[0].asset_code + ':' + this.order.reserves[0].asset_issuer; // this.order.asset_code + ':' + this.order.asset_issuer;
				this.receive_asset_codeissuer = this.order.reserves[1].asset_code + ':' + this.order.reserves[1].asset_issuer;

				this.show.receive_quantity = true;
				this.show.quantity = true;
				this.quantity_asset_code = this.order.reserves[0].asset_code
				this.receive_quantity_asset_code = this.order.reserves[1].asset_code
			}



			if (this.operation == "amm_buy") {
				this.show.quantity = true;
				this.quantity_asset_code = this.currency.asset_code
			}

			if (this.operation == "amm_sell") {
				this.show.quantity = true;
				this.quantity_asset_code = this.send_asset.asset_code
			}

			if (this.operation == "amm_transfert") {
				this.send_asset_codeissuer = this.send_asset.asset_code + ':' + this.send_asset.asset_issuer; // this.order.asset_code + ':' + this.order.asset_issuer;
				this.receive_asset_codeissuer = this.send_asset_codeissuer;
				this.show.send_asset_codeissuer = false;
				this.show.receive_asset_codeissuer = true;
				this.show.quantity = true;
				this.show.destination_key = true;
				this.quantity_asset_code = this.send_asset.asset_code
				this.receive_asset_list.push(this.currency)


				this.receive_asset_list.forEach((item, i) => {
					if (item.asset_code == this.send_asset_code && item.asset_issuer == this.send_asset_issuer) this.receive_asset_list.splice(i, 1)
				});


			}

			// --- Validation du sequestre
			if (this.operation == 'sequestre' && this.amount == 1 && this.send_asset.asset_code.substring(0, 3) == this.public_key.substring(this.public_key.length - 3)) {
				//		console.log(this.send_asset)
				this.destination_key = this.send_asset.destination
				//		this.show.destination_key = true;
			}


			if (this.operation == 'sequestre' && this.amount == 1) {
				//		console.log(this.send_asset)
				this.destination_key = this.send_asset.destination
				//		this.show.destination_key = true;
			}


			this.price_total = (Number(this.price_unit) * Number(this.quantity)).toFixed(7);


			this.shopping_bag = Object.values(this.shopping)
			//		this.shopping_bag.forEach((item, i) => {				console.log(i, item)			})

		},
		onDecode(result) {
			if (this.icon_qr == "source_key") this.source_key = result
			if (this.icon_qr == "destination_key") this.destination_key = result
			this.dialog = false
		},

		async onInit(promise) {
			try {
				await promise
			} catch (error) {
				if (error.name === 'NotAllowedError') {
					this.error = "ERROR: Pas de permission pour la caméra"
				} else if (error.name === 'NotFoundError') {
					this.error = "ERROR: Pas de caméra"
				} else if (error.name === 'NotSupportedError') {
					this.error = "ERROR: secure context required (HTTPS, localhost)"
				} else if (error.name === 'NotReadableError') {
					this.error = "ERROR: La caméra semble déjà en cours d'utilisation"
				} else if (error.name === 'OverconstrainedError') {
					this.error = "ERROR: installed cameras are not suitable"
				} else if (error.name === 'StreamApiNotSupportedError') {
					this.error = "ERROR: Stream API is not supported in this browser"
				} else if (error.name === 'InsecureContextError') {
					this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
				} else {
					this.error = `ERROR: Camera error (${error.name})`;
				}
			}

		},
		readURL(file) {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (event) => {
				this.hash = this.crc32(event.target.result)

				this.hash = ("HHHHHHHH" + this.hash);
				this.hash = this.hash.substring(this.hash.length - 8)

			}
		},
		check_contract(file) {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (event) => {
				this.hash = this.crc32(event.target.result)
				this.hash = ("HHHHHHHH" + this.hash);
				this.hash = this.hash.substring(this.hash.length - 8)

				this.compare = undefined;
				this.balances.find(token => {
					if (token.asset_code == this.send_asset.asset_code) {
						if (token.memo != undefined) {
							if (token.memo.substring(0, 8) == this.hash) this.compare = true;
							else this.compare = false;
						} else this.compare = false;
					}
				})
			}
		},


		staking_update() {
			let taux = 1;
			for (let i = 0; i < this.term.length; i++)
				if (this.staking == this.term[i].an) taux = this.term[i].staking;

			this.reward = (Number(this.quantity) * taux / 100).toFixed(2);
			this.total = (Number(this.reward) + Number(this.quantity)).toFixed(2);
			this.totaleuro = (Number(this.total) * Number(this.price)).toFixed(2);
			this.win = (Number(this.reward) * Number(this.daily_rate)).toFixed(2);

		},
		pool_update() {
			this.receive_quantity = Number(this.quantity * (this.order.reserves[1].amount / this.order.reserves[0].amount)).toFixed(5)
		},
		orderAssets: function (A, B) {
			let inverse = undefined;
			(StellarSdk.Asset.compare(A, B) <= 0) ? inverse = false: inverse = true;


			return (StellarSdk.Asset.compare(A, B) <= 0) ? [A, B] : [B, A];
		},
		send_transaction: function (key) {

			this.progress = true;
			this.etat_transaction = "";

			let uneminute = 60;
			let unjour = 24 * 60 * 60;
			let duree = undefined;
			let bCanClaim = undefined;
			let terme = undefined;
			let memo2 = undefined;
			let memo3 = undefined;
			let memo4 = undefined;
			let operations2 = [];
			let operations3 = [];
			let operations4 = [];
			let params2 = undefined;
			let params3 = undefined;
			let params4 = undefined;

			try {
				let check = undefined;

				let memo = "Berlcoin.market";
				if (this.memo != undefined) memo = this.memo.substring(0, 28).trim();

				let send_asset = undefined;
				let receive_asset = undefined;
				let currency_asset = new StellarSdk.Asset(this.currency.asset_code, this.currency.asset_issuer);

				if (this.send_asset_code != "" && this.send_asset_issuer != '' && this.send_asset_issuer != 'native') send_asset = new StellarSdk.Asset(this.send_asset_code, this.send_asset_issuer);
				if (this.receive_asset_code != "" && this.receive_asset_issuer != '' && this.receive_asset_issuer != 'native') receive_asset = new StellarSdk.Asset(this.receive_asset_code, this.receive_asset_issuer);

				if (this.send_asset_issuer == 'native') send_asset = new StellarSdk.Asset.native();
				if (this.receive_asset_issuer == 'native') receive_asset = new StellarSdk.Asset.native();

				let origine_keys = StellarSdk.Keypair.fromSecret(key);
				let destination_keys = undefined;

				if (this.destination_key != undefined) destination_keys = StellarSdk.Keypair.fromPublicKey(this.destination_key);
				//			console.log(this.destination_key)

				let operations = [];


				// ------------------------------------------------------------------------------------------------------------------- STAKING

				if (this.operation == "staking") {

					let claimkey = this.$store.getters.account_key("claim");

					terme = Math.ceil((Date.now() / 1000)); // .now() is in ms
					bCanClaim = StellarSdk.Claimant.predicateNot(StellarSdk.Claimant.predicateBeforeAbsoluteTime(terme.toString()));


					operations.push(StellarSdk.Operation.createClaimableBalance({
						claimants: [new StellarSdk.Claimant(claimkey, bCanClaim)],
						asset: send_asset,
						amount: this.quantity,
					}))

					memo = (parseInt(this.staking) * 12).toString() + "TC" + this.send_asset_code;
				}

				// ------------------------------------------------------------------------------------------------------------------- SPONSORING

				if (this.operation == "validate_sponsor") {
					operations.push(
						StellarSdk.Operation.manageData({
							name: "sponsor",
							value: this.destination_key,
						}))
				}

				// ------------------------------------------------------------------------------------------------------------------- RETOUR D'INVESTISSEMENT

				if ((this.operation == "createClaimableBalance") || (this.operation == "divest")) {

					duree = unjour * Number(this.day);
					if (this.operation == "divest") duree = uneminute;
					terme = Math.ceil((Date.now() / 1000) + duree);
					bCanClaim = StellarSdk.Claimant.predicateNot(StellarSdk.Claimant.predicateBeforeAbsoluteTime(terme.toString()));

					operations.push(
						StellarSdk.Operation.createClaimableBalance({
							claimants: [new StellarSdk.Claimant(destination_keys.publicKey(), bCanClaim)],
							asset: send_asset,
							amount: this.quantity,
						})
					)
				}

				// ------------------------------------------------------------------------------------------------------------------- VALIDATION KYC

				if (this.operation == "validate_kyc") {


					let i = 0;
					//		console.log(this.manage_value)
					for (i = 0; i < 10; i++)
						if (this.manage_value.substr(i * 64, 64) != "") operations.push(
							StellarSdk.Operation.manageData({
								name: 'kyc_' + i,
								value: this.manage_value.substr(i * 64, 64),
							}))

					operations.push(
						StellarSdk.Operation.payment({
							destination: destination_keys.publicKey(),
							asset: StellarSdk.Asset.native(),
							amount: "1",
						})
					)
				}


				// ------------------------------------------------------------------------------------------------------------------- TRUST POUR OFFRE D'ACHAT OU DE VENTE

				if (this.operation == "manageBuyOfferNFT") {
					check = this.balances.find(token => {
						if (token.asset_code == this.receive_asset_code && token.asset_issuer == this.receive_asset_issuer) return token;
					})
					if (check == undefined && this.receive_asset_code != "XLM")
						operations.push(
							StellarSdk.Operation.changeTrust({
								asset: receive_asset
							})
						)
				}

				if (this.operation == "manageBuyOfferNFT_XLM") {
					this.shopping_bag.forEach((item, i) => {
						check = undefined;
						check = this.balances.find(token => {
							if (item.asset_code == token.asset_code && token.asset_issuer == item.asset_issuer) return token;
						})
						if (check == undefined)
							operations.push(
								StellarSdk.Operation.changeTrust({
									asset: new StellarSdk.Asset(item.asset_code, item.asset_issuer)
								})
							)

					})
				}



				if ((this.operation == "manageBuyOffer") || (this.operation == "amm_buy") || (this.operation == "manageSellOffer") || (this.operation == "claim_claimable_balance")) {


					if ((this.send_asset_code != "")) {
						check = this.balances.find(token => {
							if (token.asset_code == this.send_asset_code && token.asset_issuer == this.send_asset_issuer) return token;
						})
						if (check == undefined && this.send_asset_code != "XLM")
							operations.push(
								StellarSdk.Operation.changeTrust({
									asset: send_asset
								})
							)
					}

					if ((this.receive_asset_code != "")) {
						check = this.balances.find(token => {
							if (token.asset_code == this.receive_asset_code && token.asset_issuer == this.receive_asset_issuer) return token;
						})
						if (check == undefined && this.receive_asset_code != "XLM")
							operations.push(
								StellarSdk.Operation.changeTrust({
									asset: receive_asset
								})
							)
					}

					check = undefined;
					check = this.balances.find(token => {
						if (token.asset_code == this.currency.asset_code && token.asset_issuer == this.currency.asset_issuer) return token;
					})

					if (check == undefined && this.currency.asset_code != "XLM")
						operations.push(
							StellarSdk.Operation.changeTrust({
								asset: currency_asset
							})
						)
				}
				// ------------------------------------------------------------------------------------------------------------------- OFFRE D'ACHAT

				if ((this.operation == "manageBuyOffer") || (this.operation == "manageBuyOffer_XLM"))
					operations.push(
						StellarSdk.Operation.manageBuyOffer({
							"source": origine_keys.publicKey(),
							"selling": send_asset,
							"buying": receive_asset,
							"buyAmount": Number(this.quantity).toFixed(7),
							"price": Number(this.price_unit).toFixed(7),
							"offerId": this.order_id
						})
					)
				// ------------------------------------------------------------------------------------------------------------------- OFFRE D'ACHAT NFT

				if ((this.operation == "manageBuyOfferNFT"))
					operations.push(
						StellarSdk.Operation.manageBuyOffer({
							"source": origine_keys.publicKey(),
							"selling": send_asset,
							"buying": receive_asset,
							"buyAmount": Number(this.quantity / 10000000).toFixed(7),
							"price": Number(this.price_unit * 10000000).toFixed(7),
							"offerId": this.order_id
						})
					)

				// ------------------------------------------------------------------------------------------------------------------- OFFRE D'ACHAT NFT en XLM

				if ((this.operation == "manageBuyOfferNFT_XLM"))
					this.shopping_bag.forEach((item, i) => {
						if (item.quantity > 0) {

							//		console.log("n", (Number(item.price) * Number(this.daily_rate) * Number(this.cours_XLM_inverse) * 10000000).toFixed(7))
							//		console.log("buyAmount", Number(item.quantity / 10000000).toFixed(7))
							operations.push(
								StellarSdk.Operation.manageBuyOffer({
									"source": origine_keys.publicKey(),
									"selling": StellarSdk.Asset.native(),
									"buying": new StellarSdk.Asset(item.asset_code, item.asset_issuer),
									"buyAmount": Number(item.quantity / 10000000).toFixed(7),
									"price": {
										"n": (Number(item.price) * Number(this.daily_rate) * Number(this.cours_XLM_inverse) * 10000000).toFixed(7),
										"d": 1
									},
									"offerId": this.order_id
								})
							)
						}

					})

				// ------------------------------------------------------------------------------------------------------------------- OFFRE DE VENTE

				if ((this.operation == "manageSellOffer")) {
					operations.push(
						StellarSdk.Operation.manageSellOffer({
							"source": origine_keys.publicKey(),
							"selling": send_asset,
							"buying": receive_asset,
							"amount": Number(this.quantity).toFixed(7),
							"price": Number(this.price_unit).toFixed(7),
							"offerId": this.order_id
						})
					)
				}

				// ------------------------------------------------------------------------------------------------------------------- PAIEMENT DIRECT

				if ((this.operation == "payment" && this.check_payment == 0))
					operations.push(
						StellarSdk.Operation.payment({
							destination: destination_keys.publicKey(),
							asset: send_asset,
							amount: this.quantity,
						})
					)

				// ------------------------------------------------------------------------------------------------------------------- PAIEMENT NFT

				if (this.operation == "payment_nft")
					operations.push(
						StellarSdk.Operation.payment({
							destination: destination_keys.publicKey(),
							asset: send_asset,
							amount: (this.quantity / 10000000).toFixed(7),
						})
					)
				// ------------------------------------------------------------------------------------------------------------------- VALIDER ou REFUSER SEQUESTRE

				if (this.operation == 'sequestre' && this.amount == 1) {
					operations.push(
						StellarSdk.Operation.payment({
							destination: destination_keys.publicKey(),
							asset: send_asset,
							amount: "1",
						})
					)
					operations.push(
						StellarSdk.Operation.changeTrust({
							asset: send_asset,
							limit: "0",
						})
					)
				}

				// ------------------------------------------------------------------------------------------------------------------- RECUPERATION SEQUESTRE

				if (this.operation == 'sequestre' && this.amount == 2) {
					//console.log(this.$store.getters.key("sequestre"))
					bCanClaim = StellarSdk.Claimant.predicateNot(StellarSdk.Claimant.predicateBeforeAbsoluteTime(Math.ceil(Date.now() / 1000).toString()));

					operations.push(
						StellarSdk.Operation.createClaimableBalance({
							claimants: [new StellarSdk.Claimant(this.$store.getters.key("sequestre"), bCanClaim)],
							asset: send_asset,
							amount: "2",
						})
					)

					operations.push(
						StellarSdk.Operation.payment({
							destination: this.$store.getters.key("sequestre"),
							asset: StellarSdk.Asset.native(),
							amount: "1",
						})
					)

					operations.push(
						StellarSdk.Operation.changeTrust({
							asset: send_asset,
							limit: "0",
						})
					)

					check = undefined;
					if ((this.send_asset.destination_asset_code != "")) {
						check = this.balances.find(token => {
							if (token.asset_code == this.send_asset.destination_asset_code && token.asset_issuer == this.send_asset.destination_asset_issuer) return token;
						})
						if (check == undefined && this.send_asset.destination_asset_code != "XLM")
							operations.push(
								StellarSdk.Operation.changeTrust({
									asset: new StellarSdk.Asset(this.send_asset.destination_asset_code, this.send_asset.destination_asset_issuer)
								})
							)
					}

				}
				// ------------------------------------------------------------------------------------------------------------------- PAIEMENT A TERME ECHU

				if ((this.operation == "payment" && this.check_payment == 1)) {
					let nombre_jour = this.day.toString();
					duree = unjour * nombre_jour;
					terme = Math.ceil((Date.now() / 1000) + duree); // .now() is in ms
					bCanClaim = StellarSdk.Claimant.predicateNot(StellarSdk.Claimant.predicateBeforeAbsoluteTime(terme.toString()));
					operations.push(
						StellarSdk.Operation.createClaimableBalance({
							claimants: [new StellarSdk.Claimant(destination_keys.publicKey(), bCanClaim)],
							asset: send_asset,
							amount: this.quantity,
						}),
					)
				}

				// ------------------------------------------------------------------------------------------------------------------- PAIEMENT AVEC TIERS DE CONFIANCE

				if ((this.operation == "payment" && this.check_payment == 2)) {


					let uid = (Date.now().toString(36) + Math.random().toString(36)).toLowerCase();
					if (this.hash != undefined) uid = this.hash;
					uid = ('AAAAA' + uid)
					uid = uid.substring(uid.length - 8);
					//console.log(uid)
					var destination_key_part1 = this.destination_key.substring(0, 20);
					var destination_key_part2 = this.destination_key.substring(18, 38);
					var destination_key_part3 = this.destination_key.substring(36, 58);

					let trust_key = this.$store.getters.account_key("trust");

					terme = Math.ceil((Date.now() / 1000)); // .now() is in ms
					bCanClaim = StellarSdk.Claimant.predicateNot(StellarSdk.Claimant.predicateBeforeAbsoluteTime(terme.toString()));

					operations.push(
						StellarSdk.Operation.createClaimableBalance({
							claimants: [new StellarSdk.Claimant(trust_key, bCanClaim)],
							asset: send_asset,
							amount: this.quantity,
						})
					)
					memo = (uid + this.send_asset_code + ':' + this.quantity).substring(0, 28);
					memo2 = uid + destination_key_part1;
					memo3 = uid + destination_key_part2;
					memo4 = uid + destination_key_part3;



					operations2 = [];
					operations2.push(
						StellarSdk.Operation.createClaimableBalance({
							claimants: [new StellarSdk.Claimant(trust_key, bCanClaim)],
							asset: StellarSdk.Asset.native(),
							amount: "0.32",
						})
					)

					operations3 = [];
					operations3.push(
						StellarSdk.Operation.createClaimableBalance({
							claimants: [new StellarSdk.Claimant(trust_key, bCanClaim)],
							asset: StellarSdk.Asset.native(),
							amount: "0.33",
						})
					)

					operations4 = [];
					operations4.push(
						StellarSdk.Operation.createClaimableBalance({
							claimants: [new StellarSdk.Claimant(trust_key, bCanClaim)],
							asset: StellarSdk.Asset.native(),
							amount: "0.35",
						})
					)

					params4 = {
						"source": origine_keys,
						"operations": operations4,
						"memo": memo4
					}

					params3 = {
						"source": origine_keys,
						"operations": operations3,
						"memo": memo3,
						"chain": params4
					}

					params2 = {
						"source": origine_keys,
						"operations": operations2,
						"memo": memo2,
						"chain": params3
					}


					//		console.log(params2)
					//		console.log(params3)
					//		console.log(params4)

				}

				// ------------------------------------------------------------------------------------------------------------------- LIGNE DE CONFIANCE


				if (this.operation == "changeTrust")
					operations.push(
						StellarSdk.Operation.changeTrust({
							asset: send_asset,
							limit: "0",
						})
					)

				if (this.operation == "liquidypool_addtrustline") {

					let asset_poll_a;
					let asset_poll_b;

					if (this.order.reserves[0].asset_code == "XLM") {
						asset_poll_a = new StellarSdk.Asset.native();
					} else {
						asset_poll_a = new StellarSdk.Asset(this.order.reserves[0].asset_code, this.order.reserves[0].asset_issuer);
					}


					if (this.order.reserves[1].asset_code == "XLM") {
						asset_poll_b = new StellarSdk.Asset.native();
					} else {
						asset_poll_b = new StellarSdk.Asset(this.order.reserves[1].asset_code, this.order.reserves[1].asset_issuer);
					}

					const [A, B] = this.orderAssets(...[
						asset_poll_a, asset_poll_b
					]);

					const poolShareAsset = new StellarSdk.LiquidityPoolAsset(A, B, StellarSdk.LiquidityPoolFeeV18);

					operations.push(
						StellarSdk.Operation.changeTrust({
							asset: poolShareAsset,
							limit: "922337203685",
						})
					)
				}

				// ------------------------------------------------------------------------------------------------------------------- LIQUIDITY POOL

				if (this.operation == "liquiditypool") {
					const poolId = this.order.id;

					if (this.amm == 0) {
						const upward = 10 / 100
						const downward = 10 / 100
						// ---------- PARAMETRE DE LA TRANSACTION
						const depositA = this.quantity.toString();
						const depositB = this.receive_quantity.toString();

						const exactPrice = depositA / depositB;
						const minPrice = exactPrice - (exactPrice * downward);
						const maxPrice = exactPrice + (exactPrice * upward);

						operations.push(
							StellarSdk.Operation.liquidityPoolDeposit({
								liquidityPoolId: poolId,
								maxAmountA: depositA,
								maxAmountB: depositB,
								minPrice: minPrice.toFixed(7),
								maxPrice: maxPrice.toFixed(7),
							}))
					}
					if (this.amm == 1) {
						if (this.quantity > this.order.balance) this.quantity = this.order.balance;
						const amount = this.quantity.toString()

						operations.push(
							StellarSdk.Operation.liquidityPoolWithdraw({
								liquidityPoolId: poolId,
								amount: amount,
								minAmountA: "0",
								minAmountB: "0",
							})
						)
					}
					if (this.amm == 2) {

						let asset_poll_a;
						let asset_poll_b;

						if (this.order.reserves[0].asset_code == "XLM") {
							asset_poll_a = new StellarSdk.Asset.native();
						} else {
							asset_poll_a = new StellarSdk.Asset(this.order.reserves[0].asset_code, this.order.reserves[0].asset_issuer);
						}


						if (this.order.reserves[1].asset_code == "XLM") {
							asset_poll_b = new StellarSdk.Asset.native();
						} else {
							asset_poll_b = new StellarSdk.Asset(this.order.reserves[1].asset_code, this.order.reserves[1].asset_issuer);
						}

						const [A, B] = this.orderAssets(...[
							asset_poll_a, asset_poll_b
						]);

						const poolShareAsset = new StellarSdk.LiquidityPoolAsset(A, B, StellarSdk.LiquidityPoolFeeV18);

						operations.push(
							StellarSdk.Operation.changeTrust({
								asset: poolShareAsset,
								limit: "0",
							})
						)
					}

				}


				// ------------------------------------------------------------------------------------------------------------------- ACHAT VIA AMM

				if (this.operation == "amm_buy") {

					operations.push(
						StellarSdk.Operation.pathPaymentStrictSend({
							sendAsset: currency_asset,
							sendAmount: this.quantity,
							destination: this.public_key,
							destAsset: send_asset,
							destMin: "0.001"
						})
					)
				}


				// ------------------------------------------------------------------------------------------------------------------- VENTE VIA AMM

				if (this.operation == "amm_sell") {
					operations.push(
						StellarSdk.Operation.pathPaymentStrictSend({
							sendAsset: send_asset,
							sendAmount: this.quantity,
							destination: this.public_key,
							destAsset: currency_asset,
							destMin: "0.001"
						})
					)
				}


				// ------------------------------------------------------------------------------------------------------------------- TRANSFERT VIA AMM - Stric Send
				if (this.operation == "amm_transfert" && this.garanty == 0) {
					operations.push(
						StellarSdk.Operation.pathPaymentStrictSend({
							sendAsset: send_asset,
							sendAmount: this.quantity,
							destination: destination_keys.publicKey(),
							destAsset: receive_asset,
							destMin: "0.001"
						})
					)
				}

				// ------------------------------------------------------------------------------------------------------------------- TRANSFERT VIA AMM - Strict Send
				if (this.operation == "amm_transfert" && this.garanty == 1) {
					// Reception et destination sont inversé car on indique le montant de destination
					operations.push(
						StellarSdk.Operation.pathPaymentStrictReceive({
							sendAsset: receive_asset,
							destAmount: this.quantity,
							destination: destination_keys.publicKey(),
							destAsset: send_asset,
							sendMax: "9999999.9999999"
						})
					)
				}

				// ------------------------------------------------------------------------------------------------------------------- RECLAMER SOLDE RECUPERABLE

				if (this.operation == "claim_claimable_balance") {
					operations.push(StellarSdk.Operation.claimClaimableBalance({
						balanceId: this.order.id
					}));
				}

				// ------------------------------------------------------------------------------------------------------------------- PARAMETRES DE LA TRANSACTION

				let params = {
					"source": origine_keys,
					"operations": operations,
					"memo": memo,
					"chain": params2
				}

				console.log(params);

				// ------------------------------------------------------------------------------------------------------------------- ENVOI DE LA TRANSACTION
				this.transaction(params)


			} catch (error) {
				this.etat_transaction = this.$t('transaction_reject_wallet') + error.toString().replace("TypeError: ", '');
				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 144, got undefined")
					this.etat_transaction = this.$t('transaction_reject_false');
				this.error_data = error;
				this.progress = false;

				this.$emit('result', false)

			}
		},

		transaction(params) {
			try {

				this.$store.dispatch('stellar_transaction', params).then(
					response => {
						//console.log("stellar_transaction")
						if (params.chain == undefined) {
							//console.log("success " + params.memo)
							this.progress = false;
							this.etat_transaction = "success";
							this.$emit('result', true)
						} else this.transaction(params.chain)
					},
					error => {
						console.log(error)
						this.progress = false;
						this.etat_transaction = "Echec de la transaction";
						this.error_why = error.toString().substr(6)
						this.$emit('result', false)
					}
				)
			} catch (error) {
				this.etat_transaction = this.$t('transaction_reject_wallet') + error.toString().replace("TypeError: ", '');
				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 144, got undefined")
					this.etat_transaction = this.$t('transaction_reject_false');

				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 48, got 144")
					this.etat_transaction = this.$t('transaction_reject_key');


				this.error_data = error;
				this.progress = false;

				this.$emit('result', false)
			}
		},

		change_price_total() {
			this.quantity = (Number(this.price_total) / Number(this.price_unit)).toFixed(7);
		},

	},
	watch: {
		data_value() {
			this.manage_value = this.data_value;
		},
		quantity() {
			this.quantity = this.quantity.replace(',', '.')
			this.price_total = (Number(this.price_unit) * Number(this.quantity)).toFixed(7);

			if (this.operation == "staking")
				this.staking_update()

			if (this.operation == "liquiditypool")
				this.pool_update()
		},
		staking() {
			this.staking_update()
		},
		price_total() {
			this.price_total = this.price_total.replace(',', '.')

		},
		price_unit() {
			// -- pas elegant mais je ne trouve pas pourquoi il me met le prix du Berlcoin
			//		if ((this.operation == "manageBuyOfferNFT") || (this.operation == "manageSellOfferNFT") && this.price_unit > 100) this.price_unit = "0"
			this.price_unit = this.price_unit.replace(',', '.')
			this.price_total = (Number(this.price_unit) * Number(this.quantity)).toFixed(7);
		},
		send_asset() {
			console.log(this.send_asset)
			this.send_asset_issuer = this.send_asset.asset_issuer
			this.send_asset_code = this.send_asset.asset_code
		},
		receive_asset() {
			this.receive_asset_issuer = this.receive_asset.asset_issuer
			this.receive_asset_code = this.receive_asset.asset_code
		},
		public_key() {
			this.source_key = this.public_key;
		},
		receive_key() {
			this.destination_key = this.receive_key;
		},
		amount() {
			this.quantity = Number(this.amount).toFixed(7);
		},
		price() {
			this.price_unit = Number(this.price).toFixed(7);
		},
		price_total() {
			(Number(this.price_unit) * Number(this.quantity)).toFixed(7);
		},
		check_payment() {
			if (this.check_payment == 1) this.show.day = true;
			else this.show.day = false;
		},
		amm() {
			if (this.amm == 0) {
				this.show.receive_quantity = true;
				this.show.quantity = true;
				this.quantity_asset_code = this.order.reserves[0].asset_code
				this.receive_quantity_asset_code = this.order.reserves[1].asset_code
			}

			if (this.amm == 1) {
				this.quantity_asset_code = "Share"
				this.show.receive_quantity = false;
				this.show.quantity = true;
			}

			if (this.amm == 2) {
				this.show.receive_quantity = false;
				this.show.quantity = false;
			}


		},
		garanty() {
			if (this.garanty == 0) this.quantity_asset_code = this.send_asset_code
			if (this.garanty == 1) this.quantity_asset_code = this.receive_asset_code

		},
		operation() {

			this.init()
		},
		receive_asset_codeissuer() {
			//		console.log(this.receive_asset_code)
			//		this.quantity = "0";
			this.receive_asset_code = this.receive_asset_codeissuer.substring(0, this.receive_asset_codeissuer.indexOf(":"))
			this.receive_asset_issuer = this.receive_asset_codeissuer.substring(this.receive_asset_codeissuer.indexOf(":") + 1)
			if (!(this.receive_asset_code == this.currency.asset_code && this.receive_asset_issuer == this.currency.asset_issuer)) {
				let temp = this.$store.getters.asset("distributor", this.receive_asset_code, this.receive_asset_issuer)
				if (temp != undefined) this.price_unit = temp.price_unit.toFixed(7);
				if (this.operation == "manageBuyOffer" && this.send_asset_code == "XLM") this.price_unit = (Number(this.price_unit) - 0.0000001).toFixed(7);
				if (this.operation == "manageBuyOffer" && this.receive_asset_code == "XLM") this.price_unit = (Number(this.price_unit) + 0.00001).toFixed(4);

				console.log(this.operation, this.receive_asset_code)
			}
			if (this.operation == "manageBuyOfferNFT") {
				//console.log(this.receive_asset_code)
				this.price_unit = Number(this.receive_asset_code.substring(this.receive_asset_code.length - 2)).toFixed(2)

			}
		},

		send_asset_codeissuer() {

			//		this.quantity = "0";
			this.send_asset_code = this.send_asset_codeissuer.substring(0, this.send_asset_codeissuer.indexOf(":"))
			this.send_asset_issuer = this.send_asset_codeissuer.substring(this.send_asset_codeissuer.indexOf(":") + 1)
			if (!(this.send_asset_code == this.currency.asset_code && this.send_asset_issuer == this.currency.asset_issuer)) {
				let temp = this.$store.getters.asset("distributor", this.send_asset_code, this.send_asset_issuer)
				if (temp != undefined) this.price_unit = temp.price_unit.toFixed(7);
				if (this.operation == "manageSellOffer" && this.send_asset_code == "XLM") this.price_unit = (Number(this.price_unit) - 0.0000001).toFixed(7);
			}
			if (this.operation == "manageSellOfferNFT") {
				//			console.log(this.receive_asset_codeissuer)
				this.price_unit = Number(this.send_asset_code.substring(this.send_asset_code.length - 2)).toFixed(2)
			}
		},


	}
}
</script>

<style scoped>
</style>
