<template>
<div>
	<v-row>
		<v-col cols=12>
			<template>
				<v-banner single-line
									elavation=0
									color="yellow lighten-5">
					<v-icon color="success"
									@click="$emit('changepage', 'dashboard')"
									class="mr-6"
									size="24">
						mdi-home
					</v-icon>
					<template v-slot:actions>
						<v-btn class="mx-2"
									 fab
									 dark
									 small
									 @click="$emit('changepage', 'keys')"
									 :color="public_key == undefined ? 'red' : 'success'">
							<v-icon dark>
								mdi-lock
							</v-icon>
						</v-btn>
					</template>
				</v-banner>
			</template>
		</v-col>
	</v-row>

	<v-row>

		<v-col cols=12
					 v-if=" public_key == undefined">
			<v-alert dark
							 class="pa-2 py-5  "
							 color="warning">
				<v-icon>
					mdi-checkbox-multiple-marked-outline
				</v-icon> {{$t('nokey')}}
				<template>
					<v-btn class="    float-right "
								 color="primary"
								 @click="$emit('changepage', 'keys')"
								 elevation=0
								 small>{{$t('keys')}}</v-btn>
				</template>
			</v-alert>
		</v-col>

		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=4>
			<template>
				<v-card class="mx-auto  "
								@click="change_page('primary_buy')">
					<v-img :src="media + 'cryptocurrency-concept' + '.png'" />
					<v-card-title v-html="$t('buyberlcoin')" />
					<v-card-text style="height:100px;"
											 v-html="$t('buyberlcoin_text')" />
					<v-divider class="mx-4"></v-divider>
					<v-card-actions>
						<v-icon slot="icon"
										color="warning"
										size="36">
							mdi-shopping
						</v-icon>
						<v-btn color="deep-purple lighten-2"
									 text>
							{{$t('buydirect')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-col>


		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=4>
			<template>
				<v-card class="mx-auto  "
								@click="change_page('nft')">
					<v-img :src="media + 'BER0001x00'+ (Math.floor(Math.random() * 8)+1).toString() + '.png'" />
					<v-card-title v-html="$t('estate_card')" />
					<v-card-text style="height:100px;"
											 v-html="$t('estate_card_text')" />
					<v-divider class="mx-4"></v-divider>
					<v-card-actions>
						<v-icon slot="icon"
										color="warning"
										size="36">
							mdi-shopping
						</v-icon>
						<v-btn color="deep-purple lighten-2"
									 text>
							{{$t('buydirect')}}
						</v-btn>
					</v-card-actions>
				</v-card>

			</template>
		</v-col>

		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=4>
			<template>
				<v-card class="mx-auto  ">
					<a style="text-decoration:none;color:rgba(0, 0, 0, 0.6);"
						 href="https://berlcoin.berlin">
						<v-img :src="media + '31324' + '.jpg'" />
						<v-card-title style="color:rgba(0, 0, 0, 0.86);"
													v-html="$t('convert_berlcoin_title')" />
						<v-card-text style="height:100px;"
												 v-html="$t('convert_berlcoin_text')" />
					</a>
					<v-divider class="mx-4"></v-divider>
					<v-card-actions>

						<v-btn color="deep-purple lighten-2"
									 @click="change_page('secondary_sell')"
									 dark>
							XLM > fcEURO
						</v-btn>
						<v-spacer> </v-spacer>
						<v-btn color="deep-purple lighten-2"
									 @click="change_page('secondary_buy')"
									 dark>
							fcEURO > XLM
						</v-btn>
					</v-card-actions>

				</v-card>
			</template>
		</v-col>


	</v-row>
	<v-row>

		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=4>
			<template>
				<v-card class="mx-auto  ">
					<v-img src="@/assets/media/home_primary.jpg"></v-img>
					<v-card-title v-html="$t('dashboard_primary_title')" />
					<v-card-text style="height:100px;"
											 v-html="$t('dashboard_primary_text')" />

					<v-divider class="mx-4"></v-divider>
					<v-card-actions>
						<v-icon slot="icon"
										color="warning"
										size="36">
							mdi-currency-eur
						</v-icon>
						<v-btn color="deep-purple lighten-2"
									 text
									 @click="change_page('primary')">
							Accéder au marché
						</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-col>

		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=4>
			<template>
				<v-card class="mx-auto  ">
					<v-img src="@/assets/media/transfert.jpg"></v-img>
					<v-card-title v-html="$t('dashboard_decentralised_title')" />
					<v-card-text style="height:100px;"
											 v-html="$t('dashboard_decentralised_text')" />
					<v-divider class="mx-4"></v-divider>
					<v-card-actions>
						<v-icon slot="icon"
										color="warning"
										size="36">
							mdi-circle-outline
						</v-icon>
						<v-btn color="deep-purple lighten-2"
									 text
									 @click="change_page('secondary')">
							{{$t('dashboard_access')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-col>

		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=4>
			<template>
				<v-card class="mx-auto  ">
					<v-img src="@/assets/media/home_amm.jpg"></v-img>
					<v-card-title v-html="$t('dashboard_amm_title')" />
					<v-card-text style="height:100px;"
											 v-html="$t('dashboard_amm_text')" />
					<v-divider class="mx-4"></v-divider>
					<v-card-actions>
						<v-icon slot="icon"
										color="warning"
										size="36">
							mdi-autorenew
						</v-icon>
						<v-btn color="deep-purple lighten-2"
									 text
									 @click="change_page('amm')">
							{{$t('dashboard_access')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-col>
	</v-row>

	<v-row>
		<v-col cols=12
					 v-if="!kyc_wallet && public_key != undefined">
			<v-alert dark
							 color="warning">
				<v-icon>
					mdi-checkbox-multiple-marked-outline
				</v-icon> {{$t('nokyc')}}
				<template>
					<v-btn class="    float-right "
								 color="primary"
								 @click="$emit('changepage', 'validate_kyc')"
								 elevation=0
								 small>{{$t('nokyc1')}}</v-btn>
				</template>
			</v-alert>
		</v-col>

		<v-col cols=12
					 v-if="!sponsor_wallet && public_key != undefined">
			<v-alert dark
							 color="warning">
				<v-icon>
					mdi-account-convert
				</v-icon> {{$t('nosponsor')}}
				<template>
					<v-btn class="    float-right "
								 color="primary"
								 @click="$emit('changepage', 'validate_sponsor')"
								 elevation=0
								 small>{{$t('nosponsor1')}}</v-btn>
				</template>
			</v-alert>
		</v-col>
	</v-row>


	<v-row>
		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=4>
			<template>
				<v-card class="mx-auto  ">
					<a style="text-decoration:none;color:rgba(0, 0, 0, 0.6);"
						 href="https://berlcoin.berlin">
						<v-img :src="media + '4288663-2' + '.png'" />
						<v-card-title style="color:rgba(0, 0, 0, 0.86);"
													v-html="$t('discover_berlcoin_title')" />
						<v-card-text style="height:100px;"
												 v-html="$t('discover_berlcoin_text')" />
					</a>
					<v-divider class="mx-4"></v-divider>
					<v-card-actions>
						<v-icon slot="icon"
										color="warning"
										size="36">
							mdi-web
						</v-icon>
						<v-btn color="deep-purple lighten-2"
									 href="https://berlcoin.berlin"
									 text>
							{{$t('discover_berlcoin_title')}}
						</v-btn>
					</v-card-actions>

				</v-card>
			</template>
		</v-col>

		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=4>
			<template>
				<v-card class="mx-auto  ">
					<a style="text-decoration:none;color:rgba(0, 0, 0, 0.6);"
						 href="https://fraction.immo">
						<v-img :src="media + 'home_fraction.immo' + '.png'" />
						<v-card-title style="color:rgba(0, 0, 0, 0.86);"
													v-html="$t('discover_fractionimmo_title')" />
						<v-card-text style="height:100px;"
												 v-html="$t('discover_fractionimmo_text')" />
					</a>
					<v-divider class="mx-4"></v-divider>
					<v-card-actions>
						<v-icon slot="icon"
										color="warning"
										size="36">
							mdi-web
						</v-icon>
						<v-btn color="deep-purple lighten-2"
									 href="https://fraction.immo"
									 text>
							{{$t('discover_fractionimmo_title')}}
						</v-btn>
					</v-card-actions>

				</v-card>
			</template>
		</v-col>

		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 xl=4>
			<template>
				<v-card class="mx-auto  ">
					<a style="text-decoration:none;color:rgba(0, 0, 0, 0.6);"
						 href="https://fraction.re">
						<v-img :src="media + '5372580-504' + '.jpg'" />
						<v-card-title style="color:rgba(0, 0, 0, 0.86);"
													v-html="$t('discover_fractionre_title')" />
						<v-card-text style="height:100px;"
												 v-html="$t('discover_fractionre_text')" />
					</a>
					<v-divider class="mx-4"></v-divider>
					<v-card-actions>
						<v-icon slot="icon"
										color="warning"
										size="36">
							mdi-web
						</v-icon>
						<v-btn color="deep-purple lighten-2"
									 href="https://fraction.re"
									 text>
							{{$t('discover_fractionre_title')}}
						</v-btn>
					</v-card-actions>

				</v-card>
			</template>
		</v-col>

	</v-row>




</div>
</template>


<script>
import listbalances from "@/components/list_balances.vue"
import listliquiditypools from "@/components/list_liquidity_pools.vue"
import listclaimablebalances from "@/components/list_claimable_balances.vue"
import listsellingoffers from "@/components/list_selling_offers.vue"
import cardbuying from "@/components/card_buying.vue"
import cardselling from "@/components/card_selling.vue"


export default {
	name: 'dashboard',
	components: {
		listbalances,
		listliquiditypools,
		listclaimablebalances,
		cardbuying,
		cardselling,
		listsellingoffers

	},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		public_key() {
			return this.$store.getters.public_key;
		},
		kyc_wallet() {
			return this.$store.getters.kyc_wallet;
		},
		sponsor_wallet() {
			return this.$store.getters.sponsor_wallet;
		},
		media() {
			return this.$store.getters.media;
		},
	},
	methods: {
		askaction(action) {
			//console.log("askaction - dashboard")
			if (action == "init") this.$emit('askaction', "init")
		},
		change_page(page) {
			this.$emit('changepage', page)
		}
	},
	watch: {}
}
</script>

<style scoped>
</style>
