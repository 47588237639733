<template>
<div>
	<v-row>


		<v-col cols=12>
			<template>
				<v-banner single-line
									elavation=0
									color="yellow lighten-5">
					<v-icon color="warning"
									@click="$emit('changepage', 'dashboard')"
									class="mr-6"
									size="24">
						mdi-home
					</v-icon>
					<v-icon color="warning"
									@click="$emit('changepage', 'primary')"
									class="mr-6"
									size="24">

						mdi-currency-eur
					</v-icon>
					<v-icon color="warning"
									class="mr-6"
									@click="$emit('changepage', 'nft')"
									size="24">
						mdi-cards
					</v-icon>
					<v-icon color="success"
									class="mr-6"
									size="24">
						mdi-shopping
					</v-icon>

					<template v-slot:actions>
						<v-btn color="primary"
									 text>
							{{total_shop.toFixed(2)}} €
						</v-btn>
					</template>
				</v-banner>
			</template>
		</v-col>
	</v-row>
	<v-row v-show="!transactionstate">

		<v-col cols=12
					 sm=12
					 md=8
					 lg=8
					 xl=8>
			<v-row>
				<v-col cols=12
							 v-for="(article, asset_code, index) in shopping"
							 style="width:100%"
							 :key="index">
					<v-row>
						<v-col cols=12
									 sm=6
									 md=3
									 lg=3
									 xl=3>
							<v-img class="rounded"
										 :src="media +asset_code + '.png'" />
						</v-col>
						<v-col cols=4
									 sm=2
									 md=3
									 lg=3
									 xl=3>
							<b class="">{{asset_code}}</b><br />
							<span class="">{{(article.price *daily_rate).toFixed(2) }}€</span>
						</v-col>
						<v-col cols=4
									 sm=2
									 md=3
									 lg=3
									 xl=3>
							<v-btn-toggle class=""
														v-model="button[asset_code]">
								<v-btn @click="minus(asset_code,article.asset_issuer, article.price)">
									<v-icon>mdi-minus</v-icon>
								</v-btn>
								<v-btn @click="plus(asset_code,article.asset_issuer,article.price )"
											 :color="button[asset_code] > 0 ? 'success' : ''">
									{{ article.quantity }}
								</v-btn>
								<v-btn>
									<v-icon @click="plus(asset_code,article.asset_issuer,article.price)">mdi-plus</v-icon>
								</v-btn>
							</v-btn-toggle>
						</v-col>
						<v-col cols=4
									 sm=2
									 md=3
									 lg=3
									 xl=3>
							<b style="float:right"
								 class="success--text mr-1 text-right">{{(article.price*article.quantity *daily_rate).toFixed(2) }}€</b>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols=12
					 sm=12
					 md=4
					 lg=4
					 xl=4>
			<div style="max-width:750px;"
					 class=" m-0  p-5  ">
				<v-card v-show="public_key!= undefined"
								class="mb-12 pa-3 "> <b>{{$t("key_delivery")}}</b>
					<span class="description"> {{public_key}} </span>
				</v-card>

				<v-card v-show="public_key== undefined"
								color="red darken-4"
								dark
								@click="$emit('changepage', 'keys')"
								class="mb-12 pa-6"> {{$t('message_no_key')}}
				</v-card>


				<div v-show="public_key!= undefined">
					<v-card v-show="!virement"
									class="mb-12 pa-1">

						<div ref="paypal"></div>
						<v-btn block
									 @click="transactionstate=true;virement=true"
									 small
									 color="success"
									 depressed
									 primary>
							{{$t('pay_by_bank')}}
						</v-btn>
						<v-btn block
									 :disabled="balance_xlm <  Number(cours_XLM_inverse * total_shop)"
									 depressed
									 class="mt-2"
									 @click="transactionstate=true;crypto=true"
									 small
									 color="success"
									 primary>
							{{$t('pay_by_crypto')}} ({{(cours_XLM_inverse * total_shop).toFixed(2)}} XLM)
						</v-btn>
						<i style="text-align:right; font-size:80%"> {{$t('balance')}} XLM Lumens : {{Number(balance_xlm).toFixed(2)}} </i>
					</v-card>

				</div>
			</div>
		</v-col>

	</v-row>
	<v-row v-show="transactionstate">

		<v-col cols=12
					 sm=12
					 md=9
					 lg=9
					 xl=9>
			<template>
				<v-card class="mx-auto  "
								style="max-width:350px">

					<v-card-title class="h6"
												v-html="$t('invest_ready')" />
					<v-card-text>
						<span v-if="!virement && !crypto"><br />
							{{$t('thanks')}}<br /><b> {{public_key}}</b>

							{{asset}}

							<br /><br />
							<v-btn text
										 @click="$emit('changepage', 'dashboard')"
										 small
										 color="success"
										 primary>
								{{$t('accueil')}}
							</v-btn>
						</span>


						<div v-if="crypto">
							<v-alert dense
											 text
											 class="m-1"
											 type="success">
								{{(Number(cours_XLM_inverse * total_shop) ).toFixed(2)}} <b> XLM </b>

							</v-alert>



							<stellarform operation="manageBuyOfferNFT_XLM"
													 css="pa-3"
													 :order="order"
													 @result="stellarform_result" />
						</div>
						<span v-if="virement">
							{{$t("reservation")}}
							<br />
							<br /><b>{{$t('ibanlabel')}} </b>
							<br />
							<br /><b style=" color:red">FR76 1679 8000 0100 0085 4071 638</b>

							<br />
							<br /> {{$t('codebic')}}: <b style=" color:red">TRZOFR21XXX</b>
							<br /> {{$t('titulaire')}}: <b style=" color:red">Fraction Real Estate </b>
							<br />
							<br /> {{$t("reftranfert")}} : <b style=" color:red">{{uid}}</b>
							<br /><br /> <b>{{$t('bank')}} </b> <br />
							Shine : 5 rue Charlot, 75003 Paris

							<validbutton :etat_transaction="etat_transaction"
													 v-if="etat_transaction != 'success'"
													 :error_why="error_why"
													 :progress="progress"
													 @valid="send_transaction" />

							<div class="ma-3"
									 v-if="etat_transaction == 'success'">


								<v-btn color="teal lighten-4"
											 class="ma-2"
											 elevation=0>
									<v-icon color="success"
													@click="print_virement()"
													class="mx-auto   "
													large>mdi-printer
									</v-icon>
								</v-btn>


							</div>
						</span>
					</v-card-text>
					<v-divider class="mx-4"></v-divider>
					<v-card-actions>
						<v-btn text
									 v-if="virement"
									 @click="virement=false;transactionstate=false"
									 small
									 color="success"
									 primary>
							{{$t('paypal')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</template>

		</v-col>
	</v-row>

</div>
</template>


<script>
import validbutton from '@/components/valid_button.vue'
import stellarform from '@/components/stellar_form.vue'
var StellarSdk = require('stellar-sdk');

export default {
	name: 'shoppingcard',
	components: {
		validbutton,
		stellarform
	},
	props: {},
	data: () => ({
		button: [],
		virement: false,
		crypto: false,
		loaded: false, // necessaise a paypal
		paidFor: false, // necessaise a paypal
		axios_private: [],

		transactionstate: false,
		uid: undefined,
		etat_transaction: undefined,
		error_why: undefined,
		progress: false,
		balance_xlm: 0,
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		this.uid = (Date.now().toString(36) + Math.random().toString(36).substr(2)).substr(10).toUpperCase();


		const script = document.createElement("script");
		script.src =
			"https://www.paypal.com/sdk/js?&currency=EUR&client-id=" + this.paypal_key;
		script.addEventListener("load", this.setLoaded);
		document.body.appendChild(script);
		if (this.asset != undefined) this.balance_xlm = this.asset.balance;

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		shopping() {
			return this.$store.state.shopping;
		},
		total_shop() {
			return this.$store.getters.total_shop;
		},
		daily_rate() {
			return this.$store.getters.daily_rate;
		},
		paypal_key() {
			return this.$store.getters.paypal_key;
		},
		public_key() {
			return this.$store.getters.public_key;
		},
		media() {
			return this.$store.getters.media;
		},
		cours_XLM_inverse() {
			return this.$store.getters.cours_XLM_inverse;
		},
		asset() {
			return this.$store.getters.asset("wallet", "XLM", "native");
		}
	},
	methods: {
		send_transaction: function (key) {

			this.progress = true;
			this.etat_transaction = "";
			try {
				var source = StellarSdk.Keypair.fromSecret(key);
				if (this.public_key == source.publicKey()) {
					this.etat_transaction = "success";
					this.progress = false;
					this.confirm("");
				} else {
					this.progress = false;
					this.etat_transaction = "Echec de la transaction";
					this.error_why = this.$t('transaction_reject_false')
				}
			} catch (error) {
				this.etat_transaction = this.$t('transaction_reject_wallet') + error.toString().replace("TypeError: ", '');
				if (error.toString().replace("TypeError: ", '') == "Error: invalid version byte. expected 144, got undefined")
					this.etat_transaction = this.$t('transaction_reject_false');
				this.progress = false;
			}
		},

		print_virement() {
			window.print();

			this.$emit('changepage', 'dashboard');
		},

		plus(article, asset_issuer, price) {
			let param = {
				"article": article,
				"asset_issuer": asset_issuer,
				"price": price
			}
			this.$store.dispatch('plus', param)
		},
		minus(article, asset_issuer, price) {
			let param = {
				"article": article,
				"asset_issuer": asset_issuer,
				"price": price
			}
			this.$store.dispatch('minus', param)
		},
		setLoaded: function () {
			//	console.log(this.total_shop)
			this.loaded = true;
			window.paypal
				.Buttons({
					createOrder: (data, actions) => {
						return actions.order.create({
							purchase_units: [{
								description: "NFT -  Berlcoin",
								amount: {
									currency_code: "EUR",
									value: this.total_shop.toFixed(2)
								}
							}]
						});
					},
					onApprove: async (data, actions) => {
						const order = await actions.order.capture();
						this.paidFor = true;
						this.transactionstate = true;
						this.confirm(order)
					},
					onError: err => {
						this.transactionstate = false;
					}
				})
				.render(this.$refs.paypal);
		},
		confirm(order) {
			this.opt = [];
			this.opt["api"] = "buyberlcoin"
			this.opt["selection"] = 'berlcoin';
			this.opt["json"] = JSON.stringify(order);
			this.opt["total_shop"] = this.total_shop;
			this.opt["shopping"] = JSON.stringify(this.shopping);
			this.opt["debug"] = false;
			this.opt["virement"] = this.uid;
			this.opt["public_key"] = this.public_key;

			this.$store.dispatch('post', this.opt).then(response => {
				if (response != undefined)
					if (response.data.result) this.$store.dispatch('init_shopping')

			})

		}

	},
	watch: {}
}
</script>

<style>
.description {
	font-family: "Courier", Helvetica, Arial;
	font-size: 20px;
}
</style>
