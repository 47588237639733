<template>
<v-stepper v-model="position_stepper"
					 vertical
					 class="mt-3">



	<v-stepper-step :complete="position_stepper > 1"
									step="1">
		{{$t("amountprimarysell")}}
		<div v-if="position_stepper>1">

			<br />
			<b class="p-5  success--text"
				 v-html="Number(moneyback).toFixed(2)+ '€ = '+ Number(amount_berlcoin).toFixed(2)  + ' Berlcoin'" />
		</div>
	</v-stepper-step>
	<v-stepper-content step="1">
		<v-card color="blue-grey lighten-5"
						elevation=0
						class="mb-12 pa-4">

			<v-btn elevation=0
						 class="ma-1"
						 color="white">
				<v-icon color="orange darken-2">
					mdi-currency-eur
				</v-icon><b class="success--text"
					 v-html="monetary_aspect(daily_rate)" />
			</v-btn>
			<v-btn elevation=0
						 class="ma-1"
						 color="white">
				<v-icon color="orange darken-2">
					mdi-circle-outline
				</v-icon><b class="success--text"
					 v-html="monetary_aspect(token.balance)+'  '" />
			</v-btn>

			<br />
			<br />
			<v-text-field v-model="amount_berlcoin"
										outlined
										:label="$t('amountberlcoin')"></v-text-field>
			<v-btn x-small
						 elevation=0
						 class="mx-1"
						 color="success"
						 @click="coupe(25)"
						 dark>
				25%
			</v-btn>
			<v-btn x-small
						 elevation=0
						 class="mx-1"
						 color="success"
						 @click="coupe(50)"
						 dark>
				50%
			</v-btn>
			<v-btn x-small
						 elevation=0
						 class="mx-1"
						 color="success"
						 @click="coupe(75)"
						 dark>
				75%
			</v-btn>
			<v-btn x-small
						 elevation=0
						 class="mx-1"
						 color="success"
						 @click="coupe(100)"
						 dark>
				100%
			</v-btn>
			<br />
			<span class="ma-1 pt-4"><br />
				{{$t('invest16')}} <b v-html="monetary_aspect(moneyback)" /> euros
			</span>
		</v-card>
		<v-btn class="success"
					 @click="position_stepper = 2">
			{{$t('next')}}
		</v-btn> &nbsp;
		<v-btn @click="position_stepper = position_stepper-1"
					 elevation=0
					 class="mx-2"
					 fab
					 dark
					 small
					 color="primary">
			<v-icon dark>
				mdi-arrow-left
			</v-icon>
		</v-btn>
	</v-stepper-content>



	<v-stepper-step step="2">
		{{$t("confirmation")}}
	</v-stepper-step>
	<v-stepper-content step="2">
		<v-card color="blue-grey lighten-5"
						elevation=0
						class="mb-12 pa-5">

			<stellarform operation="divest"
									 v-if="etat_transaction != 'success'"
									 :memo="'divest ' +uid"
									 :receive_key="$store.getters.account_key('divest')"
									 :send_asset="token"
									 :price="daily_rate"
									 @result="stellarform_result"
									 :amount="amount_berlcoin" />

			<p v-if="etat_transaction == 'success'">
				<br /> {{$t('ask_divest1') }}
				<br /> {{$t('ask_divest2') }}
				<br /> {{$t('ask_divest3') }}

				<br />
				<br /> {{$t("reftranfert")}} : <b style=" color:red">{{uid}}</b>
				<br />

			</p>

		</v-card>


	</v-stepper-content>
</v-stepper>
</template>

<script>
import stellarform from '@/components/stellar_form.vue'
export default {
	name: 'primary_sell_form',
	components: {

    stellarform,

  },
	props: {},
	data: () => ({
    show_menu: true,
		virement: false,
		position_stepper: 1,

		email: '',


		credit: 0,
		reward: 0,
		total: 0,
		totaleuro: 0,
		win: 0,
		step: 1,
		field_euro: 500,
		term: 0,
		cp: '',
		transactionstate: false,
		savestate: undefined,

		loaded: false,
		paidFor: false,
		souscription: false,

		uid: undefined,
		etat_transaction: undefined,
		error_why: undefined,
		progress: false,
		paypal_return: undefined,
		menu_select: 0,
		amount_berlcoin: 0,
		moneyback: 0,
  }),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
    this.$i18n.locale = this.$route.params.lang

 		this.email = this.public_key;
		this.uid = (Date.now().toString(36) + Math.random().toString(36).substr(2)).substr(10).toUpperCase();

  },
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
    token() {
      return this.$store.getters.asset("wallet", "Berlcoin", "")
    },
    public_key() {
      return this.$store.getters.public_key;
    },
    daily_rate() {
      return this.$store.getters.daily_rate;
    },

  },
	methods: {
    stellarform_result(result) {
    if (result) this.etat_transaction = 'success';
    this.opt = [];
    this.opt["api"] = "divest"
    this.opt["selection"] = 'berlcoin';
    this.opt["moneyback"] = this.moneyback;
    this.opt["amount_berlcoin"] = this.amount_berlcoin;
    this.opt["uid"] = this.uid
    this.opt["public_key"] = this.public_key;
    this.opt["debug"] = true;
    this.$store.dispatch('post', this.opt);
  },
  imprimer() {
    this.print = false;
    window.print();
  },
  coupe: function (pourcentage) {
    this.amount_berlcoin = Number(this.token.balance) * (pourcentage / 100);

  },

  	},
  	watch: {
  		position_stepper() {
  			if (this.position_stepper == 2) this.show_menu = false;

  		},
  		amount_berlcoin: function () {
  			this.moneyback = Number(this.amount_berlcoin) * Number(this.daily_rate);
  		},


  	}
  }
  </script>

  <style scoped>
  </style>
