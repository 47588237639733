<template>
<v-card class="mx-auto  "
				style="height:100%">

	<v-card-title class="white--text red darken-4"> NFT
		<v-spacer> </v-spacer>
		<v-btn v-show="show_action == 'list'"
					 fab
					 elevation=0
					 small
					 text
					 dark
					 @click="$emit('changepage', 'nft')"
					 color="white">
			<v-icon dark>
				mdi-plus-circle
			</v-icon>

		</v-btn>

	</v-card-title>
	<hr />
	<listprimarynft />

</v-card>
</template>


<script>
import listprimarynft from "@/components/list_primary_nft.vue"
export default {
	name: 'card_primary_nft',
	components: {
		listprimarynft
	},
	props: {},
	data: () => ({
		show_action: 'list',
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
