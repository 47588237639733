<template>
<div>
	<v-row>

		<v-col cols=12>
			<template>
				<v-banner single-line
									elavation=0
									color="yellow lighten-5">
					<v-icon color="warning"
									@click="$emit('changepage', 'dashboard')"
									class="mr-6"
									size="24">
						mdi-home
					</v-icon>
					<v-icon color="success"
									class="mr-6"
									size="24">
						mdi-send-secure
					</v-icon>


					<template v-slot:actions>
						<v-btn class="mx-2"
									 fab
									 dark
									 small
									 @click="$emit('changepage', 'keys')"
									 :color="public_key == undefined ? 'red' : 'success'">
							<v-icon dark>
								mdi-lock
							</v-icon>
						</v-btn>
					</template>
				</v-banner>
			</template>
		</v-col>
	</v-row>

	<v-row>
		<v-col cols=12
					 sm=6
					 md=6
					 lg=4
					 class="ma-0 pa-0"
					 xl=3>

			<v-col cols=6
						 class="float-left">
				<v-card color="success"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/transfer.svg"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3">DIRECT </p>
				</v-card>
			</v-col>

			<v-col cols=6
						 class="float-left">
				<v-card color="success"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/calendar-time.svg"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3">A TERME ECHU</p>
				</v-card>
			</v-col>



			<v-col cols=6
						 class="float-left">
				<v-card color="success"
								class="pa-3"
								dark>
					<v-img src="@/assets/icones/friendship.svg"
								 style="width:100%"
								 aspect-ratio="1"
								 class="mr-8  lighten-2" />
					<p class="text-center mt-3"> TIERS DE CONFIANCE </p>
				</v-card>
			</v-col>
		</v-col>



		<v-col cols=12
					 v-if="$store.getters.account_solde('token')>0"
					 sm=6
					 md=6
					 lg=4
					 xl=3>

			<cardtransfert />

		</v-col>






	</v-row>
</div>
</template>


<script>
import cardtransfert from "@/components/card_transfert.vue"


export default {
	name: 'transfert',
	components: {
		cardtransfert
	},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		public_key() {
			return this.$store.getters.public_key;
		},
	},
	methods: {
		askaction(action) {
			//console.log("askaction - dashboard")
			if (action == "init") this.$emit('askaction', "init")
		},
		change_page(page) {
			this.$emit('changepage', page)
		}
	},
	watch: {}
}
</script>

<style scoped>
</style>
