<template>
	<v-list two-line
				>
			 

				<v-list-item 					 >
					<template v-slot:default="{ active }">
						<v-list-item-content class="py-2">
							<v-list-item-title>
								<v-chip color="primary"
												label>
									<b v-html="monetary_aspect(item.balance)" />
								</v-chip>

								<b class="pl-3"> {{ item.asset_code}}</b>
							</v-list-item-title>

							<v-list-item-subtitle class="text--primary">
								{{ (item.asset_type == 'native' || parseInt(item.limit) > 1000000) ? '∞' : ( parseInt(item.limit) == 1 ? 'Unique' :  parseInt(item.limit) )}}
								by {{ item.asset_type == 'native' ? 'Stellar Foundation' : account_name(item.asset_issuer,false)}}
							</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-action class="py-2" >
							<v-chip label>
								<v-icon left
												small
												color="primary">
									mdi-currency-eur
								</v-icon>
								<span v-html="monetary_aspect(item.price_total)" />
							</v-chip>
							<v-list-item-action-text v-html=" monetary_aspect(item.price_unit)" />
						</v-list-item-action>

					</template>
				</v-list-item>
				<v-divider v-if=" item.asset_type=='credit_alphanum4' || item.asset_type=='credit_alphanum12' || item.asset_type=='native'"
								 ></v-divider>

	</v-list>

	</template>


<script>
import stellarform from '@/components/stellar_form.vue'
export default {
	name: 'show_balance',
	components: {
		stellarform
	},
	props: {
		show: {
			type: String,
			default: 'list'
		},
		asset_show: []

	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		item() {
			if (this.asset_show != undefined) return this.$store.getters.asset('wallet', this.asset_show.asset_code, this.asset_show.asset_issuer);
		},
		currency() {
			return this.$store.getters.currency;
		},
	},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
