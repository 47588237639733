<template>
<div>
	<v-row>
		<v-col cols=12>

			<v-banner single-line
								elavation=0
								color="yellow lighten-5">
				<v-icon color="warning"
								@click="$emit('changepage', 'dashboard')"
								class="mr-6"
								size="24">
					mdi-home
				</v-icon>
				<v-icon color="warning"
								class="mr-6"
								@click="$emit('changepage', 'secondary')"
								size="24">
					mdi-circle-outline
				</v-icon>
				<v-icon color="success"
								class="mr-6"
								size="24">
					mdi-shopping
				</v-icon>

			</v-banner>

		</v-col>
	</v-row>
	<v-row class="blue-grey lighten-5">
		<v-col cols=12>

			<v-card class="mx-auto"
							elevation=1
							style="max-width:500px;">
				<v-card-title dark
											class="white--text red darken-4 "> {{$t('balance')}} : {{token.balance}} </v-card-title>

				<stellarform operation="staking"
										 :send_asset="token"
										 css="pa-3"
										 :term="term_contrat"
										 @result="stellarform_result" />
			</v-card>
		</v-col>
	</v-row>
</div>
</template>


<script>
import stellarform from '@/components/stellar_form.vue'


export default {
	name: 'secondary_staking',
	components: {
		stellarform
	},
	props: {},
	data: () => ({
		term_contrat: []
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		this.term_contrat = [];
		let temp = this.data.value;
		if (temp != undefined) {

			const words = temp.split('#');
			this.term_contrat.push({
				"an": 1,
				"label": "1 an",
				"staking": words[0]
			});
			this.term_contrat.push({
				"an": 2,
				"label": "2 ans",
				"staking": words[1]
			});
			this.term_contrat.push({
				"an": 3,
				"label": "3 ans",
				"staking": words[2]
			});
			this.term_contrat.push({
				"an": 4,
				"label": "4 ans",
				"staking": words[3]
			});
			this.term_contrat.push({
				"an": 5,
				"label": "5 ans",
				"staking": words[4]
			});
			this.term_contrat.push({
				"an": 6,
				"label": "6 ans",
				"staking": words[5]
			});
			this.term_contrat.push({
				"an": 7,
				"label": "7 ans",
				"staking": words[6]
			});
			this.term_contrat.push({
				"an": 8,
				"label": "8 ans",
				"staking": words[7]
			});
			this.term_contrat.push({
				"an": 9,
				"label": "9 ans",
				"staking": words[8]
			});
			this.term_contrat.push({
				"an": 10,
				"label": "10 ans",
				"staking": words[9]
			});
		}
		//	console.log(this.term_contrat)
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		token() {
			return this.$store.getters.asset("wallet", "Berlcoin", "")
		},
		public_key() {
			return this.$store.getters.public_key;
		},

		data() {
			return this.$store.getters.data("master", "Berlcoin_term_contract");
		},
	},
	methods: {
		stellarform_result() {
			this.$emit('changepage', 'dashboard');
		},
	},
	watch: {}
}
</script>

<style scoped>
</style>
