	<template><div>
		<v-alert v-if="send_asset != undefined  "
		dense
		text
		class="m-1"
		type="success"
	>
		{{send_asset.balance.toString(7)}} <b> {{send_asset.asset_code}}</b>
	</v-alert>

<v-list two-line 	v-if="display=='list'"
				style="height:450px; overflow-y: scroll;">
				<v-list-item-group v-model="selected"
													 active-class="pink--text">
	<template v-for="(item, index) in trust">
		<v-list-item :key="item.title">
			<v-list-item-content class="py-3 my-2">
				<v-list-item-title>
					<v-chip :color="afternow(item.claimants[0].predicate.not.abs_before.substring(0,10)) ? 'success' : 'warning'"
									label  v-html="item.asset_code" />


					<b class="pl-3" v-if="item.memo != undefined"
						 v-html="item.memo.substring(9,28 )" />
				</v-list-item-title>
				<v-list-item-subtitle class="text--primary"		v-html="item.asset_code.substring(0,3)== public_key.substring(public_key.length-3) ? 'Destination :'+item.asset_code.substring(6,9) : 'Origine : '+item.asset_code.substring(0,3)" />

			</v-list-item-content>

			<v-list-item-action class="py-2">
				<v-chip label>

					<span  v-if="item.memo != undefined" v-html="item.memo.substring(0,8)" />
				</v-chip>

			</v-list-item-action>
		</v-list-item>
		<v-divider :key="index"></v-divider>
	</template>
		</v-list-item-group>
</v-list>

	<div v-if="display=='form'">
		<stellarform :operation="operation"
								 css="pa-3"
								 :order="order"

								 :send_asset="send_asset"
								 @result="stellarform_result" />
		<v-divider></v-divider>

		<v-btn icon
					 @click="display='list';$emit('event', 'show_list')"
					 color="warning">
			<v-icon>mdi-arrow-left</v-icon>
		</v-btn>


	</div>
</div>
</template>

<script>
import stellarform from '@/components/stellar_form.vue'
export default {
	name: 'list_claimable_trust',
	components: {
		stellarform
	},
	props: {
		show: {
			type: String,
			default: 'list'
		}
	},
	data: () => ({
		selected: undefined,
		order: undefined,
		display: "list",
		send_asset: undefined,
		operation: "claim_claimable_balance",

	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		public_key() {
			return this.$store.getters.public_key
		},
		trust() {
			return this.$store.getters.trust('wallet')
		},
		token() {
			return this.$store.getters.asset("wallet", "Berlcoin", "")
		},
		data() {
			return this.$store.getters.data("master", "Berlcoin_term_contract");
		},
	},
	methods: {
		afternow(itemdate) {
			if (Date.parse(itemdate) < new Date()) return true;
			else return false;
		},
		stellarform_result(result) {
			if (result) this.display = "list";

			this.$emit('askaction', "init")
		}
	},
	watch: {
		show() {
			if (this.show == 'new') {
				this.order = undefined;
				this.operation = "staking"
				this.send_asset = this.token
				this.display = 'form';
			}
			if (this.show == 'list') {
				this.send_asset = undefined
			}
		},
		selected() {
			if (this.selected != undefined) {
				this.order = this.trust[this.selected];
				this.operation = "claim_claimable_balance"
				this.send_asset = undefined
				this.display = "form";
				this.$emit('event', "show_form")
			}
		}
	}
}
</script>

<style scoped>
</style>
