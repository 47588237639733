<template>
<v-row>



	<v-col cols=12>

		<div style="height:450px; overflow-y: scroll;"
				 v-if="display=='list'">
			<v-col cols=12
						 v-for="(article, index) in orders"
						 :key="index">

				<div class="pa-1"
						 @click="selected=index">
					<v-img class="rounded mb-1"
								 :src="media +article.asset_code + '.png'" />
					{{(article.address )  }}
					<v-btn small
								 elevation=0
								 style="float:right"
								 :color="color(article)">{{( article.price_end )  }}</v-btn>
					<br /> <b class="">{{article.asset_code}}</b><br />
					<br />
				</div>
			</v-col>
		</div>
		<div class="ma-3"
				 v-if="display=='detail'">



			<h3 v-if="orders[selected].process=='cancel'"
					class="red text-center white--text"
					v-html="$t('cancel_transaction')" />
			<br /> {{$t('invest6')}} : <b style=" color:red"
				 v-html="'<b>'+ (orders[selected].last_change)+' </b> '" />

			<br /> NFT : <b style=" color:red"
				 v-html="'<b>'+orders[selected].asset_code+' </b> '" />
			<br /> BERLCOIN : <b style=" color:red"
				 v-html="'<b>'+parseFloat(orders[selected].price_start).toFixed(2) +' - '+ parseFloat(orders[selected].price_end).toFixed(2)   " />

			<br /> {{$t('paiement')}} : <b class="success--text"
				 v-html="'<b>'+orders[selected].id_payment+' </b> '" />

			<p v-if="orders[selected].id_payment=='' && orders[selected].process!=='cancel'">

				<br /> {{$t("reftranfert")}} : <b style=" color:red">{{orders[selected].id_transaction}}</b>
				<br />
				<br />
				<b class="caption">{{$t('ibanlabel')}} </b>
				<br /><b style="font-size:92%"
					 class="red--text">FR76 1679 8000 0100 0085 4071 638</b>
				<br /> {{$t('codebic')}}: TRZOFR21XXX
				<br /> {{$t('titulaire')}}: <b> Fraction Real Estate </b>
				<br /><br /> <b class="caption">{{$t('bank')}} </b> <br />
				Shine 5 rue Charlot, 75003 Paris


				<br /> <br /> <i style="font-size:80%">{{$t('assist4')}}</i>
			</p>
			<hr class="mt-3" />
			<v-btn small
						 text
						 class="ma-3"
						 @click="display='list'">{{$t('back')}}
			</v-btn>
			<v-btn small
						 v-if="orders[selected].process=='pending'"
						 text
						 class="ma-3 red--text float-right"
						 @click="cancel()">{{$t('cancel_invest')}} </v-btn>
		</div>
	</v-col>
</v-row>
</template>


<script>
export default {
	name: 'list_primary_nft',
	components: {},
	props: {},
	data: () => ({
		orders: [],
		display: "list",
		selected: undefined
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		this.init();
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		media() {
			return this.$store.getters.media;
		},
		public_key() {
			return this.$store.getters.public_key;
		},
		base_croissance() {
			return this.$store.getters.base_croissance;
		},
		vdp_index() {
			return this.$store.getters.vdp_index;
		},
	},
	methods: {
		init() {
			let opt = [];
			opt["api"] = "order_nft"
			opt["public_key"] = this.public_key;

			this.$store.dispatch('post', opt).then(response => {
				if (response != undefined)
					this.orders = response.data;
			})

		},
		color(article) {
			if (article.process == 'cancel') return 'red';
			if (article.id_payment == '') return 'warning'
			if (article.id_payment != '') return 'success'

		},
		cancel() {
			if (window.confirm(this.$t('cancel_invest')) == true) {

				this.opt = [];
				this.opt["debug"] = false;
				this.opt["api"] = "cancel_nft"
				this.opt["selection"] = 'berlcoin';
				this.opt["asset_code"] = this.orders[this.selected].asset_code;
				this.opt["uid"] = this.orders[this.selected].id_transaction
				this.$store.dispatch('post', this.opt).then(response => {
					if (response != undefined) {
						this.init();
						this.display = 'list';
					}
				})
			}
		},
	},
	watch: {
		display() {
			if (this.display == "list") this.selected = undefined;
		},
		selected() {
			if (this.selected != undefined) {
				this.display = "detail";
				//		this.$emit('event', "show_form")
			}
		}
	}
}
</script>

<style scoped>
</style>
