<template>
<div>
	<v-row>
		<v-col cols=12>

			<v-banner single-line
								elavation=0
								color="yellow lighten-5">
				<v-icon color="warning"
								@click="$emit('changepage', 'dashboard')"
								class="mr-6"
								size="24">
					mdi-home
				</v-icon>
				<v-icon color="warning"
								class="mr-6"
								@click="$emit('changepage', 'primary')"
								size="24">
					mdi-currency-eur
				</v-icon>
				<v-icon color="success"
								class="mr-6"
								size="24">
					mdi-shopping
				</v-icon>

			</v-banner>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols=12
					 v-if="!kyc_wallet && public_key != undefined">
			<v-alert dark
							 color="warning">

				<v-icon>
					mdi-checkbox-multiple-marked-outline
				</v-icon> {{$t('nokyc')}}
				<template>
					<v-btn class="    float-right "
								 color="primary"
								 @click="$emit('changepage', 'validate_kyc')"
								 elevation=0
								 small>{{$t('nokyc1')}}</v-btn>
				</template>
			</v-alert>
		</v-col>

		<v-col cols=12>
			<div style="font-size:85%"
					 v-if="!kyc_wallet"
					 class="my-2">
				{{$t('kyc_alert')}}
			</div>
		</v-col>
	</v-row>


	<v-row>
		<v-col v-show="public_key== undefined"
					 cols=12>
			<v-card elevation=0
							color="red darken-4"
							dark
							@click="$emit('changepage', 'keys')"
							class="mx-3 mt-3 pa-2"> {{$t('message_no_key')}}
			</v-card>
		</v-col>
	</v-row>


	<v-row class="blue-grey lighten-5"
				 v-show="public_key != undefined">
		<v-col cols=12>
			<v-card class="mx-auto"
							elevation=1
							style="max-width:500px;">
				<primarybuyform @changepage="change_page" />
			</v-card>
		</v-col>
	</v-row>
</div>
</template>

<script>
import primarybuyform from '@/components/primary_buy_form.vue'

export default {
	name: 'primary_sell',
	components: {
		primarybuyform
	},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		public_key() {
			return this.$store.getters.public_key;
		},
		kyc_wallet() {
			return this.$store.getters.kyc_wallet;
		},
	},
	methods: {
		change_page(page) {
			this.$emit('changepage', page)
		}
	},
	watch: {}
}
</script>

<style scoped>
</style>
