<template>
<div>
	<v-list three-line
					v-if="display=='list'"
					style="height:450px; overflow-y: scroll;">
		<template v-for="(article, index) in borders">
			<v-divider v-if="index>0"
								 :key="article.last_change"></v-divider>
			<v-list-item :key="index">
				<v-list-item-avatar>
					<v-img src="@/assets/berlcoin.svg"></v-img>
				</v-list-item-avatar>

				<v-list-item-content>

					<v-list-item-title v-html="'<b>'+parseFloat(article.moneyback).toFixed(2)+' €</b> : ' + parseFloat(article.amount).toFixed(2)   " />
					<v-list-item-subtitle v-html="article.last_change" />
					<v-spacer></v-spacer>
				</v-list-item-content>

				<v-list-item-action>

					<v-btn small
								 elevation=0
								 style="float:right"
								 :color="article.process =='pending' ? 'warning' : 'success' ">{{(( parseFloat(article.moneyback)).toFixed(2) )  }}</v-btn>


				</v-list-item-action>
			</v-list-item>
		</template>
	</v-list>
	<div v-if="display=='form'">
		<primarysellform />
	</div>
</div>
</template>


<script>
import primarysellform from '@/components/primary_sell_form.vue'

export default {
	name: 'list_primary_selling',
	components: {primarysellform},
	props: {
		show: {
			type: String,
			default: 'list'
		}
	},
	data: () => ({
		selected: undefined,
		order: undefined,
		display: "list",
		borders: [],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		this.init()
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		public_key() {
			return this.$store.getters.public_key;
		}
	},
	methods: {
		init() {
			let opt = [];
			opt["api"] = "divest_berlcoin"
			opt["public_key"] = this.public_key;
			this.$store.dispatch('post', opt).then(response => {
				if (response != undefined)
					this.borders = response.data;
			})
		},

	},
	watch: {
		show() {
			if (this.show == 'new') {
				this.display = 'form';
			}

			if (this.show == 'list') {
				this.init();
				this.display = 'list';
			}
		},
		selected() {
			if (this.selected != undefined) {
				this.display = "form";
				this.$emit('event', "show_form")
			}
		}
	}
	}
	</script>

	<style scoped>
	</style>
