<template>
<v-app>
	<v-app-bar app
						 :color="blockchain== 'public' ? 'primary' : 'black'"
						 dark>


		<div class="d-flex align-center"
				 @click="change_page('dashboard')">
			<v-img alt="Wallet Fraction Logo"
						 class="shrink mr-2"
						 contain
						 src="@/assets/berlcoin2.png"
						 transition="scale-transition"
						 width="40" />

			<span class="shrink mt-1  text-h6"
						contain
						min-width="100"> Berlcoin Market </span>
			<span style="font-size:50%"
						class="ml-2"
						contain
						min-width="10">1.01 </span>
		</div>


		<v-spacer></v-spacer>

		<v-menu bottom
						:offset-y="true">
			<template v-slot:activator="{ on, attrs }">
				<v-btn dark
							 icon
							 v-bind="attrs"
							 v-on="on">
					<v-icon>mdi-dots-vertical</v-icon>
				</v-btn>

			</template>

			<v-list subheader
							two-line>
				<v-list-item-group v-model="selectedItem"
													 color="primary">
					<v-list-item v-for="file in files"
											 :key="file.title"
											 @click="change_page(file.go)">
						<v-list-item-avatar>
							<v-icon :class="file.color"
											dark
											v-text="file.icon"></v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title v-text="file.title"></v-list-item-title>
							<v-list-item-subtitle v-text="file.subtitle"></v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-menu>
		<v-btn dark
					 @click="page='shoppingcart'"
					 v-show="$store.getters.shops>0"
					 class="  teal"
					 icon>
			<v-icon>mdi-shopping</v-icon>
		</v-btn>


	</v-app-bar>

	<v-main>

		<v-container style="max-width:1366px;">

			<offer v-if="page=='offer'"
						 @askaction="askaction"
						 @changepage="change_page" />


			<dashboard v-if="page=='dashboard'"
								 @askaction="askaction"
								 @changepage="change_page" />

			<nft v-if="page=='nft'"
					 @askaction="askaction"
					 @changepage="change_page" />

			<shoppingcart v-if="page=='shoppingcart'"
										@askaction="askaction"
										@changepage="change_page" />

			<keys v-if="page=='keys'"
						@askaction="askaction"
						@changepage="change_page" />

			<order v-if="page=='order'"
						 @askaction="askaction"
						 @changepage="change_page" />

			<primary v-if="page=='primary'"
							 @askaction="askaction"
							 @changepage="change_page" />

			<secondary v-if="page=='secondary'"
								 @askaction="askaction"
								 @changepage="change_page" />

			<validatekyc v-if="page=='validate_kyc'"
									 @askaction="askaction"
									 @changepage="change_page" />

			<enterkyc v-if="page=='enter_kyc'"
								@askaction="askaction"
								@changepage="change_page" />

			<amm v-if="page=='amm'"
					 @askaction="askaction"
					 @changepage="change_page" />

			<transfert v-if="page=='transfert'"
								 @askaction="askaction"
								 @changepage="change_page" />

			<primarysell v-if="page=='primary_sell'"
									 @askaction="askaction"
									 @changepage="change_page" />

			<primarybuy v-if="page=='primary_buy'"
									@askaction="askaction"
									@changepage="change_page" />

			<secondarysell v-if="page=='secondary_sell'"
										 @askaction="askaction"
										 @changepage="change_page" />

			<secondarybuy v-if="page=='secondary_buy'"
										@askaction="askaction"
										@changepage="change_page" />

			<secondarystaking v-if="page=='secondary_staking'"
												@askaction="askaction"
												@changepage="change_page" />

			<validatesponsor v-if="page=='validate_sponsor'"
											 @askaction="askaction"
											 @changepage="change_page" />

			<explorer v-if="page=='explorer'"
								@askaction="askaction"
								@changepage="change_page" />




		</v-container>
	</v-main>

	<v-footer app>
		<div style="width:100%"
				 class="caption text-center">(c) 2022 - <a href="https://fraction.immo">Fraction.re</a> - <b>Berlcoin.market</b> {{$t('ismarketplace')}} <a href="https://berlcoin.berlin">Berlcoin.berlin</a>
			- <a href="https://berlcoin.berlin/fr/tou">{{$t('label_tou')}}</a>
		</div>
		<!-- -->
	</v-footer>
</v-app>
</template>

<script>
import dashboard from "@/views/dashboard.vue";
import nft from "@/views/nftcard.vue";
import shoppingcart from "@/views/shoppingcard.vue";
import keys from "@/views/keys.vue";
import order from "@/views/order.vue";
import primary from "@/views/primary.vue";
import secondary from "@/views/secondary.vue";
import validatekyc from "@/views/validate_kyc.vue";
import enterkyc from "@/views/enter_kyc.vue";
import amm from "@/views/amm.vue";
import transfert from "@/views/transfert.vue";
import primarysell from "@/views/primary_sell.vue";
import primarybuy from "@/views/primary_buy.vue";
import secondarysell from "@/views/secondary_sell.vue";
import secondarybuy from "@/views/secondary_buy.vue";
import secondarystaking from "@/views/secondary_staking.vue";
import validatesponsor from "@/views/validate_sponsor.vue";
import explorer from "@/views/explorer.vue";
import offer from "@/views/offer.vue";




export default {
	name: 'App',
	components: {
		dashboard,
		shoppingcart,
		nft,
		keys,
		order,
		primary,
		secondary,
		validatekyc,
		enterkyc,
		amm,
		transfert,
		primarysell,
		primarybuy,
		secondarysell,
		secondarybuy,
		secondarystaking,
		validatesponsor,
		explorer,
		offer

	},
	props: {},
	data: () => ({
		selectedItem: undefined,
		page: 'offer',
		files: [{
				color: 'red',
				icon: 'mdi-home-outline',
				title: 'Marché primaire',
				subtitle: 'Acheter des fractions de biens immobiliers Berlinois',
				go: 'primary'
			},
			{
				color: 'blue',
				icon: 'mdi-bank-transfer',
				title: 'Marché décentralisé',
				subtitle: 'Acheter et vendre des Berlcoins ou des NFT Patrimoine ',
				go: 'secondary'
			},
			{
				color: 'amber',
				icon: 'mdi-transfer ',
				title: 'Marché Automatique ',
				subtitle: 'Utilisez les marchés automatisés',
				go: 'amm'
			},


			{
				color: 'brown',
				icon: "mdi-format-list-numbered-rtl",
				title: 'Historique',
				subtitle: 'Les transactions sur la blockchain',
				go: 'explorer'
			},

			{
				color: 'black',
				icon: 'mdi-key',
				title: 'Paramètres',
				subtitle: 'Clés et déconnection',
				go: 'keys'
			},
		],
		asset_index: [],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {

		let txtls = window.localStorage.getItem('berlcoin.market');

		if (txtls != null) {
			let data = JSON.parse(txtls);
			this.$store.commit('update_publickey', data.publickey);
			this.$store.commit('update_privatekey', data.privatekey);
			this.$store.commit('update_pin', data.pin);
			//		console.log(data.totp)
			if (data.totp != "") this.$store.commit('update_totp', data.totp);

		} else {
			this.$store.commit("update_access", false);
		}
		//	this.init();
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {

		shops() {
			return this.$store.getters.shops;
		},
		blockchain() {
			return this.$store.getters.blockchain
		},
		horizon() {
			return this.$store.getters.horizon
		},
		keys() {
			return this.$store.getters.keys
		},
		public_key() {
			return this.$store.getters.public_key
		},
	},
	methods: {
		askaction(action) {
			//	//console.log("askaction - app")
			if (action == "init") this.init();
		},
		change_page(page) {
			this.page = page;
		},
		init() {

			// ------------------------------------------------------------------------ Les datas du compte master
			let url = this.horizon + "accounts/" + this.keys.master.public;
			this.$store.dispatch('get', url).then(response => {
				let temp = response.data.data;
				let tab = [];
				for (const [key, value] of Object.entries(temp)) {
					tab.push({
						"name": key,
						"value": window.atob(value),
					})
				}

				let param = {};
				param.account = "master"
				param.data = tab
				param.destination = "data"
				this.$store.commit("update_account", param);


				// ------------------------------------------------------------------------ Les offres du distributeur


				url = this.horizon + "accounts/" + this.keys.distributor.public + "/offers?order=desc&limit=200";
				//console.log(url)

				this.$store.dispatch('get', url).then(response => {
					param = {};
					param.account = "distributor"
					param.data = response.data._embedded.records;
					param.destination = "offers"
					this.$store.commit("update_account", param);


					// ------------------------------------------------------------------------ La balance du stock de NFT
					this.$store.dispatch('get', this.horizon + "accounts/" + this.keys.nft_stock.public).then(response => {
							//console.log(this.horizon + "accounts/" + this.keys.nft_stock.public);
							let param_nft_stock = {};
							param_nft_stock.account = "nft_stock"
							param_nft_stock.data = response.data.balances;
							param_nft_stock.destination = "balances"
							this.$store.commit("update_account", param_nft_stock);
							//console.log(param_nft_stock);


							url = this.horizon + "accounts/" + this.public_key + "/offers?limit=200&order=desc";
							this.$store.dispatch('get', url).then(response => {
								let param_offers = {};
								param_offers.account = "nft_stock"
								param_offers.destination = "offers"
								param_offers.data = response.data._embedded.records;
								this.$store.commit("update_account", param_offers);
							})



						},
						error => {
							console.log(error)
						})


					this.$store.dispatch('get', this.horizon + "accounts/" + this.keys.distributor.public).then(response => {
						let param_distributor = {};
						param_distributor.account = "distributor"
						param_distributor.data = response.data.balances;
						param_distributor.destination = "balances"
						this.$store.commit("update_account", param_distributor);
						// ------------------------------------------------------------------------   liquidity_pools  du distributeur

						// ---- necessite d'attendre d'avoir recupéré les balances
						this.$store.dispatch('get', this.horizon + "liquidity_pools/?limit=200&account=" + this.keys.distributor.public).then(response => {
							let param_distributor_lp = {};
							param_distributor_lp.account = "distributor"
							param_distributor_lp.data = response.data._embedded.records;
							param_distributor_lp.destination = "liquidity_pools"
							this.$store.commit("update_account", param_distributor_lp);
							//		console.log(param_distributor_lp)
							// ------------------------------------------------------------------------ La balance du wallet

							url = this.horizon + "accounts/" + this.public_key;
							this.$store.dispatch('get', url).then(response => {
								param = {};
								param.account = "wallet"
								param.destination = "balances"
								param.data = response.data.balances;
								this.$store.commit("update_account", param);
								// ------------------------------------------------------------------------ Les datas du compte master

								temp = response.data.data;
								tab = [];
								for (const [key, value] of Object.entries(temp)) {
									tab.push({
										"name": key,
										"value": window.atob(value),
									})
									if (key == "kyc_0") this.$store.commit("update_kyc_wallet", true);
									if (key == "sponsor") this.$store.commit("update_sponsor_wallet", true);

								}
								param = {};
								param.account = "wallet"
								param.data = tab
								param.destination = "data"
								this.$store.commit("update_account", param);

								// ------------------------------------------------------------------------   liquidity_pools

								url = this.horizon + "liquidity_pools/?limit=200&account=" + this.public_key;
								this.$store.dispatch('get', url).then(response => {
									param = {};
									param.account = "wallet"
									param.destination = "liquidity_pools"
									param.data = response.data._embedded.records;
									this.$store.commit("update_account", param);
								})

								// ------------------------------------------------------------------------ claimable_balances
								url = this.horizon + "claimable_balances?limit=200&order=desc&claimant=" + this.public_key;
								this.$store.dispatch('get', url).then(response => {
									param = {};
									param.account = "wallet"
									param.destination = "claimable_balances"
									param.data = response.data._embedded.records;
									this.$store.commit("update_account", param);
								})
								// ------------------------------------------------------------------------ offers

								url = this.horizon + "accounts/" + this.public_key + "/offers?limit=200&order=desc";
								this.$store.dispatch('get', url).then(response => {
									param = {};
									param.account = "wallet"
									param.destination = "offers"
									param.data = response.data._embedded.records;
									this.$store.commit("update_account", param);
								})

								// ------------------------------------------------------------------------ operation

								url = this.horizon + "accounts/" + this.public_key + "/operations?limit=200&order=desc";
								this.$store.dispatch('get', url).then(response => {
									param = {};
									param.account = "wallet"
									param.destination = "operations"
									param.data = response.data._embedded.records;
									this.$store.commit("update_account", param);
								})


							}) // -- attendre le compte de distribution pour être sur d'avoir le cours du XLM

						})

					})





				}).catch(error => {
					this.balances = [];

				})



			})
		},

	},
	watch: {
		public_key() {
			this.init();
		},
		page() {
			if (this.page == "dashboard") this.init();
		},
	}
}
</script>

<style scoped>
</style>
