<template>
<div>

	<section class="wrapper ">
		<div class="container pt-7 pb-12 py-sm-12 py-xxl-12">
			<h4 class="text-red"
					v-html="$t('offer1_up')" />

			<h2 class="display-3 mb-3"
					v-html="$t('offer1_title')" />

			<p class="text-h5">
				<br />
				{{$t('offer1_line2')}}

				<br /> <br />
				{{$t('offer1_line1')}}
				<br /> <br />{{$t('offer1_line3')}}
			</p>

			<div>



				<v-btn :href="$t('offer1_link')"
							 class="btn btn-lg  dark rounded mt-5 mt-10"
							 style="background-color: #1E294C; color:white">{{$t('offer1_btn')}}</v-btn>


			</div>
		</div>
		<!--/column -->

		<!-- /.container -->
	</section>



	<section class="wrapper  ">
		<div class="container py-14 py-md-16">
			<div class="row gx-lg-8 gx-xl-12 gy-12   pb-6">

				<!--/column -->
				<div class="col-lg-12">
					<h4 class="text-red"
							v-html="$t('offer2_up')" />
					<h2 class="display-3 mb-3"
							v-html="$t('offer2_title')" />
					<p class="text-h5">
						<br /><b>{{$t('offer2_bold1')}}</b>
						: {{$t('offer2_line1')}}

						<br />
						<br /><b>{{$t('offer2_bold2')}}</b>
						: {{$t('offer2_line2')}}
						<br />
						<br /><b>{{$t('offer2_bold3')}}</b>
						: {{$t('offer2_line3')}}
						<br />
						<br /><b>{{$t('offer2_bold4')}}</b>
						: {{$t('offer2_line4')}}
					</p>

					<br />
					<div class="row counter-wrapper gy-6">

						<!--/column -->
						<div class="col-md-4">


							<v-btn href="https://berlcoin.fr/index.html"
										 class="btn btn-lg  dark rounded mt-5 mt-10"
										 style="background-color: #1E294C; color:white">{{$t('offer2_btn')}}</v-btn>



						</div>
						<!--/column -->
					</div>
					<!--/.row -->
				</div>
				<!--/column -->
			</div>
			<!--/.row -->
		</div>
		<!-- /.container -->
	</section>
	<!-- /section -->





</div>
</template>


<script>
export default {
	name: 'offer',
	components: {

	},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
