	<template><div>
		<v-alert v-if="send_asset != undefined  "
		dense
		text
		class="m-1"
		type="success"
	>
		{{send_asset.balance.toString(7)}} <b> {{send_asset.asset_code}}</b>
	</v-alert>

<v-list two-line 	v-if="display=='list'"
				style="height:450px; overflow-y: scroll;">
				<v-list-item-group v-model="selected"
													 active-class="pink--text">
	<template v-for="(item, index) in claimable_balances">
		<v-list-item :key="item.title">
			<v-list-item-content class="py-3 my-2">
				<v-list-item-title>
					<v-chip :color="afternow(item.claimants[0].predicate.not.abs_before.substr(0,10)) ? 'success' : 'warning'"
									label>
						<b v-html="monetary_aspect(item.amount)" />
					</v-chip>
					<b class="pl-3"
						 v-html="item.asset_code" />
				</v-list-item-title>
				<v-list-item-subtitle class="text--primary">
					{{item.claimants[0].predicate.not.abs_before.replace("T"," ").replace("Z","")}}
				</v-list-item-subtitle>
			</v-list-item-content>

			<v-list-item-action class="py-2">
				<v-chip label>
					<v-icon left
									small
									color="primary">
						mdi-currency-eur
					</v-icon>
					<span v-html="monetary_aspect(item.price_total)" />
				</v-chip>
				<v-list-item-action-text v-html=" monetary_aspect(item.price_unit)"></v-list-item-action-text>
			</v-list-item-action>
		</v-list-item>
		<v-divider :key="index"></v-divider>
	</template>
		</v-list-item-group>
</v-list>

	<div v-if="display=='form'">
		<stellarform :operation="operation"
								 css="pa-3"
								 :order="order"
								 :term="term_contrat"
								 :send_asset="send_asset"
								 @result="stellarform_result" />
		<v-divider></v-divider>

		<v-btn icon
					 @click="display='list';$emit('event', 'show_list')"
					 color="warning">
			<v-icon>mdi-arrow-left</v-icon>
		</v-btn>


	</div>
</div>
</template>

<script>
import stellarform from '@/components/stellar_form.vue'
export default {
	name: 'list_claimable_balances',
	components: {
		stellarform
	},
	props: {
		show: {
			type: String,
			default: 'list'
		}
	},
	data: () => ({
		selected: undefined,
		order: undefined,
		display: "list",
		send_asset: undefined,
		operation: "claim_claimable_balance",
		term_contrat: [],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		this.term_contrat = [];
		let temp = this.data.value;
		if (temp != undefined) {

			const words = temp.split('#');
			this.term_contrat.push({
				"an": 1,
				"label": "1 an",
				"staking": words[0]
			});
			this.term_contrat.push({
				"an": 2,
				"label": "2 ans",
				"staking": words[1]
			});
			this.term_contrat.push({
				"an": 3,
				"label": "3 ans",
				"staking": words[2]
			});
			this.term_contrat.push({
				"an": 4,
				"label": "4 ans",
				"staking": words[3]
			});
			this.term_contrat.push({
				"an": 5,
				"label": "5 ans",
				"staking": words[4]
			});
			this.term_contrat.push({
				"an": 6,
				"label": "6 ans",
				"staking": words[5]
			});
			this.term_contrat.push({
				"an": 7,
				"label": "7 ans",
				"staking": words[6]
			});
			this.term_contrat.push({
				"an": 8,
				"label": "8 ans",
				"staking": words[7]
			});
			this.term_contrat.push({
				"an": 9,
				"label": "9 ans",
				"staking": words[8]
			});
			this.term_contrat.push({
				"an": 10,
				"label": "10 ans",
				"staking": words[9]
			});
		}

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		claimable_balances() {
			return this.$store.getters.claimable_balances('wallet')
		},
		token() {
			return this.$store.getters.asset("wallet", "Berlcoin", "")
		},
		data() {
			return this.$store.getters.data("master", "Berlcoin_term_contract");
		},
	},
	methods: {
		afternow(itemdate) {
			if (Date.parse(itemdate) < new Date()) return true;
			else return false;
		},
		stellarform_result(result) {
			if (result) this.display = "list";

			this.$emit('askaction', "init")
		}
	},
	watch: {
		show() {
			if (this.show == 'new') {
				this.order = undefined;
				this.operation = "staking"
				this.send_asset = this.token
				this.display = 'form';
			}
			if (this.show == 'list') {
				this.send_asset = undefined
			}
		},
		selected() {
			if (this.selected != undefined) {
				this.order = this.claimable_balances[this.selected];
				this.operation = "claim_claimable_balance"
				this.send_asset = undefined
				this.display = "form";
				this.$emit('event', "show_form")
			}
		}
	}
}
</script>

<style scoped>
</style>
