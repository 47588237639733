<template>
<div>
	<v-alert class="pa-1"
					 icon="mdi-format-list-numbered-rtl"
					 color="teal lighten-4"
					 @click="close('success')">Historique des transactions </v-alert>


	<v-row>
		<v-col cols=12>
			<v-dialog v-model="dialog"
								fullscreen
								hide-overlay
								transition="dialog-bottom-transition">
				<template v-slot:activator="{ on, attrs }">
					<v-card elevation="0">
						<v-simple-table v-if="operation_list != undefined">
							<thead>
								<tr>
									<th class="text-left"> Date </th>
									<th class="text-left"> Type </th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(embedded, eindex) in operation_list "
										@click="detail(eindex)"
										v-bind="attrs"
										v-on="on"
										:key="'embedded'+eindex">

									<td class="caption">{{ embedded.created_at.replace("T"," ").replace("Z","") }} </td>
									<td class="caption">{{ embedded.type }}
										<br />
										<span v-if="select_show_asset( embedded) != undefined"
													v-html="monetary_aspect( embedded.amount) +' '+select_show_asset( embedded) ">
										</span>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</v-card>
				</template>

				<v-card elevation="0">
					<v-card-title>
						<v-btn icon
									 class="ml-n3"
									 @click="dialog = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
						Détail de l'opération &nbsp;
					</v-card-title>
					<v-card-text>
						<div v-for="(value, key) in record">
							<div v-if="key!='_links'">
								<span class="caption">{{ key }} </span>
								<br />
								<span class="caption"> <b>{{ account_name(value) }}</b> </span>
								<br /> <br />
							</div>
						</div>

						<a target="_blank"
							 v-if="record._links != undefined"
							 :href="record._links.self.href">
							<v-btn small
										 class="primary ma-2">
								Self
							</v-btn>
						</a>

						<a target="_blank"
							 v-if="record._links != undefined"
							 :href="record._links.transaction.href">
							<v-btn small
										 class="primary ma-2">
								transaction
							</v-btn>
						</a>

						<a target="_blank"
							 v-if="record._links != undefined"
							 :href="record._links.effects.href">
							<v-btn small
										 class="primary ma-2">
								effects
							</v-btn>
						</a>

						<a target="_blank"
							 v-if="record._links != undefined"
							 :href="record._links.succeeds.href">
							<v-btn small
										 class="primary ma-2">
								succeeds
							</v-btn>
						</a>

						<a target="_blank"
							 v-if="record._links != undefined"
							 :href="record._links.precedes.href">
							<v-btn small
										 class="primary ma-2">
								precedes
							</v-btn>
						</a>
					</v-card-text>
				</v-card>
			</v-dialog>
		</v-col>
	</v-row>

</div>
</template>


<script>
export default {
	name: 'explorer',
	components: {},
	props: {},
	data: () => ({
		record: [],
		dialog: false,
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		operation_list() {
			return this.$store.getters.operations("wallet");
		},
	},
	methods: {
		close(etat) {
			if (etat == "success") this.$emit('changepage', "dashboard")
		},
		detail(index) {
			this.record = this.operation_list[index];
		},
		select_show_asset(embedded) {
			let asset;
			asset = embedded.asset_code;
			if (embedded.type == "manage_sell_offer") asset = embedded.selling_asset_code;
			if (embedded.type == "manage_buy_offer") asset = embedded.buying_asset_code;

			return asset;
		},
	},
	watch: {

	}
}
</script>

<style scoped>
</style>
