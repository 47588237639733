<template>
<div>
	<v-row>
		<v-col cols=12>
			<template>
				<v-banner single-line
									elavation=0
									color="yellow lighten-5">
					<v-icon color="warning"
									@click="$emit('changepage', 'dashboard')"
									class="mr-6"
									size="24">
						mdi-home
					</v-icon>
					<v-icon color="success"
									class="mr-6"
									size="24">
						mdi-checkbox-multiple-marked-outline
					</v-icon>

				</v-banner>
			</template>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols=12>
			<h4 class="ma-6"> {{$t('nokyc6')}}</h4>
			<v-alert type="warning"
							 v-if="code_kyc=='true'">
				{{$t('nokyc3')}}
				<br />
				<br /> {{$t('nokyc4')}}
			</v-alert>

			<div>
				<stellarform operation="validate_kyc"
										 v-if="code_kyc!='true'"
										 css="pa-3"
										 :data_value="code_kyc"
										 @result="stellarform_result" />
				<br />
				<i class="ma-6"
					 v-if="code_kyc!='true'"
					 style="font-size:80%"> {{$t('nokyc5')}})</i>
			</div>
		</v-col>
	</v-row>
</div>
</template>


<script>
import stellarform from '@/components/stellar_form.vue'
export default {
	name: 'validate_kyc',
	components: {
		stellarform
	},
	props: {},
	data: () => ({
		code_kyc: undefined
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		let opt = [];
		opt["api"] = "ask_code_kyc"
		opt["public_key"] = this.public_key;
		opt["debug"] = false
		this.$store.dispatch('post', opt).then(response => {
			if (response != undefined) {
				let temp = response.data;
				if (temp.pending != undefined) {
					if (temp.pending == false) this.$emit('changepage', 'enter_kyc');
					if (temp.pending == true) this.code_kyc = "true";
				} else
					this.code_kyc = response.data.crypt;
			}

		})
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		public_key() {
			return this.$store.getters.public_key;
		},
	},
	methods: {
		stellarform_result(result) {
			if (result) this.$emit('changepage', "dashboard")
		}
	},
	watch: {}
}
</script>

<style scoped>
</style>
