<template>
<v-card class="mx-auto  "
				style="height:100%">

	<v-card-title class="white--text red darken-4"> Selling offers
		<v-spacer> </v-spacer>
		<v-btn v-show="show_action == 'list'"
					 fab
					 elevation=0
					 small
					 text
					 dark
					 @click="plus"
					 color="white">
			<v-icon dark>
				mdi-plus-circle
			</v-icon>

		</v-btn>

	</v-card-title>
	<hr />
	<listsellingoffers :show="show_action"
										 @askaction="askaction"
										 @event="event" />
	<v-card-actions v-show="show_action == 'list'">
		<v-btn color=" red lighten-1"
					 text>
			Total
		</v-btn>
		<v-spacer></v-spacer>
		<v-chip label
						color="white">
			<v-icon left
							small
							color="primary">
				mdi-currency-eur
			</v-icon>

			<span v-html="monetary_aspect($store.getters.account_solde('buying'))" />
		</v-chip>

	</v-card-actions>
</v-card>
</template>


<script>
import listsellingoffers from "@/components/list_selling_offers.vue"
export default {
	name: 'card_selling',
	components: {
		listsellingoffers
	},
	props: {},
	data: () => ({
		show_action: 'list',
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		plus() {
			this.show_action = 'new'
		},
		askaction(action) {
			//console.log("askaction - card")
			if (action == "init") this.$emit('askaction', "init")
		},
		event(event) {
			if (event == "show_form") this.show_action = 'form';
			if (event == "show_list") this.show_action = 'list';
		},
	},
	watch: {}
}
</script>

<style scoped>
</style>
